import React, { useState, OnSubmit } from "react";
import { Row } from "react-bootstrap";
import "./TCPRegister.css";
import Arrow from "../../../Images/Arrow.png";
import { useHistory } from "react-router-dom";

function TCPRegister() {
  const history = useHistory();
  const handleClick1 = () => history.push("/TCPLogin");
  const [CompanyName, setCompanyName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [Number, setNumber] = useState("");
  const [CompanyNameErr, setCompanyNameErr] = useState({});
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  const [NumberErr, setNumberErr] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      setCompanyName("");
      setMobileNumber("");
      setNumber("");
      history.push("/TCotp");
    }
  };

  const formValidation = () => {
    var specialCharacterPattern2 = /[^a-zA-Z]+/;
    const CompanyNameErr = {};
    const mobileNumberErr = {};
    const NumberErr = {};
    let isValid = true;
    if (specialCharacterPattern2.test(CompanyName)) {
      CompanyNameErr.CompanyNameSpecial =
        "should not include special characters ";
      isValid = true;
    }
    if (mobileNumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile number is too long";
      isValid = false;
    }
    if (mobileNumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile number is too short";
      isValid = false;
    }
    if (isNaN(mobileNumber)) {
      mobileNumberErr.mobileNumber123 = "Mobile Number must have numbers";
      isValid = false;
    }
    if (isNaN(Number)) {
      NumberErr.Number123 = "Number of Vehicles must have numbers";
      isValid = false;
    }
    setCompanyNameErr(CompanyNameErr);
    setMobileNumberErr(mobileNumberErr);
    setNumberErr(NumberErr);
    return isValid;
  };

  return (
    <form onSubmit={OnSubmit}>
      <div className="TCPRegister" data-component="TCPRegister">
        <div className="Transportation">
          <p>
            Want to be a
            <span className="font-weight-bold">Transportation </span>
          </p>
          <h4>Delivery Partner?</h4>
        </div>
        <div className="login-transport">
          <h3>Register</h3>
          <h4>
            Already registered? Please
            <span className="TCLogin" onClick={handleClick1}> <u>Login</u></span>
          </h4>
          <Row>
            <input
              className="TClogin-input"
              type="text"
              placeholder="Company Name"
              value={CompanyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
          </Row>
          {Object.keys(CompanyNameErr).map((key) => {
            return (
              <div style={{ color: "white", paddingRight: "400px" }}>
                {CompanyNameErr[key]}
              </div>
            );
          })}
          <Row>
            <input
              className="TClogin-input"
              type="text"
              placeholder="Contact Number"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            />
          </Row>
          {Object.keys(mobileNumberErr).map((key) => {
            return (
              <div style={{ color: "white", paddingRight: "400px" }}>
                {mobileNumberErr[key]}
              </div>
            );
          })}
          <Row>
            <input
              className="TClogin-input"
              type="text"
              placeholder="Number Of Vehicles"
              value={Number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />
          </Row>
          {Object.keys(NumberErr).map((key) => {
            return (
              <div style={{ color: "white", paddingRight: "400px" }}>
                {NumberErr[key]}
              </div>
            );
          })}
        </div>
        <p className="text-white partner1">Transportation Delivery Partner</p>
        <img src={Arrow} onClick={onSubmit} className="ArrowTCR" />
      </div>
    </form>
  );
}

export default TCPRegister;