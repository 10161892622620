import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import qs from "querystring";
import { useHistory } from "react-router-dom";
import {
  pickUpDate,
  packageWeight,
  packageSpace,
  entireTruck,
  receivingPersonName,
  receivingPersonNum,
} from "../../Redux/actions/packageDetails";
import { citiesList } from "../../Redux/actions/customerInfo";
import Header from "../PoHeader/Header";
import truck3 from "../../Images/truck3.png";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import validator from "validator";
import { allTruckDetails } from "../../Redux/actions/tripsAvailable";
import "./Details.css";

function Details() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [PersonNameErr, setPersonNameErr] = useState({});
  const [PackageWeightErr, setPackageWeightErr] = useState({});
  const [PackageSpaceErr, setPackageSpaceErr] = useState({});
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  const [pickUPPoint, setPickUpPoint] = useState("");
  const [dropPoint, setDropPoint] = useState("");
  const [date, setDate] = useState("");
  const [space, setSpace] = useState("");
  const [weight, setWeight] = useState("");
  const [truck, setTruck] = useState(false);
  const [receivingName, setReceivingName] = useState("");
  const [receivingnumber, setReceivingNumber] = useState("");
  const indian_cities = useSelector((state) => state.CitiesList);
            console.log(indian_cities,'-----citiess-------------');
  console.log(space, weight, truck);

  const customerTokenSelector = useSelector((state) => state.CustomerToken);

  const dateValue = new Date().getMilliseconds;






  const params2 = `${qs.stringify({
    source: pickUPPoint,
    destination: dropPoint,
    requiredSpace: space,
    requiredWeight: weight,
    entireTruck: truck,
  })}&startDate=${date}`;


  
  function entireTruckFunc(truckValue) {
    const truckValue1 = truckValue;
    const packageValues = 0;
    if (truckValue) {
      dispatch(entireTruck(truckValue1));
      dispatch(packageWeight(packageValues));
      dispatch(packageSpace(packageValues));
    } else {
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid && date.getMilliseconds > dateValue) {
      if(!truck){
        dispatch(entireTruck(truck));
        dispatch(packageWeight(weight));
        dispatch(packageSpace(space));
      }
      dispatch(receivingPersonName(receivingName));
      dispatch(receivingPersonNum(receivingnumber));
      dispatch(pickUpDate(date));
      try {
        axios(localAxiosToken("/getTruckList", params2, customerTokenSelector))
          .then((res) => {
            const truckData = res.data.data;
            if (res.data.statusCode === 200) {
              console.log(res.data);
              truckData.map((object, index) => {
                axios(
                  localAxiosToken(
                    "/getTruckRating",
                    qs.stringify({ truckNo: object.truck_no }),
                    customerTokenSelector
                  )
                )
                  .then((res) => {
                    if (res.data.statusCode === 200) {
                      object.rating = parseInt(res.data.AverageRating);
                    } else {
                      object.rating = 0;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
              dispatch(allTruckDetails(res.data.data));
      history.push("/availabletrucks");

            } else {
              dispatch(allTruckDetails(0));
              console.log(res.data);
              alert(res.data.message);
            }
          })
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
    }
    
  };


  const formValidation = () => {
    const PackageWeightErr = {};
    const PackageSpaceErr = {};
    var specialCharacterPattern = /[^a-zA-Z ]+/;
    var specialCharacterForMobile = /[^0-9]+/;
    const PersonNameErr = {};
    const mobileNumberErr = {};
    let isValid = true;
    if (!truck && weight.length === 0) {
      PackageWeightErr.PackageWeightEmpty = "PackageWeight should be required";
      isValid = false;
    } else if (specialCharacterForMobile.test(weight)) {
      PackageWeightErr.PackageWeightChar =
        "should not include special characters ";
      isValid = false;
    } else if (weight < 0) {
      PackageWeightErr.PackageWeightNegative =
        "Package Weight should be positive";
      isValid = false;
    } else {
    }
    if (!truck && space.length === 0) {
      PackageSpaceErr.PackageSpaceEmpty = "PackageSpace should be required";
      isValid = false;
    } else if (specialCharacterForMobile.test(space)) {
      PackageSpaceErr.PackageSpaceChar =
        "should not include special characters ";
      isValid = false;
    } else if (space < 0) {
      PackageSpaceErr.PackageSpaceNegative =
        "Package Weight should be positive";
      isValid = false;
    } else {
    }
    if (receivingName.length === 0) {
      PersonNameErr.PersonNameSpecial =
        "Receiving Person Name should be required ";
    } else if (specialCharacterPattern.test(receivingName)) {
      PersonNameErr.PersonNameSpecial =
        "should not include special characters  ";
      isValid = true;
    } else {
    }
    if (receivingnumber.length === 0) {
      mobileNumberErr.mobileNumberEmpty = "Mobile Number should be required";
      isValid = false;
    } else if (specialCharacterForMobile.test(receivingnumber)) {
      mobileNumberErr.mobileNumberChar =
        "should not include special characters ";
      isValid = false;
    } else if (receivingnumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile number should be 10 Digits";
      isValid = false;
    } else if (receivingnumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile number should be 10 Digits";
      isValid = false;
    } else {
    }
    setPersonNameErr(PersonNameErr);
    setPackageWeightErr(PackageWeightErr);
    setPackageSpaceErr(PackageSpaceErr);
    setMobileNumberErr(mobileNumberErr);
    return isValid;
  };

  function onChangetextremovevalidationmessage() {
    setMobileNumberErr("");
    setPersonNameErr("");
    setPackageWeightErr("");
    setPackageSpaceErr("");
  }

  const [errorMessage, setErrorMessage] = useState("");

  const validateDate = (value) => {
    if (validator.isDate(value)) {
      setErrorMessage("");
      setDate(value);
    } else {
      setErrorMessage("Enter Valid Date!");
    }
  };

  const setTruckFunction = (value) => {
    console.log(value);
    setTruck(value);
    entireTruckFunc(value);
  };

  return (
    <div className="detailsPage">
      <div className="headerPartInDetalisPage">
        <Header />
      </div>
      <div className="detailsBody">
        <div className="detailsLeft">
          <div className="marginDetails">
            <p>Add a Package +</p>
            <div className="searchTruckWithImage">
              <h1>Search for the Truck</h1>
              <img
                src={truck3}
                width={70}
                height={50}
                alt=" "
                className="ml-2 "
              />
            </div>
                      <select
                    name="city"
                    className="deliveryPoints"
                    onChange={(e) => setPickUpPoint(e.target.value)}>
                                 <option default> Pick Up Point</option>
                    {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
                    </select>

            <select
              name="city"
              className="deliveryPoints"
              onChange={(e) => setDropPoint(e.target.value)}
            >
              <option default> Drop Point</option>
              {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
            </select>
            <input
  min={new Date().toISOString().split('T')[0]}
              type="date"
              className="startDate"
              onChange={(e) => validateDate(e.target.value)}
            />
            <span
              style={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              {errorMessage}
            </span>
          </div>
        </div>
        <div className="detailsRight">
          <div className="marginDetails">
            <div className="PackageWeightStyle">
              <label className="labelData">Package Weight</label>
              <label className="labelColor">:</label>
              <input
                type="text"
                placeholder={truck ? "N/A KG" : "Package Weight (.KG)"}
                disabled={truck}
                onChange={(e) => {
                  setWeight(e.target.value);
                  onChangetextremovevalidationmessage();
                }}
              />
              <label className="labelData">KG</label>
              {Object.keys(PackageWeightErr).map((key) => {
                return (
                  <div className="errorForWeight">{PackageWeightErr[key]}</div>
                );
              })}
            </div>
            <div className="packageSpaceStyle">
              <label className="labelData">Package Space</label>
              <label className="labelColor">:</label>
              <input
                type="text"
                placeholder={truck ? "N/A KG" : "Package Space (.FT)"}
                disabled={truck}
                onChange={(e) => {
                  setSpace(e.target.value);
                  onChangetextremovevalidationmessage();
                }}
              />
              <label className="labelData">FT</label>
              {Object.keys(PackageSpaceErr).map((key) => {
                return (
                  <div className="errorForWeight">{PackageSpaceErr[key]}</div>
                );
              })}
            </div>
            <div style={{ height: "50px" }}>
              {/* <FormControl component="fieldset">
                <FormLabel component="legend"></FormLabel>
                <FormGroup aria-label="position" row>
                  <label className="checkLabel">
                    Book Entire Truck :
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          style={{ color: "white", marginLeft: "10px" }}
                          onChange={() => setTruckFunction(!truck)}
                        />
                      }
                    />
                  </label>
                </FormGroup>
              </FormControl> */}
            </div>
            <div className="packageReceivingHeight">
              <label className="labelData1">
                Package Receiving Person Name
              </label>
              <label className="labelColor">:</label>
              <input
                type="text"
                onChange={(e) => {
                  setReceivingName(e.target.value);
                  onChangetextremovevalidationmessage();
                }}
              />
              {Object.keys(PersonNameErr).map((key) => {
                return (
                  <div className="errorForReceivingPerson">
                    {PersonNameErr[key]}
                  </div>
                );
              })}
            </div>
            <div className="packageReceivingHeight">
              <label className="labelData1">
                Recieving Person Mobile Number
              </label>
              <label className="labelColor">:</label>
              <input
                type="text"
                onChange={(e) => {
                  setReceivingNumber(e.target.value);
                  onChangetextremovevalidationmessage();
                }}
              />
              {Object.keys(mobileNumberErr).map((key) => {
                return (
                  <div className="errorForReceivingPerson">
                    {mobileNumberErr[key]}
                  </div>
                );
              })}
            </div>
            <input
              className="float-right "
              type="submit"
              name=""
              value="Search Truck"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
