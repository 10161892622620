import React from "react";
import TOHeader from "../ToHeader/TOHeader";
import "./TripStatus.css";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import TripComponent from "../ToTripComponent/TripComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  allTripIdsNTruckNos,
  deliveryRequests,
  tripDetails,
} from "../../Redux/actions/tripsAvailable";
import { localAxiosToken } from "../../Axios/axios";
import qs from "querystring";
import axios from "axios";

function TripStatus({ TripHistory1 }) {
  const history = useHistory();
  const handleClick2 = () => history.push("/addtrip");
  const TripHistorySelector = useSelector((state) => state.TripHistory);
  console.log(TripHistorySelector);
  const dispatch = useDispatch();
  const OwnerToken = useSelector((state) => state.OwnerToken);
  const TruckNo = useSelector((state) => state.TruckNo);

  const addTrip = (tripId, truckNo, startDate) => {
    dispatch(tripDetails({ tripId, truckNo, startDate }));
    history.push("./AddDelivery");
  };

  function tripPacakages(tripId, page) {
    const params = qs.stringify({ tripId });
    axios(localAxiosToken("/getTripPackages", params, OwnerToken))
      .then((res) => {
        console.log(params);
        console.log(res.data);
        dispatch(deliveryRequests(res.data.packageDetails));
        console.log(res.data.packageDetails);
        if (page === "CheckList") return history.push("./TOhomepage");
        else return history.push("./deliveryrequests");
      })
      .catch((err) => console.log(err));
  }

  const onClickDR = (tripId) => {
    const data = qs.stringify({ truckNo: TruckNo });
    tripPacakages(tripId, "DR");
  };

  const checkList = (tripId) => {
    console.log("checklist called");
    dispatch(allTripIdsNTruckNos(tripId));
    tripPacakages(tripId, "CheckList");
  };

  return (
    <div className="tripStatus">
      <TOHeader />
      <div className="d-flex flex-row">
        <div className="col-6">
          <div className="d-flex flex-column">
            <h4 className="mr-auto bol" onClick={handleClick2}>
              Add Trip +
            </h4>
            {TripHistorySelector.map((object, index) => (
              <TripComponent key={index} TripHistory1={object} />
            ))}
          </div>
        </div>
        <div className="col-6 halfpage1">
          <div className="Tripcard">
            <div className="d-flex flex-column">
              <div className="Trip d-flex flex-row">
                <h4 className="text-left ml-3 mt-3 mb-3 font-weight-normal">
                  Trip 30
                </h4>
                <h4 className="mt-3 ml-auto text-black font-weight-normal">
                  Status :
                </h4>
                <h4 className="mt-3 orange mr-3">In Transit</h4>
              </div>
              <div className="clr-trip">
                <Row>
                  <Col className="text-left mt-4">
                    <div className="pl-2">
                      <div className="mt-2 mb-3">
                        <p className="m-0" style={{ fontWeight: "bold" }}>
                          Delivery Start Point
                        </p>
                        <p className="m-0">Hyderabad, Miyapur</p>
                      </div>
                      <div className="mt-2 mb-3">
                        <p className="m-0" style={{ fontWeight: "bold" }}>
                          Delivery End Point
                        </p>
                        <p className="m-0">Delhi - Barakhamba Rd</p>
                      </div>
                      <div className="mt-4 mb-3">
                        <p className="m-0" style={{ fontWeight: "bold" }}>
                          Date & Time
                        </p>
                        <p className="m-0">03-05-2020, 20:30 IST</p>
                      </div>
                    </div>
                  </Col>
                  <Col className="text-right">
                    <p className="font-weight-bold mr-3 mt-4 pr-4">Packages</p>
                    <h2 className="color1 mr-3">38</h2>
                    <p className="s">
                      Drop Done -<span className="colorg mr-3"> 4</span>
                    </p>
                    <p
                      className="coloro mr-3"
                      onClick={() => checkList(TripHistory1.trip_id)}
                    >
                      Check List
                    </p>
                  </Col>
                </Row>
                <div className="mt-2 mb-3 d-flex flex-row">
                  <a
                    href="#"
                    className="text-black float-left ml-2 mb-2 fon font-weight-normal text-dark"
                    onClick={() =>
                      addTrip(
                        TripHistory1.trip_id,
                        TripHistory1.truck_no,
                        TripHistory1.start_date
                      )
                    }
                  >
                    + Add Delivery
                  </a>
                  <p className="coloro ml-auto">Delivery Requests</p>
                  <ArrowForwardIosIcon
                    style={{ color: "darkorange" }}
                    fontSize="medium"
                    className="mt-2"
                    onClick={() => onClickDR(TripHistory1.trip_id)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TripStatus;