import React, { useState } from "react";
import OTPInput from "otp-input-react";
import "./TCotp1.css";
import { useHistory } from "react-router-dom";
import ultimatedeliveryicon from "../../Images/ultimatedeliveryicon.png";
import blackarrow from "../../Images/blackarrow.png";
import { useSelector, useDispatch } from "react-redux";
import { localAxios, localAxiosToken } from "../../Axios/axios";
import axios from "axios";
import qs from "querystring";
import { ownerOtp } from "../../Redux/actions/ownerInfo";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";

function TCotp1() {
  const history = useHistory();
  const dispatch = useDispatch();
  let logIn = "";
  const [OTP, setOTP] = useState("");
  const [errorOTP, setErrorOTP] = useState("");
  const ownerOtpSelector = useSelector((state) => state.OwnerOtp);
  const customerMobileNumSelector = useSelector(
    (state) => state.CustomerMobileNum
  );
  const customerTokenSelector = useSelector((state) => state.OwnerToken);
  const allTruckDataSelector = useSelector((state) => state.AllTruckData);
  console.log(customerTokenSelector);

  const handleClick = () => {
    if (OTP === ownerOtpSelector) {
      logIn = "true";
      history.push("/TCaddtrip");
      console.log(logIn);
    } else {
      logIn = "false";
      console.log(logIn);
      setErrorOTP("Invalid otp");
    }
  };

  const ResendCode = async () => {
    setOTP("");
    setErrorOTP("");
    await axios(
      localAxios(
        "/SMSLogin",
        qs.stringify({
          mobileNum: customerMobileNumSelector,
        })
      )
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode === 200) {
          dispatch(ownerOtp(res.data.otp));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = () => {
    const requestList = [];
    const listOfTrtips = [];
    const OwnerToken = customerTokenSelector;

    if (allTruckDataSelector) {
      console.log(allTruckDataSelector.length,"0000000000000000000");
      for (let truck of allTruckDataSelector) {
        const data = qs.stringify({ truckNo: truck.truck_no });
        requestList.push(
          axios(localAxiosToken("/getTripByTruckNo", data, OwnerToken)).then(
            (res) => {
              if (res.data.statusCode === 200) {
                const trips = res.data.message.tripDetails;
                // const truck = res.data.message.truckDetails;
                // console.log(truck, "TRIPS");
                if (trips.length > 0 ) {
                  // trips.map((data, index) => {
                  //   data.capacity_inkgs = truck[index].capacity_inkgs;
                  //   data.capacity_inspace = truck[index].capacity_inspace;
                  // });
                  listOfTrtips.push(...trips.reverse());
                  console.log(listOfTrtips.length,"________________________________list of trip")
                }
              }
            }
          )
        );
      }
      Promise.all(requestList).then(() => dispatch(tripHistory(listOfTrtips)));
    } else {
      dispatch(tripHistory(false));
    }
  };

  return (
    <form>
      {fetchData()}
      <div className="transportCompanyOtp1">
        <div className="transportOtp1">
          <pre>
            {`Enter the 4-Digit Mobile Verification Code 
  You shall recieve the code to your Number`}
          </pre>
          <h1>Verification Code</h1>
          <div className="otpboxTC1">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          {errorOTP && <h5 className="errorTC1"> {errorOTP} </h5>}
          <p>Hint:Your OTP is {ownerOtpSelector}</p>
          <h6 onClick={ResendCode}>Resend Verification Code</h6>
          <div className="arrowTCO1">
            <img
              src={blackarrow}
              onClick={handleClick}
              className="arrowTCO1style"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default TCotp1;