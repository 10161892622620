import * as AiIcons from "react-icons/ai";

export const POsidebar = [
  {
    title: "Close",
    icon: <AiIcons.AiOutlineClose color="white" />,
    cName: "navText",
  },
  {
    title: "Notifications",
    cName: "navText",
  },
  {
    title: "Ultimate Delivery App Share",
    cName: "navText",
  },
  {
    title: "Feedback",
    cName: "navText",
  },
  {
    title: "Privacy Policy",
    cName: "navText",
  },
  {
    title: "Contact Us",
    cName: "navText",
  },
  {
    title: "Your Delivery Packages",
    path: "/deliverybooked",
    cName: "navText",
  },
  {
    title: "Your Receiving Packages",
    path: "/receivingPersonPackages",
    cName: "navText",
  },
  {
    title: "Sign Out",
    path: "/home",
    cName: "navText",
  },
];