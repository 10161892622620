import React, { useState } from "react";
import Ultimatedeliverywhite from "../../Images/Ultimatedeliverywhite.png";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import IconButton from "@material-ui/core/IconButton";
import "./TOHeader.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TOSidebarData } from "./TOSidebarData";
import { IconContext } from "react-icons";
import { useMediaQuery } from "react-responsive";

function TOHeader() {
  const [sidebar, setSidebar] = useState(false);
  const OwnerFullName = useSelector((state) => state.OwnerFullName);
  const showSidebar = () => setSidebar(!sidebar);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 300px)",
  });

  return (
    <div className="toHeaderBody">
      <div className="ultimatedeliveryimageBlock">
        <img className="ultimatedeliveryImage" src={Ultimatedeliverywhite} />
        <div className="ultimatedeliveryBlock">
          <pre className="ultimatedeliveryStyle">
            {`
ULTIMATE 
 DELIVERY
                        `}
          </pre>
        </div>
      </div>
      <div className="toprofileStyle">
        <IconContext.Provider value={{ color: "black" }}>
          <span className="towelcomeText">
            Welcome,
            <span className="toprofileName">{`${" "}${OwnerFullName}`} !</span>
          </span>
          <IconButton
            aria-label="account"
            onClick={showSidebar}
            className="ml-2"
          >
            {isMobile ? (
              <AccountCircleRoundedIcon color="disabled" fontSize="small" />
            ) : (
              <AccountCircleRoundedIcon color="disabled" fontSize="large" />
            )}
          </IconButton>
        </IconContext.Provider>
      </div>
      <div className={sidebar ? "sideNavTO" : "nav-menu"}>
        <nav className="navLinksInTruckHeader">
          <ul className="nav-menu-items p-0" onClick={showSidebar}>
            {TOSidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span className="title">{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default TOHeader;