import React from "react";
import Header from "../PoHeader/Header";
import { useHistory } from "react-router-dom";
import "./deliverybooked.css";
import { useSelector } from "react-redux";
import PackageComponent from "../PoPackageComponent/PackageComponent";

function Deliverybooked() {
  const history = useHistory();
  const handleClick = () => history.push("/Details");
  const getCustomerPackages = useSelector((state) => state.CustomerPackages);
  console.log(getCustomerPackages);

  return (
    <div className="deliveryPackagesPage">
      <div className="headerInDeliveryPackages">
        <Header />
      </div>
      <div className="mainDivInDeliveryPackages">
        <div className="deliveryPackagesHeading">
          <h1 className="text-center deliveryPackageFont ">
            Your Delivering Packages
          </h1>
        </div>
        {getCustomerPackages ? (
          <div className="outlineInDeliveryPackages">
            <PackageComponent packages={getCustomerPackages}></PackageComponent>
            <div className="down">
              <a
                className="text-black font-weight-normal "
                href="#"
                style={{ textDecoration: "underline" }}
                onClick={handleClick}
              >
                {" "}
                Want to Book a Truck for another Package ?
              </a>
            </div>
          </div>
        ) : (
          <div className="outlineInDeliveryPackages">
            <p style={{ fontSize: "20px", marginTop: "10%" }}>
              You don't Have Packages for Delivery
            </p>
            <div className="down">
              <a
                className="text-black font-weight-normal "
                href="#"
                style={{ textDecoration: "underline" }}
                onClick={handleClick}
              >
                {" "}
                Want to Book a Truck for another Package ?
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Deliverybooked;