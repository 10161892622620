import { NavLink } from "react-router-dom";

function Navigation2() {
  return (
    <div>
      <NavLink to="/"> </NavLink>
      <NavLink to="/TCRegister"></NavLink>
      <NavLink to="/TCvehicleinfo"></NavLink>
      <NavLink to="/TCotp"></NavLink>
      <NavLink to="/TClogin"></NavLink>
      <NavLink to="/TCotp1"></NavLink>
      <NavLink to="/TCaddtrip"></NavLink>
      <NavLink to="/TCtripdetails"></NavLink>
      <NavLink to="/TCdeliverydetails"></NavLink>
      <NavLink to="/TCmap"></NavLink>
      <NavLink to="/TCdeliveryrequests"></NavLink>
      <NavLink to="/TCadddeliveries"></NavLink>
      <NavLink to="/TCaddnewtruck"></NavLink>
      <NavLink to="/TCdeliverychecklist"></NavLink>
      <NavLink to="/TCdeletethetruck"></NavLink>
      <NavLink to="/TCPRegister"></NavLink>
      <NavLink to="/TCPlogin"></NavLink>
    </div>
  );
}

export default Navigation2;
