import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1%;
`;

export const Radio = styled.input`
  display: none;
`;

export const Rating = styled.div`
  cursor: pointer;
`;