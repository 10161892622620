import React from "react";
import TCHeader from "../TcHeader/TCHeader";
import "./TCMap.css";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import whitecall from "../../Images/whitecall.png";

function TCMap() {
  const history = useHistory();

  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 20.5937,
    lng: 78.9629,
  };
  const closeButton = () => {
    history.push("/TCdeliverydetails");
  };

  return (
    <div className="tcMap" data-component="tcmap">
      <div className="headerInTcMap">
        <TCHeader />
      </div>
      <div className="mainDivInTcMap">
        <LoadScript googleMapsApiKey="AIzaSyA4QqiMxUNpXNs2UkL6VsDK196sIB2TlYU">
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={8}
            center={defaultCenter}
          />
        </LoadScript>
        <div className="tcfooter">
          <button>
            <img src={whitecall} className="mr-2" width={30} height={30} />
            Call Delivery Partner
          </button>
          <input
            type="submit"
            name=""
            value="DONE"
            className="float-right"
            onClick={closeButton}
          />
        </div>
      </div>
    </div>
  );
}

export default TCMap;