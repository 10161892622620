import React, { useState } from "react";
import "./TOLogin.css";
import { useHistory } from "react-router-dom";
import Arrow from "../../Images/Arrow.png";
import { useDispatch } from "react-redux";
import qs from "querystring";
import axios from "axios";
import {
  ownerToken,
  ownerOtp,
  ownerMobileNum,
  ownerFullName,
} from "../../Redux/actions/ownerInfo";
import { truckN0, tripHistory,truckDetails } from "../../Redux/actions/ownerTruckInfo";
import { localAxios } from "../../Axios/axios";
import { deliveryRequests } from "../../Redux/actions/tripsAvailable";

function TOLogin() {
  const history = useHistory();
  const regTOFunction = () => history.push("/TORegister");
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  const [errorTOLogin, setErrorTOLogin] = useState("");
  const dispatch = useDispatch();
  let login = true;
  const [mobileNumber, setMobileNumber] = useState("");

  const onSubmit = async () => {
    const isValid = formValidation();
    if (isValid) {
      const loginData = qs.stringify({ mobileNum: mobileNumber });
      await axios(localAxios("/SMSLogin", loginData))
        .then((response) => {
          console.log(`respose data here ${JSON.stringify(response.data)}`);
          if (response.data.statusCode !== 400) {
            if (response.data.truckOwner) {
              console.log(response.data);
              console.log(response.data.truckDetails[0].truck_no);
              dispatch(ownerOtp(response.data.otp));
              dispatch(ownerMobileNum(response.data.truckOwner[0].mobile_num));
              dispatch(ownerToken(response.data.token));
              const { token } = response.data;
              console.log(response.data.token);
              console.log(token);
              dispatch(truckDetails(response.data.truckDetails[0]))
              dispatch(ownerFullName(response.data.truckOwner[0].full_name));
              dispatch(truckN0(response.data.truckDetails[0].truck_no));
              login = true;
              dispatch(tripHistory([]));
              dispatch(deliveryRequests([]));
              history.push("./TOotp1");
            }
            login = false;
            setErrorTOLogin("Mobile Number is not registered");
          }
          login = false;
          return 0;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formValidation = () => {
    const mobileNumberErr = {};
    let isValid = true;
    console.log(mobileNumber);
    if (mobileNumber.length === 0) {
      mobileNumberErr.mobileNumberEmpty = "Mobile Number is required";
      isValid = false;
    } else if (isNaN(mobileNumber)) {
      mobileNumberErr.mobileNumber123 = "Mobile Number must have numbers";
      isValid = false;
    } else if (mobileNumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile Number should be 10 digits";
      isValid = false;
    } else if (mobileNumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile Number should be 10 digits";
      isValid = false;
    } else {
      isValid = true;
    }
    setMobileNumberErr(mobileNumberErr);
    return isValid;
  };

  function onChangetextremovevalidationmessage() {
    setMobileNumberErr("");
    setErrorTOLogin("");
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="heighto">
        <div className="packageToLoginBlock">
          <div className="packageToLoginTitleBlock">
            <pre className="packageToLoginTitle">
              {"Want to"} <b>{"Send"} </b>
              <p>
                {"(or)"} <b>{"Receive"} </b>{"Package?"}
              </p>
            </pre>
          </div>
          <div className="otpToDetailsInPackage">
            <h1 className="mb-0">Login</h1>
            <p>
              Not registered? Please
              <span onClick={() => regTOFunction()}> <u>Register</u></span>
            </p>
          </div>
          <div className="loginToInPackage">
            <label
              alt="Mobile number"
              className={mobileNumber ? "active" : " "}
              htmlFor="Mobile Number"
              placeholder="Mobile Number"
            >
              Mobile Number
            </label>
            <input
              type="text"
              placeholder=""
              onChange={(e) => {
                setMobileNumber(e.target.value);
                onChangetextremovevalidationmessage();
              }}
            />
            {Object.keys(mobileNumberErr).map((key) => {
              return <div style={{ color: "red" }}>{mobileNumberErr[key]}</div>;
            })}
            {errorTOLogin && <h5 className="errorTOLogin"> {errorTOLogin} </h5>}
          </div>
          <div className="packageToArroww">
            <img
              src={Arrow}
              onClick={onSubmit}
              className="packageToArrowwStyle"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default TOLogin;