import * as AiIcons from "react-icons/ai";

export const TOSidebarData = [
  {
    title: "Close",
    icon: <AiIcons.AiOutlineClose color="white" />,
    cName: "nav-text",
  },
  {
    title: "Pending Delivery Request",
    cName: "nav-text",
  },
  {
    title: "Notifications",
    cName: "nav-text",
  },
  {
    title: "Ultimate Delivery App Share",
    cName: "nav-text",
  },
  {
    title: "Feedback",
    cName: "nav-text",
  },
  {
    title: "Privacy Policy",
    cName: "nav-text",
  },
  {
    title: "Contact Us",
    cName: "nav-text",
  },
  {
    title: "Sign Out",
    path: "/home",
    cName: "nav-text",
  },
];