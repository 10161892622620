import React, { onChange, useState } from "react";
import TCHeader from "../TcHeader/TCHeader";
import "./TCTripdetails.css";
import SearchField from "react-search-field";
import MinimizeIcon from "@material-ui/icons/Minimize";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Truck from "../../Images/Truck.png";
import { useHistory } from "react-router-dom";
import Circle from "../../Images/circle.png";
import { useSelector, useDispatch } from "react-redux";
import { pickUp, drop, pickUpDate } from "../../Redux/actions/packageDetails";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import {
  modalPageOpening,
} from "../../Redux/actions/transportCompanyInfo";
import ModalPage from "../TcModalPage/TcModal";
import qs from "querystring";

function TCTripdetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [driverName, setDriverName] = useState("");
  const [driverMobileNumber, setDriverMobileNumber] = useState("");
  const [DriverNameErr, setDriverNameErr] = useState({});
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  const selectedTruckDetails = useSelector((state) => state.SelectedTruck);
  const allTrucksInCompany = useSelector((state) => state.AllTruckData);
  const pickUpPoint = useSelector((state) => state.PickUp);
  const dropPoint = useSelector((state) => state.Drop);
  const startDateData = useSelector((state) => state.PickUpDate);
  const ownerTokenSelector = useSelector((state) => state.OwnerToken);
  const TripHistory = useSelector((state) => state.TripHistory);
  const modalPageDataSelector = useSelector((state) => state.ModalPageOpening);
  const indian_cities = useSelector((state) => state.CitiesList);


  function openModal() {
    dispatch(modalPageOpening("true"));
  }

  console.log(selectedTruckDetails);
  console.log(allTrucksInCompany[selectedTruckDetails]);

  const selectedTruckData = allTrucksInCompany[selectedTruckDetails];

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      console.log(pickUpPoint);
      console.log(dropPoint);
      console.log(startDateData);
      console.log(driverName);
      console.log(driverMobileNumber);
      console.log(ownerTokenSelector);
      const OwnerToken = ownerTokenSelector;
      const formData = `${qs.stringify({
        source: pickUpPoint,
        destination: dropPoint,
        truckNo: selectedTruckData.truck_no,
        truckDriver: driverName,
        truckDriverNum: driverMobileNumber,
        truckModel: selectedTruckData.truck_model,
      })}&startDate=${startDateData}`;
      if(dropPoint != pickUpPoint)
      axios(localAxiosToken("/addTrip", formData, OwnerToken))
        .then((res) => {
          console.log(res.data);
          if (res.data.statusCode == 400) {
            alert("Trip not added with this details");
          } else {
            console.log(
              "adding trip details and displaying space and weight for ",
              selectedTruckData.truck_no
            );
            const latestTrip = res.data.details[0];
            axios(
              localAxiosToken(
                "/getTripByTruckNo",
                qs.stringify({ truckNo: selectedTruckData.truck_no }),
                OwnerToken
              )
            )
              .then((res) => {
                console.log(res.data);
                if (res.data.statusCode === 200) {
                  const truck = res.data.message.truckDetails;
                  if (truck != null) {
                    latestTrip.capacity_inkgs = parseInt(truck[0].capacity_inkgs);
                    latestTrip.capacity_inspace = parseInt(
                      truck[0].capacity_inspace
                    );
                  }
                  console.log(latestTrip);
                  TripHistory.push(latestTrip);
                  console.log(TripHistory);
                  dispatch(tripHistory(TripHistory.reverse()));
                  history.push("/TCdeliverydetails");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const formValidation = () => {
    var specialCharacterPatternInTc = /[^a-zA-Z ]+/;
    var specialCharacterForMobileInTc = /[^0-9]+/;
    const DriverNameErr = {};
    const mobileNumberErr = {};
    let isValid = true;
    if (driverName.length === 0) {
      DriverNameErr.DriverNameEmpty = "DriverName should be required";
      isValid = false;
    } else if (specialCharacterPatternInTc.test(driverName)) {
      DriverNameErr.DriverNameSpecial =
        "should not include special characters ";
      isValid = false;
    } else {
    }
    if (driverMobileNumber.length === 0) {
      mobileNumberErr.mobileNumberEmpty = "Mobile number should be required";
      isValid = false;
    } else if (specialCharacterForMobileInTc.test(driverMobileNumber)) {
      mobileNumberErr.mobileNumberChar =
        "should not include special characters ";
      isValid = false;
    } else if (driverMobileNumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile number should be 10 Digits";
      isValid = false;
    } else if (driverMobileNumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile number should be 10 Digits";
      isValid = false;
    } else {
    }
    setDriverNameErr(DriverNameErr);
    setMobileNumberErr(mobileNumberErr);
    return isValid;
  };

  function onChangetextremovevalidationmessage() {
    setDriverNameErr("");
    setMobileNumberErr("");
  }

  return (
    <div className="tcTripDetailsPage">
      <div className="headerInTcDetailsPage">
        <TCHeader />
      </div>
      <div className="mainDivInTcDetails">
        <div className="firstDivInTcDetailsPage">
          <h4 className="addTripText" onClick={openModal}>
            Add Trip +
          </h4>
          <div className="searchBoxInTcDetails">
            <SearchField
              placeholder="Search with Truck/Location"
              onChange={onChange}
            />
          </div>
          {
            (console.log(modalPageDataSelector),
            modalPageDataSelector ? <ModalPage></ModalPage> : null)
          }
        </div>
        <div className="secondDivInTcDetails">
          <div className="tcTripCard">
            <div className="AddTrip1">
              <img
                src={Truck}
                width={50}
                height={30}
                className="ml-2 float-left mr-2 mt-2"
              />
              <span className="float-left mt-1 truck22">
                Truck {Number(selectedTruckDetails) + 1}(
                {selectedTruckData.truck_no})
              </span>
              <img
                src={Circle}
                width={25}
                height={25}
                className="ml-2 float-left mr-2 mt-2"
              />
              <p className="text-right m-0">
                Minimize
                <IconButton aria-label="minimize">
                  <MinimizeIcon style={{ color: "black" }} />
                </IconButton>
              </p>
            </div>
            <div className="padding1">
              <div className="">
                <p className="p-0 m-0 t-l ">
                  Available Space:
                  <span className="font-weight-bold">
                    {selectedTruckData.capacity_inkgs} KG
                  </span>
                </p>
                <p className="p-0 m-0 t-l">
                  Available Weight:
                  <span className="font-weight-bold">
                    {selectedTruckData.capacity_inspace} FT
                  </span>
                </p>
              </div>
              <div>
                <div className="mt-2 ">
                  <select
                    name="city"
                    className="endpoint"
                    onChange={(e) => {
                      dispatch(pickUp(e.target.value));
                    }}
                  >
                    <option default>Trip Start Point</option>
                    {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
                  </select>
                </div>
                <div className="mt-2 ">
                  <select
                    name="city"
                    className="endpoint"
                    onChange={(e) => {
                      dispatch(drop(e.target.value));
                    }}
                  >
                    <option default>Trip End Point</option>
                  {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="mt-2 d-flex ">
                <input
                  type="datetime-local"
                  id="meeting-time"
                  name="meeting-time"
                  onChange={(e) => dispatch(pickUpDate(e.target.value))}
                ></input>
              </div>
              <div className="mt-2 d-flex driverAndMobileNumDiv">
                <div style={{ width: "60%", marginRight: "3%", height: "2%" }}>
                  <p className="float-left m-0">Driver Name</p>
                  <input
                    type="text"
                    value={driverName}
                    onChange={(e) => {
                      setDriverName(e.target.value);
                      onChangetextremovevalidationmessage();
                    }}
                  />
                  {Object.keys(DriverNameErr).map((key) => {
                    return (
                      <div style={{ color: "red", fontSize: "11px" }} key={key}>
                        {DriverNameErr[key]}
                      </div>
                    );
                  })}
                </div>
                <div style={{ width: "37%" }}>
                  <p className="float-left m-0">Mobile Number</p>
                  <input
                    type="text"
                    value={driverMobileNumber}
                    onChange={(e) => {
                      setDriverMobileNumber(e.target.value);
                      onChangetextremovevalidationmessage();
                    }}
                  />
                  {Object.keys(mobileNumberErr).map((key) => {
                    return (
                      <div style={{ color: "red", fontSize: "8px" }} key={key}>
                        {mobileNumberErr[key]}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <div>
                  <a className="bla">
                    Delete Trip
                    <span>
                      <IconButton aria-label="delete">
                        <DeleteIcon
                          style={{ color: "#fb6504", fontSize: "20px" }}
                        />
                      </IconButton>
                    </span>
                  </a>
                </div>
                <div>
                  <input
                    type="submit"
                    value="Save Details"
                    className="mt-2 float-right"
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TCTripdetails;