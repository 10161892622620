import React from "react";
import AddIcon from "@material-ui/icons/Add";
import "./TripComponent.css";
import IconButton from "@material-ui/core/IconButton";
import { Col, Row } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { localAxiosToken } from "../../Axios/axios";
import qs from "querystring";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  availableWeightData,
  availableSpaceData,
} from "../../Redux/actions/ownerTruckInfo";
import {
  allTripIdsNTruckNos,
  deliveryRequests,
  tripDetails,
} from "../../Redux/actions/tripsAvailable";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";
import { truckDetails } from "../../Redux/actions/ownerTruckInfo";

function TripComponent({ TripHistory1 }) {
  const [status1, setStatus1] = React.useState(false);

  const history = useHistory();

  console.log(TripHistory1);

  const dispatch = useDispatch();
  const OwnerToken = useSelector((state) => state.OwnerToken);

  const TruckNo = useSelector((state) => state.TruckNo);

  const addDelivery = (tripId, truckNo, startDate, total_packages) => {
    dispatch(tripDetails({ tripId, truckNo, startDate, total_packages }));
    tripPacakages(tripId, "add Delivery");
  };

  function tripPacakages(tripId, page) {
    const params = qs.stringify({ tripId });
    dispatch(availableWeightData(availableWeight));
    dispatch(availableSpaceData(availableSpace));
    axios(localAxiosToken("/getTripPackages", params, OwnerToken))
      .then((res) => {
        console.log(params);
        console.log(res.data);
        dispatch(deliveryRequests(res.data.packageDetails));
        console.log(res.data.packageDetails);
        if (page === "CheckList") return history.push("./TODeliverychecklist");
        else if (page === "add Delivery") {
          history.push("./AddDelivery");
        } else return history.push("./deliveryrequests");
      })
      .catch((err) => console.log(err));
  }

  const onClickDR = (tripId, truckNo, startDate) => {
    dispatch(tripDetails({ tripId, truckNo, startDate}));
    tripPacakages(tripId, "DR");
  };

  const checkList = (tripId) => {
    console.log("checklist called");
    dispatch(allTripIdsNTruckNos(tripId));
    tripPacakages(tripId, "CheckList");
  };

  console.log(typeof TripHistory1.delivered_packages);
  console.log(typeof TripHistory1.total_packages, TripHistory1.booked_weight);

  const TruckDetailsSelector = useSelector((state) => state.TruckDetails);

  var availableWeight =
    TruckDetailsSelector.capacity_inkgs -
    (TripHistory1.booked_weight == null ? 0 : TripHistory1.booked_weight);
  var availableSpace =
    TruckDetailsSelector.capacity_inspace -
    (TripHistory1.booked_space == null ? 0 : TripHistory1.booked_space);
  console.log(
    availableWeight,
    TruckDetailsSelector.capacity_inkgs,
    TripHistory1.booked_weight
  );

  console.log(availableSpace, "availableWeightSelector");
  console.log(TruckDetailsSelector);
  
  const fetchData = () => {
    const data = qs.stringify({ truckNo: TruckNo });

    axios(localAxiosToken("/getTripByTruckNo", data, OwnerToken))
      .then((response) => {
        console.log(response.data);
        const tripDetails = response.data.message.tripDetails
          ? response.data.message.tripDetails
          : 0;
        console.log(`${JSON.stringify(tripDetails)}`);
        console.log(tripDetails.length);
        console.log(response.data.message.truckDetails[0]);
        const truck = response.data.message.truckDetails[0];
        console.log(truck);

        dispatch(tripHistory(tripDetails.reverse()));
        dispatch(truckDetails(response.data.message.truckDetails[0]));
        console.log(response.data.message.tripDetails[0].truck_no);
      })
      .catch((err) => console.log(err));
  };

  function deleteTrip(tripId) {
    const params1 = qs.stringify({ tripId });

    axios(localAxiosToken("/deleteTrip", params1, OwnerToken))
      .then((res) => {
        console.log(params1);

        console.log(res.data);
        fetchData(TripHistory1.truckNo);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <div className="cardx">
        <div className="d-flex flex-row">
          <h4 className="font-weight-normal">Trip {TripHistory1.trip_id}</h4>
          <h4 className="ml-auto font-weight-normal">
            Maximize
            <IconButton>
              <AddIcon
                style={{ color: "orange", fontSize: "30px" }}
                onClick={() => setStatus1(!status1)}
              />
            </IconButton>
          </h4>
        </div>
        <Col className="p-0">
          {status1 ? (
            <Col className="cover p-0">
              <Row className="brdr">
                <h5 className="m-0 mt-3 ml-3 mr-auto font-weight-normal">
                  Trip {TripHistory1.trip_id}
                </h5>
                <h5 className="mt-3 ml-auto font-weight-normal">{`${"Status :"}`}</h5>
                <h5 className="ml-2 mt-3 status mr-3">
                  {Number(TripHistory1.total_packages) > 0
                    ? TripHistory1.delivered_packages ===
                      Number(TripHistory1.total_packages)
                      ? "Delivered"
                      : " In Transit"
                    : "Yet to Pickup"}
                </h5>
              </Row>
              <Row className="brdr1">
                <Col className="col-5">
                  <p className="m-0 t-l">Available Weight</p>
                  <p className="font-weight-bold m-0 t-l">{availableWeight}. KG</p>
                  <div>
                    <div className="mt-2 t-l ml-3 mb-3">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Trip Start Point
                      </p>
                      <p className="m-0">{TripHistory1.source}</p>
                    </div>
                    <div className="t-l ml-3 mb-3">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Trip End Point
                      </p>
                      <p className="m-0">{TripHistory1.destination}</p>
                    </div>
                    <div className="t-l ml-3 mb-3">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Date & Time
                      </p>
                      <p className="m-0">
                        {TripHistory1.start_date.slice(0, 10)}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="col-3">
                  <p className="m-0 t-l">Available Space</p>
                  <p className="font-weight-bold m-0 t-l">{availableSpace}. FT</p>
                </Col>
                <Col className="col-4">
                  <p className="m-0 font-weight-bold">Packages</p>
                  <h2 className="packagescolor">
                    {TripHistory1.total_packages}
                  </h2>
                  <p className="dropdone">
                    Drop Done -
                    <span className="ml-1 colorgdone mr-3">
                      {TripHistory1.delivered_packages}
                    </span>
                  </p>
                  <p
                    className="colorod mr-3"
                    onClick={() => checkList(TripHistory1.trip_id)}
                  >
                    Check List
                  </p>
                </Col>
                <Row className="brdr1 under">
                  <Col className="col-5">
                    <a
                      href="#"
                      className="float-left ml-2 mb-2 fonadd font-weight-normal text-dark"
                      onClick={() =>
                        addDelivery(
                          TripHistory1.trip_id,
                          TripHistory1.truck_no,
                          TripHistory1.start_date,
                          TripHistory1.total_packages
                        )
                      }
                    >
                      + Add Delivery
                    </a>
                  </Col>
                  <Col className="col-3">
                    <p className="m-0">
                      Delete Trip
                      <a>
                        <DeleteIcon
                          style={{ color: "orange", fontSize: "25px" }}
                          onClick={() => deleteTrip(TripHistory1.trip_id)}
                        />
                      </a>
                    </p>
                  </Col>
                  <Col className="col-4">
                    <p className="colorod">
                      Delivery Requests
                      <ArrowForwardIosIcon
                        style={{ color: "#FF8200" }}
                        fontSize="small"
                        onClick={() =>
                          onClickDR(
                            TripHistory1.trip_id,
                            TripHistory1.truck_no,
                            TripHistory1.start_date,
                          )
                        }
                      />
                    </p>
                  </Col>
                </Row>
              </Row>
            </Col>
          ) : null}
        </Col>
      </div>
    </div>
  );
}

export default TripComponent;