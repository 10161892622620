import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Image from "./Home/StartingPage/Image";
import Home from "./Home/Home/Home";
import Navigation from "./PackageOwner/PoNavigation/Navigation";
import Navigation1 from "./TruckOwner/ToNavigation/Navigation1";
import Navigation2 from "./TransportCompany/TcNavigation/Navigation2";
import PORegister from "./PackageOwner/PoRegister/PORegister";
import Otp from "./PackageOwner/PoOtp/Otp";
import Login from "./PackageOwner/PoLogin/Login";
import Otp1 from "./PackageOwner/PoOtp/Otp1";
import PackageOwnerAddPackage from "./PackageOwner/PoAddPackage/PackageOwnerAddPackage";
import Details from "./PackageOwner/PoDetails/Details";
import AvailableTrucks from "./PackageOwner/PoAvailableTrucks/AvailableTrucks";
import podb1 from "./PackageOwner/PoDeliveryBooked/podb1";
import Header from "./PackageOwner/PoHeader/Header";
import TrackDelivery from "./PackageOwner/PoTrackDelivery/TrackDelivery";
import pkgStatus from "./PackageOwner/PoPackageStatus/pkgstatus";
import Map from "./PackageOwner/PoMap/Map";
import Podelivery from "./PackageOwner/PoPackageDelivered/podelivery";
import deliverybooked from "./PackageOwner/PoDeliveringPackages/deliverybooked";
import TOotp from "./TruckOwner/ToOtp/TOotp";
import TOLogin from "./TruckOwner/ToLogin/TOLogin";
import TOotp1 from "./TruckOwner/ToOtp/TOotp1";
import TOHeader from "./TruckOwner/ToHeader/TOHeader";
import addtrip from "./TruckOwner/ToAddTrip/addtrip";
import TORegister from "./TruckOwner/ToRegister/TORegister";
import DeliveryRequests from "./TruckOwner/ToDeliveryRequests/DeliveryRequests";
import Tripdetails from "./TruckOwner/ToTripDetails/Tripdetails";
import AddDelivery from "./TruckOwner/ToAddDelivery/AddDelivery";
import TripStatus from "./TruckOwner/ToTripStatus/TripStatus";
import TCRegister from "./TransportCompany/TcRegister/TCRegister";
import TCotp from "./TransportCompany/TcOtp/TCotp";
import TCLogin from "./TransportCompany/TcLogin/TCLogin";
import TCotp1 from "./TransportCompany/TcOtp/TCotp1";
import TCHeader from "./TransportCompany/TcHeader/TCHeader";
import TCAddtrip from "./TransportCompany/TcAddTrip/TCAddtrip";
import TCTripdetails from "./TransportCompany/TcTripDetails/TCTripdetails";
import TCDeliveryRequests from "./TransportCompany/TcDeliveryRequests/TCDeliveryRequests";
import TCMap from "./TransportCompany/TcMap/TCMap";
import TCAddNewTruck from "./TransportCompany/TcAddNewTruck/TCAddNewTruck";
import TCDeliverychecklist from "./TransportCompany/TcDeliveryCheckList/TCDeliverychecklist";
import TCdeletetruck from "./TransportCompany/TcDeleteTheTruck/TCdeletetruck";
import TCPRegister from "./TransportCompany/TcPartner/TcPartnerRegister/TCPRegister";
import TCPLogin from "./TransportCompany/TcPartner/TcPartnerLogin/TCPLogin";
import TCadddeliveries from "./TransportCompany/TcAddDeliveries/TCadddeliveries";
import TODeliverychecklist from "./TruckOwner/ToDeliverychecklist/TODeliverychecklist";
import TOnewdeliveryalert from "./TruckOwner/ToNewDeliveryAlert/TOnewdeliveryalert";
import TCvehicleinfo from "./TransportCompany/TcVehicleInformation/TCvehicleinfo";
import TCdeliverydetails from "./TransportCompany/TcDeliveryDetails/TCdeliverydetails";
import ReceivingPersonPackages from "./PackageOwner/PoReceivingPackages/receivingPersonPackages";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navigation />
        <Navigation1 />
        <Navigation2 />
        <Switch>
          <Route path="/" component={Image} exact />
          <Route path="/home" component={Home} exact />
          <Route path="/PORegister" component={PORegister} exact />
          <Route path="/otp" component={Otp} exact />
          <Route path="/Login" component={Login} exact />
          <Route path="/otp1" component={Otp1} exact />
          <Route
            path="/PackageOwnerAddPackage"
            component={PackageOwnerAddPackage}
            exact
          />
          <Route path="/Details" component={Details} exact />
          <Route path="/availabletrucks" component={AvailableTrucks} exact />
          <Route path="/header" component={Header} exact />
          <Route path="/TOheader" component={TOHeader} exact />
          <Route path="/deliverybooked1" component={podb1} exact />
          <Route path="/Trackdelivery" component={TrackDelivery} exact />
          <Route path="/pkgstatus" component={pkgStatus} exact />
          <Route path="/map" component={Map} exact />
          <Route path="/podelivery" component={Podelivery} exact />
          <Route path="/deliverybooked" component={deliverybooked} exact />
          <Route path="/TORegister" component={TORegister} exact />
          <Route path="/TOotp" component={TOotp} exact />
          <Route path="/TOLogin" component={TOLogin} exact />
          <Route path="/TOotp1" component={TOotp1} exact />
          <Route path="/addtrip" component={addtrip} exact />
          <Route path="/Tripdetails" component={Tripdetails} exact />
          <Route path="/TOdeliveryalert" component={TOnewdeliveryalert} exact />
          <Route path="/deliveryrequests" component={DeliveryRequests} exact />
          <Route path="/AddDelivery" component={AddDelivery} exact />
          <Route path="/TripStatus" component={TripStatus} exact />
          <Route
            path="/TODeliverychecklist"
            component={TODeliverychecklist}
            exact
          />
          <Route path="/TCRegister" component={TCRegister} exact />
          <Route path="/TCvehicleinfo" component={TCvehicleinfo} exact />
          <Route path="/TCotp" component={TCotp} exact />
          <Route path="/TClogin" component={TCLogin} exact />
          <Route path="/TCotp1" component={TCotp1} exact />
          <Route path="/TCheader" component={TCHeader} exact />
          <Route path="/TCaddtrip" component={TCAddtrip} exact />
          <Route path="/TCtripdetails" component={TCTripdetails} exact />
          <Route
            path="/TCdeliverydetails"
            component={TCdeliverydetails}
            exact
          />
          <Route
            path="/TCdeliveryrequests"
            component={TCDeliveryRequests}
            exact
          />
          <Route path="/TCmap" component={TCMap} exact />
          <Route path="/TCadddeliveries" component={TCadddeliveries} exact />
          <Route path="/TCaddnewtruck" component={TCAddNewTruck} exact />
          <Route
            path="/TCdeliverychecklist"
            component={TCDeliverychecklist}
            exact
          />
          <Route path="/TCdeletethetruck" component={TCdeletetruck} exact />
          <Route path="/TCPRegister" component={TCPRegister} exact />
          <Route path="/TCPlogin" component={TCPLogin} exact />
          <Route
            path="/receivingPersonPackages"
            component={ReceivingPersonPackages}
            exact
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
