import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { Container, Radio, Rating } from "./ratingStyles";
import { useSelector } from "react-redux";
import { ownerRating } from "../Redux/actions/ownerInfo";
import { useDispatch } from "react-redux";

const Rate = (truckNo, giveRating) => {
  const [rate, setRate] = useState(0);
  const dispatch = useDispatch();
  const TrackTruckDetailsData = useSelector((state) => state.TrackingTruckData);
  console.log(TrackTruckDetailsData);
  console.log(truckNo, giveRating, "rating");

  const dispachingRating = (ratingValue) => {
    dispatch(ownerRating(ratingValue));
  };

  return (
    <Container>
      {[...Array(5)].map((item, index) => {
        const givenRating = index + 1;
        return (
          <label>
            <Radio
              type="radio"
              value={givenRating}
              onClick={() => {
                setRate(givenRating);
                dispachingRating(givenRating);
                alert(truckNo);
              }}
            />
            <Rating>
              <FaStar
                color={
                  givenRating < rate || givenRating === rate ? "#499B00" : "000"
                }
              />
            </Rating>
          </label>
        );
      })}
    </Container>
  );
};

export default Rate;