import React, { useEffect,useState} from "react";
import "./podb1.css";
import truck3 from "../../Images/truck3.png";
import Header from "../PoHeader/Header";
import logo from "../../Images/logo1.png";
import { useHistory } from "react-router-dom";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useSelector, useDispatch } from "react-redux";
import qs from "querystring";
import axios from "axios";
import { localAxiosToken,localAxios } from "../../Axios/axios";
import { customerPackages } from "../../Redux/actions/customerInfo";
import { trackingTruckData } from "../../Redux/actions/transportCompanyInfo";

function loadScript(src) {
  console.log('text');
  return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
          resolve(true);
      }
      script.onerror = () => {
          resolve(false);
      }
      document.body.appendChild(script);
  })
}

function DeliveryBooked() {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedTruckDetails = useSelector((state) => state.SelectedTruckData);
  const dateSelector = useSelector((state) => state.PickUpDate);
  const entireTruckSelector = useSelector((state) => state.EntireTruck);
  const receivingPersonNameSelector = useSelector(
    (state) => state.ReceivingPersonName
  );
  const packageSpaceSelector = useSelector((state) => state.PackageSpace);
  const packageWeightSelector = useSelector((state) => state.PackageWeight);
  const receivingPersonNumSelector = useSelector(
    (state) => state.ReceivingPersonNum
  );
  const customerTokenSelector = useSelector((state) => state.CustomerToken);
  const CustomerMobileNum = useSelector((state) => state.CustomerMobileNum);
  const CustomerFullName = useSelector(
    (state) => `${state.CustomerFirstName} ${state.CustomerLastName}`
  );

  console.log(packageSpaceSelector,'select')
  console.log(packageWeightSelector,'selectw')

  console.log(selectedTruckDetails);
  console.log(CustomerMobileNum,'CustomerMobileNum');

  const displayRazorpay = async (orderObj) => {

    console.log(orderObj, 'orderObject');

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert("Razorpay SDK failed to load");
      return
    }

    const options = {
      key: "rzp_test_1XidZWeGPkC1uy",
      amount: orderObj.amount,
      currency: "INR",
      name: "Acme Corp",
      description: "Test Transaction",
      image: logo,
      order_id: orderObj.id,
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature)
        const requestBody = qs.stringify({
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id, 
          signature: response.razorpay_signature
        })
        axios(localAxios('/verification', requestBody))
          .then((res) => {
            console.log(res)
          })
          .catch(err => {
            console.log(err);
          })
      },
        // callback_url: '../successPaymentPage/successPaymentPage',
      prefill: {
        name: CustomerFullName,
        contact: CustomerMobileNum
      },
      notes: {
        address: "Razorpay Corporate Office"
      },
      theme: {
        color: "orange"
      }
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.open();

  }

  const handleClick = async () => {
    console.log(selectedTruckDetails);
    const params = `${qs.stringify({
      pickUpPoint: selectedTruckDetails.pickUp,
      dropPoint: selectedTruckDetails.drop,
      entireTruck: entireTruckSelector,
      receivingPersonName: receivingPersonNameSelector,
      receivingPersonNo: receivingPersonNumSelector,
      packageSpace:(entireTruckSelector ? selectedTruckDetails.availableSpace :  packageSpaceSelector),
      packageWeight:(entireTruckSelector ? selectedTruckDetails.availableWeight : packageWeightSelector),
      customerName: CustomerFullName,
      tripId: selectedTruckDetails.tripId,
    })}&date=${dateSelector}`;
    try {
      await axios(localAxiosToken("/addPackage", params, customerTokenSelector))
        .then(async (response) => {
          console.log(response.data);
          console.log(params);
          const packageId = response.data.details[0].package_id;
          console.log(packageId, selectedTruckDetails);
          await axios(
            localAxiosToken(
              "/assignPackage",
              qs.stringify({
                packageId,
                tripId: selectedTruckDetails.tripId,
                truckNo: selectedTruckDetails.truckNo,
                  }),
              customerTokenSelector
            )
          ).then((res) => {
            if (res.data.statusCode === 200) {
              axios(localAxios('/paymentMethod', qs.stringify({
                amount: 300,
                currency: "INR",
                receipt: "receipt",
            })))
                .then((res) => {
                    if (res.status === 200) {
                        const orderObj = res.data.order;
                        console.log(orderObj, 'orderObj')
                        console.log(res.data.order, 'respnseFrom');
                        displayRazorpay(orderObj);
                    }
                })
                .catch(err => {
                    console.log(err, 'err');
                })
            }
            console.log(res.data);
            // axios(
            //   localAxiosToken(
            //     "/getCustomerPackages",
            //     qs.stringify({ mobileNum: CustomerMobileNum }),
            //     customerTokenSelector
            //   )
            // ).then((res) => {
            //   console.log(res);
            //   if (res.data.statusCode === 200){
            //     dispatch(customerPackages(res.data.packageDetails.reverse()));
            //   console.log(res.data.packageDetails);
            //   if (res.data.packageDetails) {
            //     dispatch(trackingTruckData(res.data.packageDetails[0]));
            //   }
            //   history.push("./TrackDelivery");
            // }
            // });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="mainDivForBookTruck">


      <div className="headerPartInBookTruck">
        <Header />
      </div>
      <div className="bookTruckCard">
        <div className="bookTruckTitleDiv">
          <h1 className="bookTruckTitle">Book Your Truck</h1>
        </div>
        <div className="bookTruckDiv">
          <div className="truckCard">
            <div className="truckCardHeader">
              <img
                src={truck3}
                width={60}
                height={40}
                className="truckImageInBookPage"
                alt="truck"
              />
              <span className="modalText">{selectedTruckDetails.model}</span>
            </div>
            <div className="truckData">
              <p className="priceText">
                Estimated Price <span className="priceValue"> Rs 4000 </span>
              </p>
              <div>
                <div className="loactionSymbols">
                  <LocationOnIcon color="disabled" fontSize="large" />
                  <LocationOnIcon color="disabled" fontSize="large" />
                </div>
                <div className="travelData">
                  <div className="pickUpData">
                    <p className="travelDataLine">
                      {selectedTruckDetails.pickUp}
                    </p>
                    <p className="travelDataLine">
                      {selectedTruckDetails.startDate}
                    </p>
                  </div>
                  <div className="destinationData">
                    <p className="travelDataLine">
                      {selectedTruckDetails.drop}
                    </p>
                    {/* <p className="travelDataLine">Barakhamba Rd</p> */}
                    <p className="travelDataLine">4th May, 9.30 PM</p>
                  </div>
                </div>
              </div>
              <div className="availableData">
                <div className="AvailableWidth">
                  <p className="availableNumber">
                    Available Space:
                    <span className="font-weight-bold">
                      {selectedTruckDetails.availableSpace} FT
                    </span>
                  </p>
                  <p className="availableNumber">
                    Available Weight:
                    <span className="font-weight-bold">
                      {selectedTruckDetails.availableWeight} KG
                    </span>
                  </p>
                </div>
                <div>
                  <button className="bookTruckButton" onClick={handleClick}>
                    Book Truck
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryBooked;