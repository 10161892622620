import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import qs from "querystring";
import { localAxios } from "../../Axios/axios";
import {
  customerOtp,
  customerToken,
  customerFirstName,
  customerLastName,
  customerMobileNum,
} from "../../Redux/actions/customerInfo";
import {
  customerPackages,
  receivingPackages,
} from "../../Redux/actions/customerInfo";
import Arrow from "../../Images/Arrow.png";
import "./Login.css";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const regfunction = () => history.push("/PORegister");
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [mobileNum, setMobileNum] = useState("");

  const onSubmit = async () => {
    const isValid = formValidation();
    if (isValid) {
      await axios(
        localAxios(
          "/SMSLogin",
          qs.stringify({
            mobileNum: mobileNum,
          })
        )
      )
        .then((res) => {
          console.log(res);
          console.log(res.data.customerDetails, "");
          if (res.data.statusCode === 200 && res.data.customerDetails) {
            dispatch(customerOtp(res.data.otp));
            dispatch(customerToken(res.data.token));
            dispatch(customerLastName(res.data.customerDetails[0].last_name));
            dispatch(customerFirstName(res.data.customerDetails[0].first_name));
            dispatch(customerMobileNum(mobileNum));
            dispatch(customerPackages(""));
            dispatch(receivingPackages(""));
            dispatch(tripHistory([]));
            history.push("./Otp1");
          } else {
            console.log(`failed! = ${JSON.stringify(res.data)}`);
            setErrorMessage(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formValidation = () => {
    const mobileNumberErr = {};
    var specialCharacterForMobile = /[^0-9]+/;
    let isValid = true;
    if (mobileNum.length === 0) {
      mobileNumberErr.mobileNumberEmpty = "Mobile Number Should be Required";
      isValid = false;
    } else if (specialCharacterForMobile.test(mobileNum)) {
      mobileNumberErr.mobileNumberChar =
        "Should not include special characters";
      isValid = false;
    } else if (mobileNum.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile Number should be 10 digits";
      isValid = false;
    } else if (mobileNum.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile Number should be 10 digits";
      isValid = false;
    } else {
    }
    setMobileNumberErr(mobileNumberErr);
    return isValid;
  };

  function onChangetextremovevalidationmessage() {
    setMobileNumberErr("");
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="eexample1">
        <div className="packageLoginBlock">
          <div className="packageLoginTitleBlock">
            <pre className="packageLoginTitle">
              {"Want to"} <b>{"Send"} </b>
              <p>
                {"(or)"} <b>{"Receive"} </b>
                {"Package?"}
              </p>
            </pre>
          </div>
          <div className="otpDetailsInPackage">
            <h2 className="mb-0">Login</h2>
            <h3>
              Not registered? Please
              <span onClick={() => regfunction()}>
                {" "}
                <u>Register</u>
              </span>
            </h3>
          </div>
          <div className="loginInPackage">
            <label
              alt="Mobile number"
              className={mobileNum ? "active" : " "}
              htmlFor="Mobile Number"
              placeholder="Mobile Number"
            >
              Mobile Number
            </label>
            <input
              type="text"
              onChange={(e) => {
                setMobileNum(e.target.value);
                onChangetextremovevalidationmessage();
              }}
            />
            {Object.keys(mobileNumberErr).map((key) => {
              return (
                <div className="colorProperty">{mobileNumberErr[key]}</div>
              );
            })}
          </div>
          <div className="packageArroww">
            <img
              src={Arrow}
              onClick={onSubmit}
              className="packageArrowwStyle"
            ></img>
          </div>
          {errorMessage ? (
            <div>
              <p className="errorMessageStyle">{errorMessage}</p>
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
}

export default Login;
