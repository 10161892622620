import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useHistory } from "react-router-dom";
import truck3 from "../../Images/truck3.png";
import "./TruckComponent.css";
import { useDispatch, useSelector } from "react-redux";
import { selectedTruckData } from "../../Redux/actions/tripsAvailable";
import Rating from "../../Rating/rating";
import axios from "axios";
import { localAxios } from "../../Axios/axios";
import qs from "querystring";

function TruckComponent({ allData }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerTokenSelector = useSelector((state) => state.CustomerToken);
  let ratingValue = allData.rating;
  const [rating, setRating] = useState(0);
  console.log(allData.source);
  console.log(allData.destination);
  console.log(allData);
  console.log(ratingValue);

  const selectedTruck = {
    truckNo: allData.truck_no,
    model: allData.truck_model,
    pickUp: allData.source,
    drop: allData.destination,
    availableSpace: allData.capacity_inspace - allData.booked_space,
    availableWeight: allData.capacity_inkgs - allData.booked_weight,
    tripId: allData.trip_id,
    mobilenumber: allData.truckowner_mobile_num,
    rating: allData.rating,
    startDate: allData.start_date,
  };

  console.log(selectedTruck.rating);

  const handleClick = () => {
    dispatch(selectedTruckData(selectedTruck));
    history.push("/deliverybooked1");
  };

  useEffect(() => {
    console.log("text");
    axios(
      localAxios(
        "/getTruckRating",
        qs.stringify({ truckNo: allData.truck_no }),
        customerTokenSelector
      )
    ).then((res) => {
      if (res.data.statusCode === 200) {
        console.log(res.data.AverageRating);
        setRating(parseInt(res.data.AverageRating));
        console.log(rating);
      } else {
        console.log(res.data.AverageRating);
        setRating(res.data.AverageRating);
        console.log(rating);
      }
    });
  });

  return (
    <div>
      {
        (allData.rating > 0
          ? (ratingValue = allData.rating)
          : (ratingValue = 0),
        console.log(ratingValue))
      }
      <div className="card-4  mt-2 mr-2">
        <div className="Ace">
          <img
            src={truck3}
            width={40}
            height={30}
            alt=" "
            className="ml-2 float-left mr-2 mt-2 mb-2"
          />
          <span className="float-left mt-2 text-white">
            {allData.truck_model}
          </span>
        </div>
        <div>
          <Row>
            <Col className="col-7">
              <div className="float-left ">
                <p>
                  Estimated Price <span className="color"> Rs 4000 </span>
                </p>
              </div>
              <div className="float-left pl-2 pb-3 locationIconInTruck">
                <LocationOnIcon
                  color="disabled"
                  fontSize="large"
                  className="t-l"
                />
                <p className="p-0 m-0 t-l">{allData.source}</p>
                {/* <p className="p-0 m-0 t-l">Miyapur Main Road</p> */}
                <p className="p-0 m-0 t-l">{allData.start_date}</p>
              </div>
              <div className="float-left pl-2 pb-2">
                <p className="p-0 m-0 t-l ">
                  Available Space:
                  <span className="font-weight-bold">
                    {allData.capacity_inspace - allData.booked_space} FT
                  </span>
                </p>
                <p className="p-0 m-0 t-l">
                  Available Weight:
                  <span className="font-weight-bold">
                    {allData.capacity_inkgs - allData.booked_weight} KG
                  </span>
                </p>
              </div>
            </Col>
            <Col className="col-5 secondLocationDiv">
              <Rating truckNo={allData.truck_no} giveRating="abc" />
              <LocationOnIcon
                color="disabled"
                fontSize="large"
                className="mt-2 m-0"
              />
              <p className="p-0 m-0 t-l">{allData.destination}</p>
              {/* <p className="p-0 m-0 t-l">Barakhamba Rd</p> */}
              {/* <p className="p-0 m-0 t-l">{allData.endD}</p> */}
              <button className="butt mt-4 " onClick={handleClick}>
                Book Truck
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default TruckComponent;