import React, { useState } from "react";
import "./TCvehicleinfo.css";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Col } from "react-bootstrap";
import logo from "../../Images/logo.png";
import { Media } from "react-bootstrap";
import axios from "axios";
import FormData from "form-data";
import { useDispatch, useSelector } from "react-redux";
import { totalSpace, totalWeight } from "../../Redux/actions/ownerTruckInfo";
import { localAxiosFormData } from "../../Axios/axios";
import { noOfVehicals } from "../../Redux/actions/transportCompanyInfo";

function TCvehicleinfo() {
  const history = useHistory();
  const [rc, setRc] = useState(null);
  const [lc, setLc] = useState(null);
  const [weight, setWeight] = useState("");
  const [space, setSpace] = useState("");
  const [registerPlateNumber, setRegisterPlateNumber] = useState("");
  const [truckMobileNumber, setTruckMobileNumber] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const dispatch = useDispatch();
  const truckMobileNum = useSelector((state) => state.OwnerMobileNum);
  const truckregNo = useSelector((state) => state.TruckNo);
  const truckSpace = useSelector((state) => state.TotalSpace);
  const SelectedTruck = useSelector((state) => state.SelectedTruck);
  const NoOfVehicals = useSelector((state) => state.NoOfVehicals);
  const OwnerToken = useSelector((state) => state.OwnerToken);
  
  const companyMobileNumberSelector = useSelector(
    (state) => state.ContactNumber
  );

  const companyNameSelector = useSelector((state) => state.CompanyName);
  const formdata = new FormData();

  const wieghtFunction = (value) => {
    setWeight(value);
    dispatch(totalWeight(space));
  };

  const spaceFunction = (value) => {
    setSpace(value);
    dispatch(totalSpace(weight));
  };

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [license, setLicense] = useState({ preview: "", raw: "" });

  const imagePreview = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const imagePreviewlc = (e) => {
    if (e.target.files.length) {
      setLicense({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    formdata.append("truckNo", registerPlateNumber);
    formdata.append("truckModel", vehicleName);
    formdata.append("capacityInKgs", weight);
    formdata.append("capacityInSpace", space);
    formdata.append("rc", rc);
    formdata.append("license", lc);
    formdata.append("companyMobileNum", companyMobileNumberSelector);
    formdata.append("companyName", companyNameSelector);
    formdata.append("mobileNum", truckMobileNumber);
    const isValid = formValidation();
    if (isValid) {
      console.log(vehicleName);
      console.log(truckMobileNum);
      console.log(truckregNo);
      console.log(truckSpace);
      console.log(rc.name);
      console.log(lc.name);
      console.log(rc.type);
      console.log(NoOfVehicals);
      await axios(localAxiosFormData("/truckRegister", formdata, OwnerToken))
        .then((res) => {
          console.log(res);
          if (res.data.statusCode === 200) {
            console.log(`registerdone ${JSON.stringify(res)}`);
            const ind = SelectedTruck - 1;
            NoOfVehicals.splice(ind, 1, [SelectedTruck, true]);
            dispatch(noOfVehicals(NoOfVehicals));
            history.push("/TCRegister");
            console.log(NoOfVehicals);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log(SelectedTruck);
  const [WeightErr, setWeightErr] = useState({});
  const [TruckSpaceErr, setTruckSpaceErr] = useState({});
  const [RegisterPlateNumberErr, setRegisterPlateNumberErr] = useState({});
  const [TruckMobileNumberErr, setTruckMobileNumberErr] = useState({});
  const [VehicleNameErr, setVehicleNameErr] = useState({});

  const formValidation = () => {
    var specialCharacterPattern3 = /[^a-zA-Z ]+/;
    var specialCharacterForMobile = /[^0-9]+/;
    var RegisterPlatePattern1 = /[^A-Z0-9_ ]+/;
    const VehicleNameErr = {};
    const WeightErr = {};
    const TruckSpaceErr = {};
    const RegisterPlateNumberErr = {};
    const TruckMobileNumberErr = {};
    let isValid = true;
    if (vehicleName.length === 0) {
      VehicleNameErr.VehicleNameEmpty = "vehicleName should be required";
      isValid = false;
    } else if (specialCharacterPattern3.test(vehicleName)) {
      VehicleNameErr.VehicleNameSpecial =
        "should not include special characters";
      isValid = false;
    } else {
    }
    if (weight.length === 0) {
      WeightErr.WeightEmpty = "weigth should be required";
      isValid = false;
    } else if (specialCharacterForMobile.test(weight)) {
      WeightErr.WeightChar = "should not include special characters";
      isValid = false;
    } else {
    }
    if (space.length === 0) {
      TruckSpaceErr.TruckSpaceEmpty = "Space should be required";
      isValid = false;
    } else if (specialCharacterForMobile.test(truckSpace)) {
      TruckSpaceErr.TruckSpaceChar = "should not include special characters";
      isValid = false;
    } else {
    }
    if (registerPlateNumber.length === 0) {
      RegisterPlateNumberErr.RegisterNamberEmpty =
        "register Plate Number should be required ";
      isValid = false;
    } else if (RegisterPlatePattern1.test(registerPlateNumber)) {
      RegisterPlateNumberErr.RegisterNamberSpecial =
        "should not include special characters ";
      isValid = false;
    } else {
    }
    if (truckMobileNumber.length === 0) {
      TruckMobileNumberErr.TruckMobileNumberempty =
        "Mobile number should be required";
      isValid = false;
    } else if (specialCharacterForMobile.test(truckMobileNumber)) {
      TruckMobileNumberErr.TruckMobileNumberchar =
        "should not include special characters";
      isValid = false;
    } else if (truckMobileNumber.trim().length > 10) {
      TruckMobileNumberErr.TruckMobileNumberLong =
        "Mobile number should be 10 Digits";
      isValid = false;
    } else if (truckMobileNumber.trim().length < 10) {
      TruckMobileNumberErr.TruckMobileNumberShort =
        "Mobile number should be 10 Digits";
      isValid = false;
    } else {
    }
    setVehicleNameErr(VehicleNameErr);
    setWeightErr(WeightErr);
    setTruckSpaceErr(TruckSpaceErr);
    setRegisterPlateNumberErr(RegisterPlateNumberErr);
    setTruckMobileNumberErr(TruckMobileNumberErr);
    return isValid;
  };

  function onChangetextremovevalidationmessage() {
    setVehicleNameErr("");
    setWeightErr("");
    setTruckSpaceErr("");
    setRegisterPlateNumberErr("");
    setTruckMobileNumberErr("");
  }

  return (
    <div className="tcadddnewtruck">
      <div className="mainDivAddNewTruck">
        <Col>
          <Media className="ml-3 mt-3">
            <img width={200} height={150} alt="logo" src={logo} />
          </Media>
        </Col>
        <div className="divForAddTruck">
          <div className="addTruckTitle">
            <div className="d-flex flex-column addTruckSubDiv">
              <h4>Vehicle Information</h4>
              <h4 className="font-weight-bold truckNum">
                Truck {SelectedTruck}
              </h4>
              <span className="underLineStyle"></span>
            </div>
            <div className="mr-auto">
              <IconButton aria-label="close">
                <CloseIcon style={{ color: "black" }} fontSize="large" />
              </IconButton>
            </div>
          </div>
          <div className="vehicleNameDivInAddTruck">
            <div className="inputDivInAddTruck">
              <label
                alt="Vehicle Name"
                className={vehicleName ? "active" : " "}
                htmlFor="Vehicle Name"
                placeholder="Vehicle Name"
              >
                Vehicle Name
              </label>
              <input
                type="text"
                className="inputDataStyleInTruck"
                onChange={(e) => {
                  setVehicleName(e.target.value);
                  onChangetextremovevalidationmessage();
                }}
              />
              {Object.keys(VehicleNameErr).map((key) => {
                return (
                  <div className="errormessageInAddTruck">
                    {VehicleNameErr[key]}
                  </div>
                );
              })}
            </div>
            <div className="weightAndSpaceDivInAddTruck">
              <div className="inputDivInAddTruck">
                <label
                  alt="Weight Support in Kgs"
                  className={weight ? "active" : " "}
                  htmlFor="wright"
                  placeholder="Weight Support in Kgs"
                >
                  Weight Support in Kgs
                </label>
                <input
                  type="text"
                  className="inputDataStyleInTruck"
                  onChange={(e) => {
                    wieghtFunction(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(WeightErr).map((key) => {
                  return (
                    <div className="errormessageInAdd">{WeightErr[key]}</div>
                  );
                })}
              </div>
              <div className="inputDivInAddTruck">
                <label
                  alt="Space of Truck in ft."
                  className={space ? "active" : " "}
                  htmlFor="space"
                  placeholder="Space of Truck in ft."
                >
                  Space of Truck in ft
                </label>
                <input
                  type="text"
                  className="inputDataStyleInTruck"
                  onChange={(e) => {
                    spaceFunction(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(TruckSpaceErr).map((key) => {
                  return (
                    <div className="errormessageInAdd">
                      {TruckSpaceErr[key]}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="weightAndSpaceDivInAddTruck">
              <div className="inputDivInAddTruck">
                <label
                  alt="Register plate Number"
                  className={registerPlateNumber ? "active" : " "}
                  htmlFor="register Plate Number"
                  placeholder="Register plate Number"
                >
                  Register plate Number
                </label>
                <input
                  type="text"
                  className="inputDataStyleInTruck"
                  onChange={(e) => {
                    setRegisterPlateNumber(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(RegisterPlateNumberErr).map((key) => {
                  return (
                    <div className="errormessageInAdd">
                      {RegisterPlateNumberErr[key]}
                    </div>
                  );
                })}
              </div>
              <div className="inputDivInAddTruck">
                <label
                  alt="Truck Mobile Number"
                  className={truckMobileNumber ? "active" : " "}
                  htmlFor="truck Mobile Number"
                  placeholder="Truck Mobile Number"
                >
                  Truck Mobile Number
                </label>
                <input
                  type="text"
                  className="inputDataStyleInTruck"
                  onChange={(e) => {
                    setTruckMobileNumber(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(TruckMobileNumberErr).map((key) => {
                  return (
                    <div className="errormessageInAdd">
                      {TruckMobileNumberErr[key]}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="filesMainDivInTruck">
            <div className="filesDivInAddTruck">
              <p className="text-left m-0">RC</p>
              <input
                type="file"
                className="inputWidthStyleInTruck"
                name="file"
                onChange={(e) => {
                  setRc(e.target.files[0]);
                  imagePreview(e);
                }}
              />
            </div>
            <div className="filesDivInAdd">
              <p className="text-left m-0">Insurance</p>
              <input
                type="file"
                className="inputWidthStyleInTruck"
                name="file"
                onChange={(e) => {
                  setLc(e.target.files[0]);
                  imagePreviewlc(e);
                }}
              />
            </div>
          </div>
          <div className="filesMainDiv1InTruck">
            <Col className="col-4">
              <div>
                <p>
                  Attach RC
                  {image.preview ? (
                    <img
                      src={image.preview}
                      alt="dummy"
                      width="90"
                      height="90"
                    />
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </Col>
            <Col className="col-4">
              <div>
                <p>
                  Attach license
                  {license.preview ? (
                    <img
                      src={license.preview}
                      alt="dummy"
                      width="90"
                      height="90"
                    />
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </Col>
          </div>
          <div className="filesMainDivInTruck">
            <Col>
              <input
                type="submit"
                name=""
                value="CANCEL"
                className="font-weight-bold buttonForSubmit"
                onClick={() => history.push("/TCRegister")}
              />
            </Col>
            <Col>
              <input
                type="submit"
                name=""
                value="ADD TRUCK"
                className="font-weight-bold buttonForSubmit"
                onClick={onSubmit}
              />
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TCvehicleinfo;