import React from "react";
import { useHistory } from "react-router-dom";
import "./Home.css";
import Arrow from "../../Images/Arrow.png";
import {
  companyName,
  contactNumber,
  noOfVehicals,
} from "../../Redux/actions/transportCompanyInfo";
import {
  customerFirstName,
  customerLastName,
  customerMobileNum,
} from "../../Redux/actions/customerInfo";
import { ownerOtp } from "../../Redux/actions/ownerInfo";
import { useDispatch } from "react-redux";
import packageOwner from "../../Images/image1.png";
import logo1 from "../../Images/logo1.png";
import truck1 from "../../Images/truck1.png";
import trucks from "../../Images/trucks.png";

function Home() {
  const history = useHistory();
  const dispatch = useDispatch();

  const packageOwnerFunction = () => {
    dispatch(customerFirstName(""));
    dispatch(customerLastName(""));
    dispatch(customerMobileNum(""));
    history.push("/PORegister");
  };

  const truckOwnerFunction = () => history.push("/TORegister");

  const transportFunction = () => {
    dispatch(companyName(""));
    dispatch(contactNumber(""));
    dispatch(ownerOtp(""));
    dispatch(noOfVehicals([]));
    history.push("/TCRegister");
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div className="one">
        <div className="oneSubDiv">
          <div className="packageDiv">
            <img
              src={packageOwner}
              style={{ width: "30%", height: "30%" }}
              className="packageImage"
            ></img>
            <h1 className="titleName">Package Owner</h1>
          </div>
          <div className="packagetitleDiv">
            <img
              src={Arrow}
              className="arrowStyles"
              onClick={() => packageOwnerFunction()}
            ></img>
          </div>
          <div className="ultimateDeliveryDiv">
            <img src={logo1} style={{ width: "20%", height: "20%" }}></img>
            <h4 className="ultimateDeliveryStyles">ULTIMATE DELIVERY</h4>
            <h6 className="ultimatedeliverySub">
              ultimate Sharing Delivery Solution
            </h6>
          </div>
        </div>
      </div>
      <div className="two">
        <div className="oneSubDiv">
          <div className="truckDiv">
            <img src={truck1} className="truckImage"></img>
          </div>
          <div className="truckOwnerDiv">
            <h1 className="titleName">Truck Owner</h1>
            <img
              src={Arrow}
              className="arrowStyles"
              onClick={() => truckOwnerFunction()}
            ></img>
          </div>
        </div>
      </div>
      <div className="three">
        <div className="oneSubDiv">
          <div className="transportDiv">
            <h1 className="titleName">Transport Company</h1>
            <img
              src={Arrow}
              className="arrowStyles"
              onClick={() => transportFunction()}
            ></img>
          </div>
          <div className="trucksDiv">
            <img src={trucks} className="trucksImage"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
