import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./TCLogin.css";
import ultimatedeliveryicon from "../../Images/ultimatedeliveryicon.png";
import { useHistory } from "react-router-dom";
import trucks from "../../Images/trucks.png";
import Arrow from "../../Images/Arrow.png";
import {
  companyName,
  contactNumber,
  allTruckData,
} from "../../Redux/actions/transportCompanyInfo";
import { useDispatch } from "react-redux";
import axios from "axios";
import qs from "querystring";
import { localAxios } from "../../Axios/axios";
import { ownerOtp, ownerToken } from "../../Redux/actions/ownerInfo";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";

function TCLogin() {
  const history = useHistory();
  const handleClick1 = () => history.push("/TCRegister");
  const dispatch = useDispatch();
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      const params = qs.stringify({ mobileNum: mobileNumber });
      axios(localAxios("/SMSLogin", params))
        .then((response) => {
          console.log(response.data);
          if (response.data.statusCode === 200) {
            dispatch(ownerOtp(response.data.otp));
            dispatch(companyName(response.data.transportOwner[0].company_name));
            dispatch(contactNumber(mobileNumber))
            dispatch(ownerToken(response.data.token));
            if (response.data.truckDetails) {
              dispatch(allTruckData(response.data.truckDetails));
            } else {
              dispatch(allTruckData([]));
            }
            dispatch(tripHistory([]));
            history.push("/TCotp1");
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const formValidation = () => {
    const mobileNumberErr = {};
    let isValid = true;
    console.log(mobileNumber);
    if (mobileNumber.length === 0) {
      mobileNumberErr.mobileNumberEmpty = "Mobile Number is required";
      isValid = false;
    } else if (isNaN(mobileNumber)) {
      mobileNumberErr.mobileNumber123 = "Mobile Number must have numbers";
      isValid = false;
    } else if (mobileNumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile Number should be 10 digits";
      isValid = false;
    } else if (mobileNumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile Number should be 10 digits";
      isValid = false;
    }  else {
      isValid = true;
    }
    setMobileNumberErr(mobileNumberErr);
    return isValid;
  };

  function onChangetextremovevalidationmessage() {
    setMobileNumberErr("");
  }

  return (
    <form onSubmit={onSubmit}>
      <div>
        <Row className="m-0 p-0 heightc">
          <Col className="col-7" style={{ backgroundColor: "black" }}>
            <img
              src={ultimatedeliveryicon}
              width={80}
              height={100}
              className="float-left mt-5"
            />
            <img src={trucks} className="tr" />
            <p className="text-white partner">
              Transportation Delivery Partner
            </p>
          </Col>
          <Col
            className="transport col-5"
            style={{ backgroundColor: "darkorange" }}
          >
            <Row>
              <p className="bl mt-5 ml-5">
                Want to <span className="font-weight-bold"> Send </span>
              </p>
            </Row>
            <Row>
              <p className="bl ml-5">
                (or) <span className="font-weight-bold"> Recieve </span>
                Package?
              </p>
            </Row>
            <Row>
              <h2 className="text-white mt-5 ml-5 sizel"> Login</h2>
            </Row>
            <Row>
              <p className="bl ml-5">
                Not registered? Please
                <span className="u" onClick={handleClick1}> <u>Register</u></span>
              </p>
            </Row>
            <Row>
              <label className="mt-5 text-white ml-5">Mobile Number</label>
            </Row>
            <Row>
              <input
                className="ml-5"
                type="text"
                placeholder="Mobile Number"
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                  onChangetextremovevalidationmessage();
                }}
              />
              {Object.keys(mobileNumberErr).map((key) => {
                return (
                  <div style={{ color: "red", paddingLeft: "50px" }}>
                    {mobileNumberErr[key]}
                  </div>
                );
              })}
            </Row>
            <img src={Arrow} onClick={onSubmit} className="ArrowTCL" />
          </Col>
        </Row>
      </div>
    </form>
  );
}

export default TCLogin;