import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./TCPLogin.css";
import ultimatedeliveryicon from "../../../Images/ultimatedeliveryicon.png";
import { useHistory } from "react-router-dom";
import trucks from "../../../Images/trucks.png";
import Arrow from "../../../Images/Arrow.png";

function TCPLogin() {
  const history = useHistory();
  const handleClick1 = () => history.push("/TCPRegister");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberErr, setMobileNumberErr] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      setMobileNumber("");
      history.push("/TCotp1");
    }
  };

  const formValidation = () => {
    const mobileNumberErr = {};
    let isValid = true;
    if (mobileNumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile number is too long";
      isValid = false;
    } else if (mobileNumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile number is too short";
      isValid = false;
    } else if (isNaN(mobileNumber)) {
      mobileNumberErr.mobileNumber123 = "Mobile Number must have numbers";
      isValid = false;
    } else {
      isValid = true;
    }
    setMobileNumberErr(mobileNumberErr);
    return isValid;
  };

  return (
    <form onSubmit={onSubmit}>
      <div>
        <Row className="m-0 p-0 heightcp">
          <Col className="col-7" style={{ backgroundColor: "black" }}>
            <img
              src={ultimatedeliveryicon}
              width={80}
              height={100}
              className="float-left mt-5"
            />
            <img src={trucks} className="tr" />
            <p className="text-white partner">
              Transportation Delivery Partner
            </p>
          </Col>
          <Col
            className="transport col-5"
            style={{ backgroundColor: "darkorange" }}
          >
            <Row>
              <p className="bl mt-5 ml-5">
                Want to <span className="font-weight-bold"> Send </span>
              </p>
            </Row>
            <Row>
              <p className="bl ml-5">
                (or) <span className="font-weight-bold"> Recieve </span>
                Package?
              </p>
            </Row>
            <Row>
              <h2 className="text-white mt-5 ml-5 sizel"> Login</h2>
            </Row>
            <Row>
              <p className="bl ml-5">
                Not registered? Please
                <span className="u" onClick={handleClick1}> <u>Register</u></span>
              </p>
            </Row>
            <Row>
              <label className="mt-5 text-white ml-5">Mobile Number</label>
            </Row>
            <Row>
              <input
                className="ml-5"
                type="text"
                placeholder="Mobile Number"
                value={mobileNumber}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                }}
              />
              {Object.keys(mobileNumberErr).map((key) => {
                return (
                  <div style={{ color: "white", paddingLeft: "120px" }}>
                    {mobileNumberErr[key]}
                  </div>
                );
              })}
            </Row>
            <img src={Arrow} onClick={onSubmit} className="ArrowTCL" />
          </Col>
        </Row>
      </div>
    </form>
  );
}

export default TCPLogin;