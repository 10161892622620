import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./TORegister.css";
import { Row, Col } from "react-bootstrap";
import blackarrow from "../../Images/blackarrow.png";
import axios from "axios";
import qs from "querystring";
import FormData from "form-data";
import { useDispatch } from "react-redux";
import {
  ownerFullName,
  ownerToken,
  ownerOtp,
  ownerRegisterMobileNum,
} from "../../Redux/actions/ownerInfo";
import {
  truckM0del,
  truckN0,
  totalSpace,
  totalWeight,
} from "../../Redux/actions/ownerTruckInfo";
import { truckRegisterFailed } from "../../Redux/actions/other";
import { localAxios, localAxiosFormData } from "../../Axios/axios";

function TORegister() {
  const history = useHistory();
  let login;
  const toLoginFunction = () => history.push("/TOLogin");
  const [weight, setWeight] = useState(0);
  const [space, setSpace] = useState(0);
  const [PackageWeightErr, setPackageWeightErr] = useState({});
  const [PackageSpaceErr, setPackageSpaceErr] = useState({});
  const dispatch = useDispatch();

  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [registerplateNumber, setRegisterplateNumber] = useState("");

  const [errorTORegister, setErrorTORegister] = useState("");
  const [errorTOVehicleRegister, setErrorTOVehicleRegister] = useState("");

  const [rc, setRc] = useState(null);
  const [lc, setLc] = useState(null);

  const truckOwnerData = qs.stringify({
    fullName: fullName,
    mobileNum: mobileNumber,
  });

  const formdata = new FormData();

  const [FullNameErr, setFullNameErr] = useState({});
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  const [VehicleNameErr, setVehicleNameErr] = useState({});
  const [RegisterPlateNumberErr, setRegisterPlateNumberErr] = useState({});
  const [RCErr, setRCErr] = useState({});
  const [LCErr, setLCErr] = useState({});

  const onSubmit = async () => {
    formdata.append("truckNo", registerplateNumber);
    formdata.append("truckModel", vehicleName);
    formdata.append("capacityInKgs", weight);
    formdata.append("capacityInSpace", space);
    formdata.append("rc", rc);
    formdata.append("license", lc);
    formdata.append("mobileNum", mobileNumber);
    dispatch(ownerFullName(fullName));
    dispatch(ownerRegisterMobileNum(mobileNumber));
    dispatch(truckM0del(vehicleName));
    dispatch(truckN0(registerplateNumber));
    dispatch(totalWeight(weight));
    dispatch(totalSpace(space));
    const isValid = formValidation();
    if (isValid) {
      try {
        await axios(localAxios("/truckOwnerRegister", truckOwnerData))
          .then(async (resp) => {
            console.log(
              resp.data.statusCode != 201,
              truckOwnerData,
              resp.data.statusCode,
              resp.data.statusCode !== 201
            );
            if (resp.data.statusCode != 201) {
              console.log(`login faild data here ${JSON.stringify(resp.data)}`);
              login = false;
              setErrorTORegister(
                "You are already registered with this mobile Number"
              );
              return 0;
            }
            console.log(`register data here ${JSON.stringify(resp.data)}`);
            console.log(resp.data.token);
            dispatch(ownerOtp(resp.data.otp));
            dispatch(ownerToken(resp.data.token));
            const { token } = resp.data;
            console.log(token);
            login = true;
            console.log(login);
            await axios(localAxiosFormData("/truckRegister", formdata, token))
              .then((res) => {
                console.log(res);
                if (res.data.statusCode !== 200) {
                  dispatch(truckRegisterFailed("Failed"));
                  console.log(`register failed ${JSON.stringify(res)}`);
                  setErrorTOVehicleRegister(
                    "Register plate number is already existed"
                  );
                } else {
                  history.push("/TOotp");
                  console.log(weight);
                  console.log(JSON.stringify(res.data));
                }
              })
              .catch((err) => {
                console.log(err);
                dispatch(truckRegisterFailed("Failed"));
              });
          })
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const formValidation = () => {
    var specialCharacterPattern = /[^a-zA-Z_ ]+/;
    const FullNameErr = {};
    const mobileNumberErr = {};
    var specialCharacterPattern1 = /[^a-zA-Z_ ]+/;
    const VehicleNameErr = {};
    var RegisterPlatePattern1 = /[^A-Za-z0-9_ ]+/;
    const RegisterPlateNumberErr = {};
    const RCErr = {};
    const LCErr = {};
    let validfullname = true;
    let validmobilenumber = true;
    let validvehiclename = true;
    let validregisterplateno = true;
    let validPackageWeight = true;
    let validPackageSpace = true;
    let validLC = true;
    let validRC = true;
    let isValid = false;
    if (fullName.length === 0) {
      FullNameErr.FullNameEmpty = "Full Name is required";
      validfullname = false;
    } else if (specialCharacterPattern.test(fullName)) {
      FullNameErr.FullNameSpecial =
        "Should not include special characters and numbers";
      validfullname = false;
    } else {
      validfullname = true;
    }
    if (mobileNumber.length === 0) {
      mobileNumberErr.mobileNumberEmpty = "Mobile Number is required";
      validmobilenumber = false;
    } else if (isNaN(mobileNumber)) {
      mobileNumberErr.mobileNumber123 = "Mobile Number must have numbers";
      validmobilenumber = false;
    } else if (mobileNumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile Number should be ten digits";
      validmobilenumber = false;
    } else if (mobileNumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile Number should be ten digits";
      validmobilenumber = false;
    } else {
      validmobilenumber = true;
    }
    if (vehicleName.length === 0) {
      VehicleNameErr.VehicleNameEmpty = "Vehicle Name is required";
      validvehiclename = false;
    } else if (specialCharacterPattern1.test(vehicleName)) {
      VehicleNameErr.VehicleNameSpecial =
        "Should not include special characters and numbers";
      validvehiclename = false;
    } else {
      validvehiclename = true;
    }
    if (registerplateNumber.length === 0) {
      RegisterPlateNumberErr.RegisterplateNumberEmpty =
        "Register Plate Number is required";
      validregisterplateno = false;
    } else if (RegisterPlatePattern1.test(registerplateNumber)) {
      RegisterPlateNumberErr.RegisterplateNumberSpecial =
        "Should not include special characters";
      validregisterplateno = false;
    } else {
      validregisterplateno = true;
    }
    if (weight > 1) {
      validPackageWeight = true;
    } else {
      PackageWeightErr.PackageWeight123 = "Package Weight must have some value";
      validPackageWeight = false;
    }
    if (space > 1) {
      validPackageSpace = true;
    } else {
      PackageSpaceErr.PackageSpace123 = "Package Space must have some value";
      validPackageSpace = false;
    }
    if (rc === null) {
      RCErr.rcErr = "Please upload your RC";
      validRC = false;
    }
    if (lc === null) {
      LCErr.lcErr = "Please upload your LC";
      validLC = false;
    }
    setFullNameErr(FullNameErr);
    setMobileNumberErr(mobileNumberErr);
    setVehicleNameErr(VehicleNameErr);
    setRegisterPlateNumberErr(RegisterPlateNumberErr);
    setPackageWeightErr(PackageWeightErr);
    setPackageSpaceErr(PackageSpaceErr);
    setRCErr(RCErr);
    setLCErr(LCErr);
    if (
      validfullname &&
      validmobilenumber &&
      validvehiclename &&
      validregisterplateno &&
      validPackageWeight &&
      validPackageSpace &&
      validRC &&
      validLC
    ) {
      isValid = true;
    }
    return isValid;
  };

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [license, setLicense] = useState({ preview: "", raw: "" });

  const imagePreview = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const imagePreviewlc = (e) => {
    if (e.target.files.length) {
      setLicense({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  function onChangetextremovevalidationmessage() {
    setFullNameErr("");
    setMobileNumberErr("");
    setErrorTORegister("");
    setVehicleNameErr("");
    setRegisterPlateNumberErr("");
    setErrorTOVehicleRegister("");
    setPackageWeightErr("");
    setPackageSpaceErr("");
  }

  function clearDispatchValues() {
    dispatch(ownerToken([]));
  }

  return (
    <div className="truckOwnerRegister">
      {clearDispatchValues()}
      <div className="truckRegisterblock">
        <div className="truckRegisterTitleBlock">
          <pre className="truckRegisterTitle">
            {`Want to be an `}
            <b>{`Individual
Delivery Partner?`}</b>
          </pre>
        </div>
        <div className="registerDetailsInTruck">
          <h3>Register</h3>
          <p>
            Already registered? Please
            <span className="login" onClick={toLoginFunction}> <u>Login</u></span>
          </p>
          <div className="inputsInRegister">
            <input
              type="text"
              placeholder=" "
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
                onChangetextremovevalidationmessage();
              }}
            />
            <label className="mt-1 floating">Full Name</label>
            {Object.keys(FullNameErr).map((key) => {
              return (
                <div className="colorerror text-left">{FullNameErr[key]}</div>
              );
            })}
          </div>
          <div className="inputsInRegister mt-2">
            <input
              type="text"
              placeholder=" "
              onChange={(e) => {
                setMobileNumber(e.target.value);
                onChangetextremovevalidationmessage();
              }}
            />
            <label className="mt-1 floating">Mobile Number</label>
            {Object.keys(mobileNumberErr).map((key) => {
              return (
                <div className="colorerror text-left">
                  {mobileNumberErr[key]}
                </div>
              );
            })}
            {errorTORegister && (
              <h5 className="errorTORegister"> {errorTORegister} </h5>
            )}
          </div>
        </div>
        <div className="dottedline"></div>
        <div className="vehicleInformation">
          <h4>Vehicle Information</h4>
          <Row>
            <Col className="col-4 mt-2">
              <div className="inputsInTruckRegister">
                <input
                  className="mr-1"
                  type="text"
                  placeholder=" "
                  onChange={(e) => {
                    setVehicleName(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                <label className="floatingVehicle">Vehicle Name</label>
                {Object.keys(VehicleNameErr).map((key) => {
                  return (
                    <div className="errorvehicle text-left">
                      {VehicleNameErr[key]}
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col className="col-4 mt-2">
              <div className="inputsInTruckRegister">
                <input
                  type="text"
                  placeholder=" "
                  onChange={(e) => {
                    setRegisterplateNumber(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                <label className="floatingVehicle">Register plate Number</label>
                {Object.keys(RegisterPlateNumberErr).map((key) => {
                  return (
                    <div className="errorvehicle text-left">
                      {RegisterPlateNumberErr[key]}
                    </div>
                  );
                })}
                {errorTOVehicleRegister && (
                  <h5 className="errorTOVehicleRegister">
                    {errorTOVehicleRegister}
                  </h5>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-4 mt-2">
              <div className="inputsInTruckRegister">
                <input
                  type="number"
                  min="1"
                  placeholder=" "
                  onChange={(e) => {
                    setWeight(e.target.value);
                    console.log(weight);
                    onChangetextremovevalidationmessage();
                  }}
                />
                <label className="floatingVehicle">
                  Weight Support in Kgs.
                </label>
                {Object.keys(PackageWeightErr).map((key) => {
                  return (
                    <div className="errorvehicle text-left">
                      {PackageWeightErr[key]}
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col className="col-4 mt-2">
              <div className="inputsInTruckRegister">
                <input
                  className=""
                  type="number"
                  min="1"
                  placeholder=" "
                  onChange={(e) => {
                    setSpace(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                <label className="floatingVehicle">Space of Truck in ft.</label>
                {Object.keys(PackageSpaceErr).map((key) => {
                  return (
                    <div className="errorvehicle text-left">
                      {PackageSpaceErr[key]}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <div className="RC">
                <p>
                  Attach RC
                  {image.preview ? (
                    <img
                      src={image.preview}
                      alt="dummy"
                      width="90"
                      height="90"
                    />
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </Col>
            <Col className="col-4">
              <div className="License">
                <p>
                  Attach license
                  {license.preview ? (
                    <img
                      src={license.preview}
                      alt="dummy"
                      width="90"
                      height="90"
                    />
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <div className="rc">
                <input
                  type="file"
                  name="file"
                  onChange={(e) => {
                    setRc(e.target.files[0]);
                    imagePreview(e);
                  }}
                />
                {Object.keys(RCErr).map((key) => {
                  return (
                    <div className="errorvehicle text-left">
                      {RCErr[key]}
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col className="col-4">
              <div className="license">
                <input
                  type="file"
                  name="picture"
                  onChange={(e) => {
                    setLc(e.target.files[0]);
                    imagePreviewlc(e);
                  }}
                />
                {Object.keys(LCErr).map((key) => {
                  return (
                    <div className="errorvehicle text-left">
                      {LCErr[key]}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
        <div className="arrowTR">
          <img
            src={blackarrow}
            alt=" "
            onClick={onSubmit}
            className="arrowTRstyle"
          />
        </div>
      </div>
    </div>
  );
}

export default TORegister;