import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <div>
      <NavLink to="/"> </NavLink>
      <NavLink to="/home"> </NavLink>
      <NavLink to="/PORegister"> </NavLink>
      <NavLink to="/otp"> </NavLink>
      <NavLink to="/Login"> </NavLink>
      <NavLink to="/otp1"> </NavLink>
      <NavLink to="/PackageOwnerAddPackage"> </NavLink>
      <NavLink to="/Details"> </NavLink>
      <NavLink to="/availabletrucks"> </NavLink>
      <NavLink to="/deliverybooked1"> </NavLink>
      <NavLink to="/deliverybooked2"> </NavLink>
      <NavLink to="/deliverybooked3"> </NavLink>
      <NavLink to="/Trackdelivery"> </NavLink>
      <NavLink to="/pkgstatus"> </NavLink>
      <NavLink to="/map"> </NavLink>
      <NavLink to="/podelivery"> </NavLink>
      <NavLink to="/deliverybooked"> </NavLink>
    </div>
  );
}

export default Navigation;
