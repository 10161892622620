import React from "react";
import { Col, Row } from "react-bootstrap";
import TOHeader from "../ToHeader/TOHeader";
import "./TODeliverychecklist.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import orangecall from "../../Images/orangecall.png";
import { useHistory } from "react-router-dom";
import { Media } from "react-bootstrap";
import { useSelector } from "react-redux";

function TODeliverychecklist() {
  const history = useHistory();
  const handleClick = () => history.push("/Tripdetails");

  const DeliveryRequestsSelector = useSelector(
    (state) => state.DeliveryRequests
  );
  
  console.log(DeliveryRequestsSelector[0]);

  let done = 0;

  DeliveryRequestsSelector[0].map((data) => {
    if (data[0].delivered === true) {
      done++;
    }
  });

  return (
    <div className="toDeliveryChecklist">
      <TOHeader />
      <Row>
        <div className="deliverychecklistWidth d-flex flex-column ml-2">
          <div className="d-flex flex-row">
            <ArrowBackIcon
              style={{ color: "#black", fontSize: "30px" }}
              className="float-left mt-3 mr-1 ml-2"
              onClick={handleClick}
            />
            <h4 className="text-left checklist mt-3"> Delivery Check List </h4>
          </div>
        </div>
        <div className="checklistWidth todeliverychecklist d-flex mt-4 flex-column">
          <Row className="mt-3">
            <Col className="col-4">
              <FiberManualRecordIcon
                style={{ color: "red", fontSize: "15px" }}
                className=" mr-1 "
              />
              <span className="font-weight-bold">Need to Deliver</span>
            </Col>
            <Col className="col-4">
              <FiberManualRecordIcon
                style={{ color: "green", fontSize: "15px" }}
                className=" mr-1 "
              />
              <span className="font-weight-bold">Delivered</span>
            </Col>
            <Col className="col-4">
              <span className="font-weight-bold">
                Drop Done - <span style={{ color: "green" }}>{done}</span>
              </span>
            </Col>
          </Row>
          <div className="m-5">
            <Row className="mt-3">
              <Col className="col-4">
                <p className="font-weight-bold">Names</p>
              </Col>
              <Col className="col-4">
                <p className="font-weight-bold">Location</p>
              </Col>
              <Col className="col-4">
                <p className="font-weight-bold">Status</p>
              </Col>
            </Row>
            {DeliveryRequestsSelector[0].length ? (
              DeliveryRequestsSelector[0].map((object, index) => (
                <div key={index}>
                  <Row className="mt-3 checklistborder">
                    <Col className="col-3">
                      <Media>
                        <img src={orangecall} className="mr-2" />
                        <Media.Body>
                          <p>{object[1].package_receiving_person}</p>
                        </Media.Body>
                      </Media>
                    </Col>
                    <Col className="col-5">
                      <p className="m-0">{object[1].pickup_point}</p>
                      <p className="m-0">{object[1].drop_point}</p>
                    </Col>
                    <Col className="col-4">
                      {object[0].delivered == true ? (
                        <FiberManualRecordIcon
                          style={{ color: "green", fontSize: "15px" }}
                        />
                      ) : (
                        <FiberManualRecordIcon
                          style={{ color: "red", fontSize: "15px" }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <div>
                <p>No packages in the trip.</p>
              </div>
            )}
          </div>
        </div>
        <div className="totalWidth d-flex flex-column">
          <h4 style={{ color: "black" }} className="mt-3">
            Total - {DeliveryRequestsSelector[0].length}
          </h4>
        </div>
      </Row>
    </div>
  );
}

export default TODeliverychecklist;