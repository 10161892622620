import React, { useState } from "react";
import logoWhite from "../../Images/logo_white.png";
import "./Header.css";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { POsidebar } from "./POsidebar";
import { IconContext } from "react-icons";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

function Header() {
  const [sidebar, setSidebar] = useState(false);
  const customerFullName = useSelector(
    (state) => `${state.CustomerFirstName} ${state.CustomerLastName}`
  );
  const showSidebar = () => setSidebar(!sidebar);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 300px)",
  });

  return (
    <div className="containerBody">
      <div className="logoStyle">
        <img src={logoWhite} className="logoImageInPackage"></img>
        <div className="preTextDiv">
          <pre className="preText">
            {`
ULTIMATE
DELIVERY
                        `}
          </pre>
        </div>
      </div>
      <div className="profileStyle">
        <IconContext.Provider value={{ color: "red" }} className="iconText">
          <span className="welcomeText">
            Welcome,
            <span className="profileName">{`${" "}${customerFullName}`} !</span>
          </span>
          <IconButton
            aria-label="account"
            onClick={showSidebar}
            className="ml-2"
          >
            {isMobile ? (
              <AccountCircleRoundedIcon color="disabled" fontSize="small" />
            ) : (
              <AccountCircleRoundedIcon color="disabled" fontSize="large" />
            )}
          </IconButton>
        </IconContext.Provider>
      </div>
      <div className={sidebar ? "sideNav" : " sideNav1"}>
        <nav className="navLinksInPackageHeader">
          <ul className="navMenuItems p-0" onClick={showSidebar}>
            {POsidebar.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span className="title1">{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;