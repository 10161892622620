export const truckN0 = (data) => ({
  type: "TruckNo",
  data,
});

export const truckM0del = (data) => ({
  type: "TruckModel",
  data,
});

export const totalWeight = (data) => ({
  type: "TotalWeight",
  data,
});

export const totalSpace = (data) => ({
  type: "TotalSpace",
  data,
});

export const tripHistory = (data) => ({
  type: "TripHistory",
  data,
});

export const truckDetails = (data) => ({
  type: "TruckDetailsData",
  data,
});

export const availableWeightData = (data) => ({
  type: "WeightData",
  data,
});

export const availableSpaceData = (data) => ({
  type: "SpaceData",
  data,
});