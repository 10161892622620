export const CustomerFirstName = (state = "", action) => {
  switch (action.type) {
    case "FirstName":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export const CustomerLastName = (state = "", action) => {
  switch (action.type) {
    case "LastName":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export const CustomerMobileNum = (state = "", action) => {
  switch (action.type) {
    case "MobileNum":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export const CustomerOtp = (state = "", action) => {
  switch (action.type) {
    case "OTP":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export const CustomerToken = (state = "", action) => {
  switch (action.type) {
    case "Token":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export const CustomerPackages = (state = "", action) => {
  switch (action.type) {
    case "CustomerPackages":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export const ReceivingPackages = (state = "", action) => {
  switch (action.type) {
    case "ReceivingPackages":
      state = action.data;
      return state;
    default:
      return state;
  }
};

export const CitiesList = (state = [], action) => {
  switch (action.type) {
    case "SET_CITIES_LIST":
      return action.payload; 
    default:
      return state;
  }
};