import React from "react";
import { useHistory } from "react-router-dom";
import "./image.css";
import Arrow from "../../Images/Arrow.png";
import logo1 from "../../Images/logo1.png";

function Image() {
  const history = useHistory();
  console.log("HIIIISSSSTOOOORY", history);
  const handleClick = () => history.push("/home");

  return (
    <div className="bodyDiv">
      <img src={logo1} className="logoImage"></img>
      <h1 className="titleText">ULTINMATE DELIVERY</h1>
      <h2 className="subTitleText">Ultimate Sharing Delivery Solution</h2>
      <img src={Arrow} className="arrowImage" onClick={handleClick}></img>
    </div>
  );
}

export default Image;
