import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { useHistory } from "react-router-dom";
import "./Otp1.css";
import Arrow from "../../Images/Arrow.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import qs from "querystring";
import { localAxios } from "../../Axios/axios";
import { customerOtp } from "../../Redux/actions/customerInfo";
import { localAxiosToken } from "../../Axios/axios";
import {
  customerPackages,
  receivingPackages,
} from "../../Redux/actions/customerInfo";
import { trackingTruckData } from "../../Redux/actions/transportCompanyInfo";

function Otp1() {
  const history = useHistory();
  const [OTP, setOTP] = useState(" ");
  const [errorOTP, setErrorOTP] = useState("");
  const customerOtpSelector = useSelector((state) => state.CustomerOtp);
  const customerMobileNumSelector = useSelector(
    (state) => state.CustomerMobileNum
  );
  const customerTokenSelector = useSelector((state) => state.CustomerToken);
  let Login;
  const dispatch = useDispatch();

  const handleClick = () => {
    axios(
      localAxiosToken(
        "/getCustomerPackages",
        qs.stringify({ mobileNum: customerMobileNumSelector }),
        customerTokenSelector
      )
    )
      .then((res) => {
        if (res.data.statusCode === 200)
          dispatch(customerPackages(res.data.packageDetails.reverse()));
        dispatch(trackingTruckData(res.data.packageDetails));
        console.log(res.data);
      })
      .catch((err) => console.log(err));
    try {
      axios(
        localAxiosToken(
          "/getReceivingPackages",
          qs.stringify({ mobileNum: customerMobileNumSelector }),
          customerTokenSelector
        )
      )
        .then((res) => {
          if (res.data.statusCode === 200)
            dispatch(receivingPackages(res.data.packageDetails.reverse()));
          dispatch(trackingTruckData(res.data.packageDetails[0]));

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
    if (customerOtpSelector === OTP) {
      Login = true;
      console.log(Login);
      history.push("/PackageOwnerAddPackage");
    } else {
      Login = false;
      console.log(Login);
      setErrorOTP("Invalid otp");
    }
  };

  const ResendCode = async () => {
    setOTP("");
    setErrorOTP("");
    await axios(
      localAxios(
        "/SMSLogin",
        qs.stringify({
          mobileNum: customerMobileNumSelector,
        })
      )
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode === 200 && res.data.customerDetails) {
          dispatch(customerOtp(res.data.otp));
          history.push("./Otp1");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form>
      <div className="packageOwnerOtp1">
        <div className="packageOtp1">
          <pre>
            {`Enter the 4-Digit Mobile Verification Code 
 You shall recieve the code to your Number`}
          </pre>
          <h1>Verification Code</h1>
          <div className="otpboxPO1">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          {errorOTP && <h5 className="errorPO1"> {errorOTP} </h5>}
          <p>Hint:Your OTP is {customerOtpSelector}</p>
          <h6 onClick={ResendCode}>Resend Verification Code</h6>
          <div className="arrowPO1">
            <img
              src={Arrow}
              alt=" "
              onClick={handleClick}
              className="arrowPO1style"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default Otp1;