import React, { useState } from "react";
import TOHeader from "../ToHeader/TOHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./AddDelivery.css";
import { Col, Row } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import box3 from "../../Images/box-3.png";
import qs from "querystring";
import { localAxiosToken } from "../../Axios/axios";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TOPackageComponent from "../ToPackageComponent/TOPackageComponent";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";
import { truckDetails } from "../../Redux/actions/ownerTruckInfo";
import { deliveryRequests } from "../../Redux/actions/tripsAvailable";

function Adddelivery() {
  const dispatch = useDispatch();
    const indian_cities = useSelector((state) => state.CitiesList);


  function fetchData(TruckNo) {
    const data = qs.stringify({ truckNo: TruckNo });
    axios(localAxiosToken("/getTripByTruckNo", data, OwnerToken))
      .then((response) => {
        console.log(response.data);
        const tripDetails = response.data.message.tripDetails
          ? response.data.message.tripDetails
          : 0;
        console.log(`${JSON.stringify(tripDetails)}`);
        console.log(tripDetails.length);
        console.log(response.data.message.truckDetails[0]);
        const truck = response.data.message.truckDetails[0];
        console.log(truck);
        dispatch(tripHistory(tripDetails.reverse()));
        dispatch(truckDetails(response.data.message.truckDetails[0]));
        console.log(response.data.message.tripDetails[0].truck_no);
      })
      .catch((err) => console.log(err));
  }

  function tripPackages(tripId) {
    const params = qs.stringify({ tripId });
    axios(localAxiosToken("/getTripPackages", params, OwnerToken))
      .then((res) => {
        console.log(params);
        console.log(res.data);
        dispatch(deliveryRequests(res.data.packageDetails));
        console.log(res.data.packageDetails);
      })
      .catch((err) => console.log(err));
  }

  const history = useHistory();
  const handleClick = () => history.push("/Tripdetails");
  const [success, setSuccess] = useState("");
  const [message, setMessage] = useState("");
  const OwnerToken = useSelector((state) => state.OwnerToken);
  const [dropCustomerName, setDropCustomerName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [packageWeight, setPackageWeight] = useState(0);
  const [packageSpace, setPackageSpace] = useState(0);
  const [pickupCustomerName, setPickupCustomerName] = useState("");
  const [receivingMobileNumber, setreceivingMobileNumber] = useState("");
  const [pickUpPoint, setPickUpPoint] = useState("");
  const [dropPoint, setDropPoint] = useState("");
  const [PackageWeightErr, setPackageWeightErr] = useState("");
  const [PackageSpaceErr, setPackageSpaceErr] = useState("");
  const [DropCustomerNameErr, setDropCustomerNameErr] = useState({});
  const [PickupCustomerNameErr, setPickupCustomerNameErr] = useState({});
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  const [ReceivingMobileNumberErr, setreceivingMobileNumberErr] = useState({});
  const TripDetails = useSelector((state) => state.TripDetails);
  console.log(TripDetails);
  const DeliveryRequestsSelector = useSelector(
    (state) => state.DeliveryRequests
  );
  const availableWeightSelector = useSelector(
    (state) => state.availableWeightReducer
  );
  const availableSpaceSelector = useSelector(
    (state) => state.availableSpaceReducer
  );
  console.log(
    availableSpaceSelector,
    availableWeightSelector,
    "availableWeightSelectoravailableWeightSelector"
  );

  const params = `${qs.stringify({
    pickUpPoint: pickUpPoint,
    dropPoint: dropPoint,
    receivingPersonName: pickupCustomerName,
    receivingPersonNo: receivingMobileNumber,
    packageSpace: packageSpace,
    packageWeight: packageWeight,
    customerName: dropCustomerName,
    mobileNum: mobileNumber,
    entireTruck: false,
    tripId: TripDetails.tripId,
  })}&date=${TripDetails.startDate}`;

  const params2 = (packageId) =>
    `${qs.stringify({
      truckNo: TripDetails.truckNo,
      tripId: TripDetails.tripId,
      packageId,
      status: "Accepted",
    })}&date=${TripDetails.startDate}`;

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("Add delivery", packageWeight);
    console.log(TripDetails.tripId, "TripDetails.tripId", packageSpace);
    const isValid = formValidation();
    if (isValid) {
      axios(localAxiosToken("/addPackage", params, OwnerToken))
        .then((res) => {
          console.log(params);
          console.log(res);
          if (res.data.statusCode === 400) setMessage(res.data.message);
          axios(
            localAxiosToken(
              "/assignPackage",
              params2(res.data.details[0].package_id),
              OwnerToken
            )
          )
            .then((response) => {
              console.log(response.data);
              setSuccess("Yes");
              fetchData(TripDetails.truckNo);
              tripPackages(TripDetails.tripId, null);
              setMobileNumber("");
              setDropCustomerName("");
              setPackageSpace("");
              setPackageWeight("");
              history.push("/AddDelivery");
            })
            .catch((error) => {
              console.log(error);
              setSuccess("No");
            });
        })
        .catch((err) => {
          console.log(err);
          setSuccess("No");
        });
    }
  };

  const formValidation = () => {
    var specialCharacterPattern = /[^a-zA-Z]+/;
    const DropCustomerNameErr = {};
    const PickupCustomerNameErr = {};
    const mobileNumberErr = {};
    const PackageWeightErr = {};
    const PackageSpaceErr = {};
    const ReceivingMobileNumberErr = {};
    let validDropCustomerName = true;
    let validmobileNumber = true;
    let validPackageWeight = true;
    let validPackageSpace = true;
    let validpickupCustomerName = true;
    let validreceivingmobileNumber = true;
    let isValid = false;
    console.log(packageWeight);
    if (packageWeight <= 0) {
      PackageWeightErr.PackageWeight123 = "Package Weight must have some value";
      validPackageWeight = false;
    } else if (packageWeight > availableWeightSelector) {
      PackageWeightErr.PackageWeight123 =
        "truck has available weight " + availableWeightSelector;
      validPackageWeight = false;
    } else {
      validPackageWeight = true;
    }
    if (packageSpace <= 0) {
      PackageSpaceErr.PackageSpace123 = "Package Space must have some value";
      validPackageSpace = false;
    } else if (packageSpace > availableSpaceSelector) {
      PackageSpaceErr.PackageSpace123 =
        "truck has available space " + availableSpaceSelector;
      validPackageSpace = false;
    } else {
      validPackageSpace = true;
    }
    if (dropCustomerName.length === 0) {
      DropCustomerNameErr.DropCustomerNameEmpty =
        "Drop Customer Name is required";
      validDropCustomerName = false;
    } else if (specialCharacterPattern.test(dropCustomerName)) {
      DropCustomerNameErr.DropCustomerNameSpecial =
        "should not include special characters and numbers";
      validDropCustomerName = false;
    } else {
      validDropCustomerName = true;
    }
    if (mobileNumber.length === 0) {
      mobileNumberErr.mobileNumberEmpty = "Mobile Number is required";
      validmobileNumber = false;
    } else if (isNaN(mobileNumber)) {
      mobileNumberErr.mobileNumber123 = "Mobile Number must have numbers";
      validmobileNumber = false;
    } else if (mobileNumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile Number should be ten digits";
      validmobileNumber = false;
    } else if (mobileNumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile Number should be ten digits";
      validmobileNumber = false;
    } else {
      validmobileNumber = true;
    }
    if (pickupCustomerName.length === 0) {
      PickupCustomerNameErr.PickupCustomerNameEmpty =
        "Pickup Customer Name is required";
      validpickupCustomerName = false;
    } else if (specialCharacterPattern.test(pickupCustomerName)) {
      PickupCustomerNameErr.PickupCustomerNameSpecial =
        "should not include special characters and numbers";
      validpickupCustomerName = false;
    } else {
      validpickupCustomerName = true;
    }
    if (receivingMobileNumber.length === 0) {
      ReceivingMobileNumberErr.ReceivingMobileNumberEmpty =
        "Mobile Number is required";
      validreceivingmobileNumber = false;
    } else if (isNaN(receivingMobileNumber)) {
      ReceivingMobileNumberErr.ReceivingMobileNumber123 =
        "Mobile Number must have numbers";
      validreceivingmobileNumber = false;
    } else if (receivingMobileNumber.trim().length > 10) {
      ReceivingMobileNumberErr.ReceivingMobileNumberLong =
        "Mobile Number should be ten digits";
      validreceivingmobileNumber = false;
    } else if (receivingMobileNumber.trim().length < 10) {
      ReceivingMobileNumberErr.ReceivingMobileNumberShort =
        "Mobile Number should be ten digits";
      validreceivingmobileNumber = false;
    } else {
      validreceivingmobileNumber = true;
    }
    setPackageWeightErr(PackageWeightErr);
    setPackageSpaceErr(PackageSpaceErr);
    setDropCustomerNameErr(DropCustomerNameErr);
    setMobileNumberErr(mobileNumberErr);
    setPickupCustomerNameErr(PickupCustomerNameErr);
    setreceivingMobileNumberErr(ReceivingMobileNumberErr);
    if (
      validPackageWeight &&
      validPackageSpace &&
      validDropCustomerName &&
      validmobileNumber &&
      validpickupCustomerName &&
      validreceivingmobileNumber
    ) {
      console.log(
        validPackageWeight,
        validPackageSpace,
        validDropCustomerName,
        validmobileNumber,
        validpickupCustomerName,
        validreceivingmobileNumber,
        "validations"
      );
      isValid = true;
    }
    return isValid;
  };

  function onChangetextremovevalidationmessage() {
    setDropCustomerNameErr("");
    setMobileNumberErr("");
    setPickupCustomerNameErr("");
    setreceivingMobileNumberErr("");
    setPackageWeightErr("");
    setPackageSpaceErr("");
  }

  console.log(DeliveryRequestsSelector[0]);

  return (
    <div className="AddDelivery">
      <TOHeader />
      <ArrowBackIcon
        style={{ color: "black", fontSize: "30px" }}
        className="float-left mt-3 ml-3 mr-3"
        onClick={handleClick}
      />
      <h2 className="text-left mt-3">
        Add Deliveries
        <span className="font-weight-normal">
          (Trip-{TripDetails.tripId} {TripDetails.startDate.slice(0, 10)})
        </span>
      </h2>
      <Row className="m-0 p-0">
        <Col className="col-4 addDelivery">
          {DeliveryRequestsSelector[0] != null &&
          DeliveryRequestsSelector[0].length ? (
            DeliveryRequestsSelector[0].map((object, index) => (
              <TOPackageComponent
                color="black"
                key={index}
                numbers={index}
                DeliveryRequests1={object}
              />
            ))
          ) : (
            <h2>No Packages in the trip.</h2>
          )}
        </Col>
        <form onSubmit={onSubmit}>
          <Col className="col-8">
            <div className="packagecard1">
              <div className="d-flex flex-column">
                <div className="Addpackage33">
                  <img
                    src={box3}
                    width={30}
                    height={30}
                    className="ml-2 float-left mr-2 mt-2 mb-2"
                  />
                  <span className="float-left mt-2 ml-2 packageno33">
                    Package {Number(TripDetails.total_packages) + 1}
                  </span>
                </div>
                <Row className="mt-3 mb-1">
                  <Col>
                    <p className="m-0 p-0 float-left pl-3">Package Weight</p>
                    <input
                      type="number"
                      min="1"
                      onChange={(e) => {setPackageWeight(e.target.value);
                        onChangetextremovevalidationmessage();
                      }}
                    />
                    {Object.keys(PackageWeightErr).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {PackageWeightErr[key]}
                        </div>
                      );
                    })}
                  </Col>
                  <Col>
                    <p className="m-0 p-0 float-left pl-3">Package Space</p>
                    <input
                      type="number"
                      min="1"
                      onChange={(e) => {setPackageSpace(e.target.value);
                        onChangetextremovevalidationmessage();
                      }}
                    />
                    {Object.keys(PackageSpaceErr).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {PackageSpaceErr[key]}
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <select
                      name="city"
                      className="droppoint"
                      onChange={(e) => setDropPoint(e.target.value)}
                    >
                      <option default>Delivery Drop Point</option>
                      {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
                    </select>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-8">
                    <p className="float-left pl-3">Drop Customer Name</p>
                    <input
                      type="text"
                      onChange={(e) => {
                        setDropCustomerName(e.target.value);
                        onChangetextremovevalidationmessage();
                      }}
                    />
                    {Object.keys(DropCustomerNameErr).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {DropCustomerNameErr[key]}
                        </div>
                      );
                    })}
                  </Col>
                  <Col className="col-4">
                    <p className="float-left pl-3">Mobile Number</p>
                    <input
                      type="text"
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                        onChangetextremovevalidationmessage();
                      }}
                    />
                    {Object.keys(mobileNumberErr).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {mobileNumberErr[key]}
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <select
                      name="city"
                      className="droppoint"
                      onChange={(e) => setPickUpPoint(e.target.value)}
                    >
                      <option default>Delivery Pickup Point</option>
                      {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
                    </select>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-8">
                    <p className="float-left pl-3">Pickup Customer Name</p>
                    <input
                      type="text"
                      onChange={(e) => {
                        setPickupCustomerName(e.target.value);
                        onChangetextremovevalidationmessage();
                      }}
                    />
                    {Object.keys(PickupCustomerNameErr).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {PickupCustomerNameErr[key]}
                        </div>
                      );
                    })}
                  </Col>
                  <Col className="col-4">
                    <p className="float-left pl-3">Mobile Number</p>
                    <input
                      type="text"
                      onChange={(e) => {
                        setreceivingMobileNumber(e.target.value);
                        onChangetextremovevalidationmessage();
                      }}
                    />
                    {Object.keys(ReceivingMobileNumberErr).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {ReceivingMobileNumberErr[key]}
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-7">
                    <a href="#" className="cancel">
                      Cancel Delivery
                      <span>
                        <IconButton aria-label="delete">
                          <DeleteIcon
                            style={{ color: "orange", fontSize: "20px" }}
                          />
                        </IconButton>
                      </span>
                    </a>
                  </Col>
                  <Col className="col-5">
                    <input
                      type="submit"
                      value="Accept Delivery"
                      onClick={onSubmit}
                      className="mt-2"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="request">
                      <a
                        href="#"
                        className="text-black float-left ml-2 mb-2 another font-weight-normal"
                        onClick={() => {
                          history.push("/AddDelivery");
                        }}
                      >
                        Add another Delivery request +
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {success === "Yes" ? (
              <p className="addDeliveryStatus">Delivery sucessfully added.</p>
            ) : success === "No" ? (
              <p className="addDeliveryStatus">
                Delivery failed. Try again. {message}
              </p>
            ) : (
              <div></div>
            )}
          </Col>
        </form>
      </Row>
    </div>
  );
}

export default Adddelivery;