import React, { useState } from "react";
import "./Otp.css";
import OTPInput from "otp-input-react";
import { useHistory } from "react-router-dom";
import Arrow from "../../Images/Arrow.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import qs from "querystring";
import { localAxios } from "../../Axios/axios";
import { customerOtp } from "../../Redux/actions/customerInfo";

function Otp() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const [errorOTP, setErrorOTP] = useState("");
  const customerOtpSelector = useSelector((state) => state.CustomerOtp);
  const customerMobileNumSelector = useSelector(
    (state) => state.CustomerMobileNum
  );
  console.log(customerOtpSelector);
  let logIn = "";

  const handleClick = () => {
    if (OTP === customerOtpSelector) {
      logIn = "true";
      history.push("/Login");
      console.log(logIn);
    } else {
      logIn = "false";
      console.log(logIn);
      setErrorOTP("Invalid otp");
    }
  };

  const ResendCode = async () => {
    setOTP("");
    setErrorOTP("");
    await axios(
      localAxios(
        "/SMSLogin",
        qs.stringify({
          mobileNum: customerMobileNumSelector,
        })
      )
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode === 200 && res.data.customerDetails) {
          dispatch(customerOtp(res.data.otp));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form>
      <div className="packageOwnerOtp">
        <div className="packageOtp">
          <pre>
            {" "}
            {`Enter the 4-Digit Mobile Verification Code 
  You shall recieve the code to your Number`}
          </pre>
          <h1>Verification Code</h1>
          <div className="otpboxPO">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          {errorOTP && <h5 className="errorPO"> {errorOTP} </h5>}
          <p>Hint:Your OTP is {customerOtpSelector}</p>
          <h6 onClick={ResendCode}>Resend Verification Code</h6>
          <div className="arrowPO">
            <img
              src={Arrow}
              alt=" "
              onClick={handleClick}
              className="arrowPOstyle"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default Otp;