import React from "react";
import TOHeader from "../ToHeader/TOHeader";
import "./TOnewdeliveryalert.css";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import { Col, Row } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import box3 from "../../Images/box-3.png";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

function TOnewdeliveryalert() {
  const history = useHistory();
  const handleClick1 = () => history.push("/AddDelivery");
  const handleClick2 = () => history.push("/addtrip");
  const handleClick3 = () => history.push("/deliveryrequests");
  const [status, setStatus] = React.useState(false);
  const [status1, setStatus1] = React.useState(false);
  const [status2, setStatus2] = React.useState(false);
  const [status3, setStatus3] = React.useState(false);

  return (
    <div className="newalert">
      <TOHeader />
      <div className="d-flex flex-row">
        <div className="col-6">
          <div className="d-flex flex-column">
            <h4 className="mr-auto bol" onClick={handleClick2}>
              Add Trip +
            </h4>
            <div className="cardx mt-5">
              <div className="d-flex flex-row">
                <h4 className="font-weight-normal">Trip 29</h4>
                <h4 className="ml-auto font-weight-normal">
                  Maximise
                  <IconButton onClick={() => setStatus(!status)}>
                    <AddIcon
                      style={{ color: "orange", fontSize: "30px" }}
                      onClick={() => setStatus(!status)}
                    />
                  </IconButton>
                </h4>
              </div>
              <Col className="p-0">
                {status ? (
                  <Col className="cover p-0">
                    <Row className="brdr">
                      <p className="m-0 mr-auto">Trip 29</p>
                      <p className="m-0">Delete Trip</p>
                      <a>
                        <DeleteIcon
                          style={{ color: "orange", fontSize: "30px" }}
                        />
                      </a>
                    </Row>
                    <Row className="brdr1">
                      <Col className="col-5">
                        <p className="m-0 t-l">Available Weight</p>
                        <p className="font-weight-bold m-0 t-l">300.KG</p>
                        <div className="">
                          <div className="mt-2 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Delivery Start Point
                            </p>
                            <p className="m-0">Hyderabad, Miyapur</p>
                          </div>
                          <div className="t-l ml-3 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Delivery End Point
                            </p>
                            <p className="m-0">Delhi - Barakhamba Rd</p>
                          </div>
                          <div className="t-l ml-3 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Date & Time
                            </p>
                            <p className="m-0">03-05-2020, 20:30 IST</p>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-4">
                        <p className="m-0 t-l">Available Space</p>
                        <p className="font-weight-bold m-0 t-l">4.FT</p>
                      </Col>
                      <Col className="col-3">
                        <p className="m-0 font-weight-bold">Packages</p>
                        <h2 className="color1">38</h2>
                      </Col>
                      <div className="mt-2 mb-3 d-flex flex-row under">
                        <a
                          href="#"
                          className="text-black float-left ml-2 mb-2 fon font-weight-normal text-dark"
                          onClick={handleClick1}
                        >
                          + Add Delivery
                        </a>
                        <div className="deli">
                          <p className="coloro ml-auto">
                            Delivery Requests
                            <ArrowForwardIosIcon
                              style={{ color: "darkorange" }}
                              fontSize="medium"
                              onClick={handleClick3}
                            />
                          </p>
                        </div>
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </Col>
            </div>
            <div className="cardx">
              <div className="d-flex flex-row">
                <h4 className="font-weight-normal">Trip 28</h4>
                <h4 className="ml-auto font-weight-normal">
                  Maximise
                  <IconButton>
                    <AddIcon
                      style={{ color: "orange", fontSize: "30px" }}
                      onClick={() => setStatus1(!status1)}
                    />
                  </IconButton>
                </h4>
              </div>
              <Col className="p-0">
                {status1 ? (
                  <Col className="cover p-0">
                    <Row className="brdr">
                      <p className="m-0 mr-auto">Trip 28</p>
                      <p className="m-0">Delete Trip</p>
                      <a>
                        <DeleteIcon
                          style={{ color: "orange", fontSize: "30px" }}
                        />
                      </a>
                    </Row>
                    <Row className="brdr1">
                      <Col className="col-5">
                        <p className="m-0 t-l">Available Weight</p>
                        <p className="font-weight-bold m-0 t-l">300.KG</p>
                        <div className="">
                          <div className="mt-2 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Delivery Start Point
                            </p>
                            <p className="m-0">Hyderabad, Miyapur</p>
                          </div>
                          <div className="t-l ml-3 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Delivery End Point
                            </p>
                            <p className="m-0">Delhi - Barakhamba Rd</p>
                          </div>
                          <div className="t-l ml-3 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Date & Time
                            </p>
                            <p className="m-0">03-05-2020, 20:30 IST</p>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-4">
                        <p className="m-0 t-l">Available Space</p>
                        <p className="font-weight-bold m-0 t-l">4.FT</p>
                      </Col>
                      <Col className="col-3">
                        <p className="m-0 font-weight-bold">Packages</p>
                        <h2 className="color1">38</h2>
                      </Col>
                      <div className="mt-2 mb-3 d-flex flex-row under">
                        <a
                          href="#"
                          className="text-black float-left ml-2 mb-2 fon font-weight-normal text-dark"
                          onClick={handleClick1}
                        >
                          + Add Delivery
                        </a>
                        <div className="deli">
                          <p className="coloro ml-auto">
                            Delivery Requests
                            <ArrowForwardIosIcon
                              style={{ color: "darkorange" }}
                              fontSize="medium"
                              onClick={handleClick3}
                            />
                          </p>
                        </div>
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </Col>
            </div>
            <div className="cardx">
              <div className="d-flex flex-row">
                <h4 className="font-weight-normal">Trip 27</h4>
                <h4 className="ml-auto font-weight-normal">
                  Maximise
                  <IconButton>
                    <AddIcon
                      style={{ color: "orange", fontSize: "30px" }}
                      onClick={() => setStatus2(!status2)}
                    />
                  </IconButton>
                </h4>
              </div>
              <Col className="p-0">
                {status2 ? (
                  <Col className="cover p-0">
                    <Row className="brdr">
                      <p className="m-0 mr-auto">Trip 27</p>
                      <p className="m-0">Delete Trip</p>
                      <a>
                        <DeleteIcon
                          style={{ color: "orange", fontSize: "30px" }}
                        />
                      </a>
                    </Row>
                    <Row className="brdr1">
                      <Col className="col-5">
                        <p className="m-0 t-l">Available Weight</p>
                        <p className="font-weight-bold m-0 t-l">300.KG</p>
                        <div className="">
                          <div className="mt-2 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Delivery Start Point
                            </p>
                            <p className="m-0">Hyderabad, Miyapur</p>
                          </div>
                          <div className="t-l ml-3 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Delivery End Point
                            </p>
                            <p className="m-0">Delhi - Barakhamba Rd</p>
                          </div>
                          <div className="t-l ml-3 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Date & Time
                            </p>
                            <p className="m-0">03-05-2020, 20:30 IST</p>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-4">
                        <p className="m-0 t-l">Available Space</p>
                        <p className="font-weight-bold m-0 t-l">4.FT</p>
                      </Col>
                      <Col className="col-3">
                        <p className="m-0 font-weight-bold">Packages</p>
                        <h2 className="color1">38</h2>
                      </Col>
                      <div className="mt-2 mb-3 d-flex flex-row under">
                        <a
                          href="#"
                          className="text-black float-left ml-2 mb-2 fon font-weight-normal text-dark"
                          onClick={handleClick1}
                        >
                          + Add Delivery
                        </a>
                        <div className="deli">
                          <p className="coloro ml-auto">
                            Delivery Requests
                            <ArrowForwardIosIcon
                              style={{ color: "darkorange" }}
                              fontSize="medium"
                              onClick={handleClick3}
                            />
                          </p>
                        </div>
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </Col>
            </div>
            <div className="cardx">
              <div className="d-flex flex-row">
                <h4 className="font-weight-normal">Trip 26</h4>
                <h4 className="ml-auto font-weight-normal">
                  Maximise
                  <IconButton>
                    <AddIcon
                      style={{ color: "orange", fontSize: "30px" }}
                      onClick={() => setStatus3(!status3)}
                    />
                  </IconButton>
                </h4>
              </div>
              <Col className="p-0">
                {status3 ? (
                  <Col className="cover p-0">
                    <Row className="brdr">
                      <p className="m-0 mr-auto">Trip 26</p>
                      <p className="m-0">Delete Trip</p>
                      <a>
                        <DeleteIcon
                          style={{ color: "orange", fontSize: "30px" }}
                        />
                      </a>
                    </Row>
                    <Row className="brdr1">
                      <Col className="col-5">
                        <p className="m-0 t-l">Available Weight</p>
                        <p className="font-weight-bold m-0 t-l">300.KG</p>
                        <div className="">
                          <div className="mt-2 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Delivery Start Point
                            </p>
                            <p className="m-0">Hyderabad, Miyapur</p>
                          </div>
                          <div className="t-l ml-3 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Delivery End Point
                            </p>
                            <p className="m-0">Delhi - Barakhamba Rd</p>
                          </div>
                          <div className="t-l ml-3 mb-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              Date & Time
                            </p>
                            <p className="m-0">03-05-2020, 20:30 IST</p>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-4">
                        <p className="m-0 t-l">Available Space</p>
                        <p className="font-weight-bold m-0 t-l">4.FT</p>
                      </Col>
                      <Col className="col-3">
                        <p className="m-0 font-weight-bold">Packages</p>
                        <h2 className="color1">38</h2>
                      </Col>
                      <div className="mt-2 mb-3 d-flex flex-row under">
                        <a
                          href="#"
                          className="text-black float-left ml-2 mb-2 fon font-weight-normal text-dark"
                          onClick={handleClick1}
                        >
                          + Add Delivery
                        </a>
                        <div className="deli">
                          <p className="coloro ml-auto">
                            Delivery Requests
                            <ArrowForwardIosIcon
                              style={{ color: "darkorange" }}
                              fontSize="medium"
                              onClick={handleClick3}
                            />
                          </p>
                        </div>
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </Col>
            </div>
          </div>
        </div>
        <div className="col-6 halfpage1">
          <div className="Tripcard">
            <div className="d-flex clr-trip flex-column">
              <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                  <h2 className="text-left ml-2 mt-2 mb-1 font-weight-normal">
                    New Delivery Alert
                  </h2>
                  <h4 className="text-left ml-2 mt-1 mb-1 font-weight-normal">
                    Trip 30 (03-05-2020)
                  </h4>
                </div>
                <IconButton aria-label="close" className="float-right ml-auto">
                  <CloseIcon style={{ color: "black" }} fontSize="large" />
                </IconButton>
              </div>
              <div className="d-flex flex-row">
                <p className="text-left ml-3 mt-3 mb-3 font-weight-normal">
                  Package Weight
                </p>
                <p className=" text-left ml-3 mt-3 mb-3 font-weight-normal">
                  Package Space
                </p>
              </div>
              <div className="d-flex flex-row">
                <h4 className="text-left ml-3 mt-1 mb-3 font-weight-normal">
                  20 K.G
                </h4>
                <h4 className="text-left ml-5 mt-1 mb-3 font-weight-normal">
                  2 FT
                </h4>
              </div>
              <Row>
                <Col className="text-left mt-1">
                  <div className="pl-3">
                    <div className="mt-2 mb-2">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Pickup Location
                      </p>
                      <p className="m-0">Hyderabad, Miyapur</p>
                    </div>
                    <div className="mt-2 mb-2">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Customer name
                      </p>
                      <p className="m-0">Hyderabad, Miyapur</p>
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        9985899858
                      </p>
                    </div>
                    <div className="mt-2 mb-2">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Drop Location
                      </p>
                      <p className="m-0">Delhi - Barakhamba Rd</p>
                    </div>
                    <div className="mt-2 mb-2">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Customer name
                      </p>
                      <p className="m-0">Hyderabad, Miyapur</p>
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        9985899858
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="text-right">
                  <img
                    src={box3}
                    alt=""
                    width={250}
                    height={250}
                    className="mr-5"
                  />
                </Col>
              </Row>
              <div className="d-flex flex-row">
                <input className="mt-3 ml-2" type="submit" value="CANCEL" />
                <input className="mt-3 ml-5" type="submit" value="ACCEPT" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TOnewdeliveryalert;