import React from "react";
import Header from "../PoHeader/Header";
import "./TrackDelivery.css";
import orangecall1 from "../../Images/orangecall1.png";
import PACKAGE from "../../Images/PACKAGE.png";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function TrackDelivery() {
  const history = useHistory();
  const customerMobileNumSelector = useSelector(
    (state) => state.CustomerMobileNum
  );
  console.log(customerMobileNumSelector);
  const selectedTruckDetails = useSelector((state) => state.SelectedTruckData);
  const trackTruckDetails = useSelector((state) => state.TrackingTruckData);
  console.log(trackTruckDetails);
  console.log(trackTruckDetails[0].truck_no);
  const handleClick = () => history.push("/Details");
  const handleClick1 = () => {
    trackTruckDetails[0].delivered
      ? history.push("/pkgstatus")
      : history.push("/map");
  };

  return (
    <div className="trackdeliveryDiv">
      <div className="headerPartInTrackDelivery">
        <Header />
      </div>
      <div className="mainDivInTrackDelivery">
        <div className="packageTitleDiv">
          <h2 className="packageTitle">Your package is on the way</h2>
        </div>
        <div className="trackDeliveryDiv">
          <div className="trackDeliverySubDiv">
            <div className="cardIntrackDelivery">
              <div className="headerForCardInTruck">
                <div>
                  <img
                    src={PACKAGE}
                    width={30}
                    height={30}
                    alt=" "
                    className="ml-2 float-left "
                  />
                  <span className="float-left  ml-4 text-white">Status : </span>
                  <span className="float-left  ml-2 text-orange">
                    {trackTruckDetails[0].status === null
                      ? "Yet to Pickup"
                      : trackTruckDetails[0].status === "Accep"
                      ? "In Transit"
                      : "Delivered"}
                  </span>
                </div>
                <div>
                  <button className="trackButton  mr-3 " onClick={handleClick1}>
                    Track Delivery
                  </button>
                </div>
              </div>
              <div className="truckOwnerNameDiv">
                <img
                  src={orangecall1}
                  width={50}
                  height={50}
                  alt=" "
                  className="ml-4 float-left mr-2 mt-2"
                />
                <div className="truckownerName">
                  <h5>{` ${"  "}(${selectedTruckDetails.truckNo})`}</h5>
                  <h6>Ashok Pattiyala</h6>
                </div>
              </div>
              <div className="loactionSymbolsInTrack">
                <LocationOnIcon color="disabled" fontSize="large" />
                <LocationOnIcon color="disabled" fontSize="large" />
              </div>
              <div className="travelDataInTrack">
                <div className="pickUpDataInTrack">
                  <p className="travelDataLineInTrack">
                    {selectedTruckDetails.pickUp}
                  </p>
                  {/* <p className="travelDataLineInTrack">Miyapur Main Road</p> */}
                  <p className="travelDataLineInTrack">
                    {selectedTruckDetails.startDate}
                  </p>
                </div>
                <div className="destinationDataInTrack">
                  <p className="travelDataLineInTrack">
                    {selectedTruckDetails.drop}
                  </p>
                  {/* <p className="travelDataLineInTrack">Barakhamba Rd</p> */}
                  <p className="travelDataLineInTrack">4th May, 9.30 PM</p>
                </div>
              </div>
            </div>
          </div>
          <div className="anotherPackageDiv">
            <IconButton aria-label="add">
              <AddIcon
                style={{ fontSize: "100px", color: "black" }}
                onClick={handleClick}
              />
            </IconButton>
            <h2>Deliver a Package </h2>
            <h5
              // className="text-white"
              className="textUnderline"
              onClick={handleClick}
            >
              Want to Deliver a Package ?
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackDelivery;