import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { useHistory } from "react-router-dom";
import "./TOotp1.css";
import Arrow from "../../Images/Arrow.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import qs from "querystring";
import { ownerOtp } from "../../Redux/actions/ownerInfo";
import { localAxios, localAxiosToken } from "../../Axios/axios";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";
import { truckDetails } from "../../Redux/actions/ownerTruckInfo";

function TOotp1() {
  const history = useHistory();
  
  const citiesArray = [];

  let logIn = "";
  const toOtp1Function = () => {
    if (OTP === truckOwnerOtpSelector) {
      logIn = "true";
       try {
      axios(localAxiosToken("/getListOfCities"))
        .then((res) => {
          const cities = res.data.data;
          if (res.data.statusCode === 200) {
           const cityNames = cities.map((city) => city.city_name);
            citiesArray.push(...cityNames);
            const uniqueCities = [...new Set(citiesArray)];
            dispatch({ type: "SET_CITIES_LIST", payload: uniqueCities });
            if (citiesArray.length > 0) {
      history.push("/addtrip");
            }
          }
          else {
            console.log(res.data);
            alert(res.data.message);
          }
        })
        .catch((err) => console.log(err))
    } catch (err) {
      console.log(err,"Error");
    }
      console.log(logIn);
    } else {
      logIn = "false";
      console.log(logIn);
      setErrorOTP("Invalid otp");
    }
  };

  const [OTP, setOTP] = useState("");
  const [errorOTP, setErrorOTP] = useState("");
  const dispatch = useDispatch();

  const OwnerMobileNum = useSelector((state) => state.OwnerMobileNum);
  const truckOwnerOtpSelector = useSelector((state) => state.OwnerOtp);

  const TruckNo = useSelector((state) => state.TruckNo);
  const OwnerToken = useSelector((state) => state.OwnerToken);

  const ResendCode = async () => {
    console.log(OwnerMobileNum);
    setOTP("");
    setErrorOTP("");

    await axios(
      localAxios(
        "/SMSLogin",
        qs.stringify({
          mobileNum: OwnerMobileNum,
        })
      )
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode !== 400) {
          if (res.data.truckOwner) {
            dispatch(ownerOtp(res.data.otp));
            console.log(res.data.truckDetails[0].truck_no);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = () => {
    const data = qs.stringify({ truckNo: TruckNo });
    axios(localAxiosToken("/getTripByTruckNo", data, OwnerToken))
      .then((response) => {
        if (response.data.statusCode !== 400) {
          console.log(response.data);
          const tripDetails = response.data.message.tripDetails
            ? response.data.message.tripDetails
            : 0;
          console.log(`${JSON.stringify(tripDetails)}`);
          console.log(tripDetails.length);
          // console.log(response.data.message.truckDetails[0]);
          // const truck = response.data.message.truckDetails[0];
          // console.log(truck);
          dispatch(tripHistory(tripDetails.reverse()));
          // dispatch(truckDetails(response.data.message.truckDetails[0]));
          console.log(tripHistory, "Tripppp history");
          // console.log(response.data.message.tripDetails[0].truck_no);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <form>
      {fetchData()}
      <div className="truckOwnerOtp1">
        <div className="truckOtp1">
          <pre>
            {`Enter the 4-Digit Mobile Verification Code 
  You shall recieve the code to your Number`}
          </pre>
          <h1>Verification Code</h1>
          <div className="otpboxTO1">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          {errorOTP && <h5 className="errorTO1"> {errorOTP} </h5>}
          <p>Hint:Your OTP is {truckOwnerOtpSelector}</p>
          <h6 onClick={ResendCode}>Resend Verification Code</h6>
          <div className="arrowTO1">
            <img
              src={Arrow}
              onClick={toOtp1Function}
              alt=" "
              className="arrowTO1style"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default TOotp1;