import React, { useState } from "react";
import ultimatedeliveryicon from "../../Images/ultimatedeliveryicon.png";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import IconButton from "@material-ui/core/IconButton";
import "./TCHeader.css";
import { Link } from "react-router-dom";
import { TraSidebarData } from "./TraSidebarData";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

function TCHeader() {
  const [sidebar, setSidebar] = useState(false);
  const companyNameSelector = useSelector((state) => state.CompanyName);
  const showSidebar = () => setSidebar(!sidebar);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 300px)",
  });

  return (
    <div className="containerBodyInTransport">
      <div className="logoStyleForTransport">
        <img src={ultimatedeliveryicon} className="logoImageInTransport"></img>
        <div className="preTextDivInTransport">
          <pre className="preTextForTransport">
            {`
ULTIMATE
DELIVERY
                    `}
          </pre>
        </div>
      </div>
      <div className="profileStyleInTransport">
        <span className="welcomeTextInTransport">
          Welcome,
          <span className="profileNameInTransport">
            {`${" "}${companyNameSelector}`} !
          </span>
        </span>
        <IconButton
          aria-label="account"
          onClick={showSidebar}
          className="ml-2 profileButtonInTransport"
          style={{ color: "white", border: "none", outline: "none" }}
        >
          {isMobile ? (
            <AccountCircleRoundedIcon color="inherit" fontSize="small" />
          ) : (
            <AccountCircleRoundedIcon color="inherit" fontSize="large" />
          )}
        </IconButton>
      </div>
      <div className={sidebar ? "sideNavInTransport" : " sideNav1InTransport"}>
        <nav className="navLinksInTransportHeader">
          <ul className="navMenuItemsInTransport p-0" onClick={showSidebar}>
            {TraSidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span className="title1">{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default TCHeader;