import React, { onChange } from "react";
import TCHeader from "../TcHeader/TCHeader";
import "./TCdeliverydetails.css";
import SearchField from "react-search-field";
import MinimizeIcon from "@material-ui/icons/Minimize";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import Truck from "../../Images/Truck.png";
import Circle from "../../Images/circle.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import {
  deliveryRequests,
  tripDetails,
} from "../../Redux/actions/tripsAvailable";
import qs from "querystring";
import ModalPage from "../TcModalPage/TcModal";
import { modalPageOpening } from "../../Redux/actions/transportCompanyInfo";

function TCdeliverydetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const checkList = "checkList";
  const addDelivery = "addDelivery";
  const tripHistorySelector = useSelector((state) => state.TripHistory);
  const OwnerToken = useSelector((state) => state.OwnerToken);
  const allTruckDataSelector = useSelector((state) => state.AllTruckData);
  const modalPageDataSelector = useSelector((state) => state.ModalPageOpening);
  console.log(tripHistorySelector, "tripHistory");
  console.log(allTruckDataSelector);

  const handleClick = (data, page) => {
    console.log(data);
    const tripId = data.trip_id;
    const params = qs.stringify({ tripId });
    axios(localAxiosToken("/getTripPackages", params, OwnerToken))
      .then((res) => {
        console.log(params);
        console.log(res.data);
        dispatch(deliveryRequests(res.data.packageDetails));
        if (page === "checkList") history.push("/TCdeliverychecklist");
        else history.push("/TCdeliveryrequests");
      })
      .catch((err) => console.log(err));
  };

  const handleClick2 = (tripData) => {
    dispatch(tripDetails(tripData));
    history.push("/TCadddeliveries");
  };

  const handleClick3 = () => history.push("/TCmap");

  function openModal() {
    dispatch(modalPageOpening("true"));
  }

  return (
    <div className="tCDeliveryDetails">
      <div className="headerInTcDeliveryDetailsPage">
        <TCHeader />
      </div>
      <div className="mainDivIntcDeliveryDetailsPage">
        <div className="firstDivInTcDeliveryDetailsPage">
          <h4 className="addTripTextInDeliveryPage" onClick={openModal}>
            Add Trip +
          </h4>
          {
            (console.log(modalPageDataSelector),
            modalPageDataSelector ? <ModalPage></ModalPage> : null)
          }
          <div className="searchBoxInTcDeliveryDetails">
            <SearchField
              placeholder="Search with Truck/Location"
              onChange={onChange}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap">
          {tripHistorySelector.map((data, index) => (
            <div key={index}>
              <div className="trtripcard ">
                <div className="AddTrip ">
                  <img
                    src={Truck}
                    width={40}
                    height={30}
                    className="ml-2 float-left mr-2 mt-2"
                  />
                  <span className="float-left mt-2 truck22">
                    Truck {data.truck_no}
                  </span>
                  <img
                    src={Circle}
                    width={25}
                    height={25}
                    className="ml-2 float-left mr-2 mt-2"
                  />
                  <p className="text-right">
                    Minimize
                    <IconButton aria-label="minimize">
                      <MinimizeIcon style={{ color: "black" }} />
                    </IconButton>
                  </p>
                </div>
                <div className="d-flex ml-3 flex-row">
                  <div>
                    <div className="d-flex flex-row">
                      <div className="d-flex flex-column ">
                        <p className="text-left m-0"> Available Weight </p>
                        <p
                          className="text-left "
                          style={{ fontSize: 20, color: "gray" }}
                        >
                          {data.capacity_inkgs -
                            (data.booked_weight === null
                              ? 0
                              : data.booked_weight)}
                          . KG
                        </p>
                      </div>
                      <div className="d-flex flex-column ml-3">
                        <p className="text-left m-0"> Available Space</p>
                        <p
                          className="text-left m-0"
                          style={{ fontSize: 20, color: "gray" }}
                        >
                          {data.capacity_inspace -
                            (data.booked_space === null
                              ? 0
                              : data.booked_space)}
                          . FT
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-column ">
                      <p className="text-left m-0 boldd">Trip Start Point</p>
                      <p className="text-left m-0">{data.source}</p>
                    </div>
                    <div className="d-flex flex-column ">
                      <p className="text-left m-0 boldd"> Trip End Point</p>
                      <p className="text-left m-0"> {data.destination}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-column ml-4 align-items-center">
                    <p className="text-left m-0 boldd">Packages</p>
                    <h1 className="text-left m-0">{data.total_packages}</h1>
                    <p className="text-left m-0">
                      Drop Done -
                      <span className="ml-1 colorgtdone">
                        {data.delivered_packages}
                      </span>
                    </p>
                    <p
                      className="text-left m-0 check"
                      onClick={() => handleClick(data, checkList)}
                    >
                      Check List
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column ml-3">
                  <p className="text-left m-0 boldd"> Date & Time</p>
                  <p className="text-left m-0">
                    {data.start_date.slice(0, 10)}
                  </p>
                </div>
                <div className="d-flex  flex-row ml-3">
                  <div className="d-flex flex-column ">
                    <p className="text-left m-0 boldd"> Driver Name</p>
                    <p className="text-left m-0"> {data.truck_driver}</p>
                  </div>
                  <div
                    className="ml-10"
                    style={{ marginTop: "1.5em", marginLeft: "8em" }}
                  >
                    <p className="text-right trackk " onClick={handleClick3}>
                      Track the Truck
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between ml-3 mb-3 mr-2 add">
                  <p
                    className="text-left m-0"
                    onClick={() => handleClick2(data)}
                  >
                    + Add Delivery
                  </p>
                  <p
                    onClick={() => handleClick(data, addDelivery)}
                    className="text-right m-0"
                    style={{
                      color: "darkorange",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Delivery Requests
                    <span>
                      <ArrowForwardIosIcon fontSize="small"></ArrowForwardIosIcon>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TCdeliverydetails;