import Axios from "axios";

export const axios = Axios.create({
  baseURL: "http://sut.basservices.in",
});

export const config = {
  mode: "cors",
  crossDomain: true,
  headers: {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
  },
};

export function configToken(token) {
  return {
    mode: "cors",
    crossDomain: true,
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
  };
}

export const localAxiosToken = (url, data, token) => {
  console.log("local axios called!", data);
  return {
    method: "post",
    url: `http://3.110.165.79/api/syt-api${url}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
};

export const localAxios = (url, data) => ({
  method: "post",
  url: `http://3.110.165.79/api/syt-api${url}`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  data,
});

export const localAxiosFormData = (url, data, token) => ({
  method: "post",
  url: `http://3.110.165.79/api/syt-api${url}`,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  },
  data,
});
