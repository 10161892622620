import { combineReducers } from 'redux';
import { CustomerFirstName, CustomerLastName,
  CustomerMobileNum, CustomerOtp, CustomerToken, CustomerPackages,
  ReceivingPackages,CitiesList } from './customerInfo';
import { PickUp, Drop, PickUpDate, EntireTruck,
  PackageWeight, PackageSpace, ReceivingPersonName,
  ReceivingPersonNum, PackageId, DropPersonName, DropPersonNum } from './packageDetails';
import { NoOfTripsFound, AllTripIdsNTruckNos, AllTruckDetails,
  AllTruckSpaceNWeight, SelectedTruckData, DeliveryRequests, TripDetails } from './tripsAvailable';
import { TruckRegisterFailed, LoginFailed } from './other';
import { OwnerFullName, OwnerOtp, OwnerToken, OwnerMobileNum, OwnerCheckList,OwnerRegisterMobileNum } from './TruckOwnerInfo';
import { TruckNo, TruckModel, TotalWeight, TotalSpace
  , TripHistory,TruckDetails,availableWeightReducer,availableSpaceReducer} from './ownerTruckInfo';
import { CompanyName, NoOfVehicals, ContactNumber, SelectedTruck,
  AllTruckData,TrackingTruckData,ModalPageOpening } from './transportCompanyInfo';

const allReducers = combineReducers({
  CustomerFirstName,
  CustomerLastName,
  CustomerMobileNum,
  CustomerOtp,
  CitiesList,
  CustomerPackages,
  ReceivingPackages,
  PickUp,
  Drop,
  PackageId,
  PickUpDate,
  EntireTruck,
  PackageWeight,
  PackageSpace,
  ReceivingPersonNum,
  ReceivingPersonName,
  DropPersonNum,
  DropPersonName,
  CustomerToken,
  NoOfTripsFound,
  AllTripIdsNTruckNos,
  AllTruckDetails,
  AllTruckSpaceNWeight,
  SelectedTruckData,
  // truck Owner
  TruckNo,
  TruckModel,
  TotalSpace,
  TotalWeight,
  OwnerFullName,
  OwnerOtp,
  OwnerToken,
  OwnerMobileNum,
  OwnerRegisterMobileNum,
  TruckRegisterFailed,
  TripHistory,
  TruckDetails,
  availableWeightReducer,
  availableSpaceReducer,
  DeliveryRequests,
  TripDetails,
  OwnerCheckList,
  // transport company
  CompanyName,
  ContactNumber,
  NoOfVehicals,
  SelectedTruck,
  AllTruckData,
  TrackingTruckData,
  ModalPageOpening
});

export default allReducers;