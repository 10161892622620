import React from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import orangecall1 from "../../Images/orangecall1.png";
import truck3 from "../../Images/truck3.png";
import { useHistory } from "react-router-dom";
import "./PackageComponent.css";
import { useSelector, useDispatch } from "react-redux";
import { trackingTruckData } from "../../Redux/actions/transportCompanyInfo";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import qs from "querystring";
import { selectedTruckData } from "../../Redux/actions/tripsAvailable";
import { useState } from "react";
import Rating from "../../Rating/rating";

function PackageComponent(packages) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [rating, setRating] = useState(false);
  const trackingTruckDetails = useSelector((state) => state.TrackingTruckData);
  const customerTokenSelector = useSelector((state) => state.CustomerToken);
  console.log(trackingTruckDetails);

  function TrackDeliveryFunction(e) {
    console.log(e);
    axios(
      localAxiosToken(
        "/getTruck2",
        qs.stringify({ truckNo: e[0].truck_no }),
        customerTokenSelector
      )
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode === 200) {
          const data1 = {
            truckNo: res.data.message[0].truck_no,
            model: res.data.message[0].truck_model,
            mobileNumber: res.data.message[0].truckowner_mobile_num,
          };
          dispatch(selectedTruckData(data1));
          console.log(e);
          dispatch(trackingTruckData(e));
          history.push("/TrackDelivery");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  console.log(packages);
  const RatingFunction = (e) => {
    axios(
      localAxiosToken(
        "/getTruck2",
        qs.stringify({ truckNo: e[0].truck_no }),
        customerTokenSelector
      )
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode === 200) {
          const data1 = {
            truckNo: res.data.message[0].truck_no,
            model: res.data.message[0].truck_model,
            mobileNumber: res.data.message[0].truckowner_mobile_num,
          };
          dispatch(selectedTruckData(data1));
          console.log(e);
          dispatch(trackingTruckData(e));
          history.push("/pkgstatus");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="d-flex flex-row flex-wrap mainDivInPackage">
      {packages.packages.map((data) => {
        return (
          <div className="cardInPackage">
            <div className="headerForCardInPackage">
              <div>
                <img
                  src={truck3}
                  width={65}
                  height={44}
                  alt=" "
                  className="ml-2 "
                />
                <span className="ml-2 text-white">Tata Ace </span>
              </div>
              <div className="mr-3">
                <span className="text-white ">Status : </span>
                <span className=" text-orange">
                  {data[0].delivered
                    ? "Delivered"
                    : data[0].status === null
                    ? "Yet to Pickup"
                    : " In Transit"}
                </span>
              </div>
            </div>
            <div className="truckOwnerNameDivInPackage">
              {data[0].status === null ? (
                <div className="yetToPickUpDiv">
                  <p>
                    Estimated Price <span>Rs 150</span>
                  </p>
                  <img
                    src={orangecall1}
                    width={30}
                    height={30}
                    alt=" "
                    className=" "
                  />
                </div>
              ) : data[0].delivered ? (
                <div className="deliveredDiv">
                  <div className="deliveredSubDiv">
                    <img
                      src={orangecall1}
                      width={30}
                      height={30}
                      alt=" "
                      className=" "
                    />
                    <div className="ml-3">
                      <p className="textBold">Delivery Partner</p>
                      <p>Ashok pattiyala</p>
                    </div>
                  </div>
                  <div className="deliveredSubDiv1">
                    {rating ? (
                      <div>
                        <Rating truckNo={data[0].truck_no} giveRating="abc" />
                      </div>
                    ) : (
                      <div>
                        <button
                          className="giveRatingButton"
                          onClick={() => RatingFunction(data)}
                        >
                          Give Rating
                        </button>
                      </div>
                    )}
                    <p className="">Rs 250</p>
                  </div>
                </div>
              ) : (
                <div className="deliveredDiv">
                  <div className="deliveredSubDiv">
                    <p>
                      Estimated Price <span>Rs 150</span>
                    </p>
                  </div>
                  <div className="deliveredSubDiv1">
                    <button
                      className="giveRatingButton"
                      onClick={() => TrackDeliveryFunction(data)}
                    >
                      Track Delivery
                    </button>
                    <img
                      src={orangecall1}
                      width={30}
                      height={30}
                      alt=" "
                      className="mr-3 "
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="loactionSymbolsInTrack">
              <LocationOnIcon color="disabled" fontSize="large" />
              <LocationOnIcon color="disabled" fontSize="large" />
            </div>
            <div className="travelDataInTrack">
              <div className="pickUpDataInTrack">
                <p className="travelDataLineInTrack">{data[1].pickup_point}</p>
                <p className="travelDataLineInTrack">Miyapur Main Road</p>
                <p className="travelDataLineInTrack">
                  {data[1].pickup_date.slice(0, 10)}
                </p>
              </div>
              <div className="destinationDataInTrack">
                <p className="travelDataLineInTrack"> {data[1].drop_point}</p>
                {/* <p className="travelDataLineInTrack">Barakhamba Rd</p> */}
                <p className="travelDataLineInTrack">4th May, 9.30 PM</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PackageComponent;