import React from "react";
import { NavLink } from "react-router-dom";

function Navigation1() {
  return (
    <div>
      <NavLink to="/"> </NavLink>
      <NavLink to="/TORegister"></NavLink>
      <NavLink to="/TOotp"></NavLink>
      <NavLink to="/TOLogin"> </NavLink>
      <NavLink to="/TOotp1"></NavLink>
      <NavLink to="/addtrip"></NavLink>
      <NavLink to="/Tripdetails"></NavLink>
      <NavLink to="/TOdeliveryalert"></NavLink>
      <NavLink to="/deliveryrequests"></NavLink>
      <NavLink to="/AddDelivery"></NavLink>
      <NavLink to="/TripStatus"></NavLink>
      <NavLink to="/TODeliverychecklist"></NavLink>
    </div>
  );
}

export default Navigation1;
