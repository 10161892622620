import React from "react";
import Header from "../PoHeader/Header";
import "./podelivery.css";
import orangecall1 from "../../Images/orangecall1.png";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useHistory } from "react-router-dom";
import truck3 from "../../Images/truck3.png";
import { useSelector } from "react-redux";
import Rating from "../../Rating/rating";

function Podelivery() {
  const history = useHistory();
  const handleClick = () => history.push("/Details");
  const trackingTruckDataDetails = useSelector(
    (state) => state.TrackingTruckData
  );
  
  return (
    <div className="poDeliveryDiv">
      <div className="headerInPoDelivery">
        <Header />
      </div>
      <div className="mainDivInPoDelivery">
        <div className="titleInPoDelivery">
          <h2 className="text-center ">Your package is Delivered</h2>
        </div>
        <div className="cardWithDeliveredStatus">
          <div className="poDeliverySubDiv">
            <div className="cardInPoDelivery">
              <div className="headerForCardInPoDelivery">
                <div className="subDivInCardPoDelivery">
                  <img
                    src={truck3}
                    width={60}
                    height={40}
                    alt=" "
                    className="ml-2 float-left "
                  />
                  <span className="truckModalInPoDelivery">Tata Ace</span>
                </div>
                <div className="statusDiv">
                  <p className="statusTextInPoDelivery">
                    Status :<span> Delivered</span>
                  </p>
                </div>
              </div>
              <div className="truckOwnerNameDivInPoDelivery">
                <div className="imageDivInPoDelivery">
                  <img
                    src={orangecall1}
                    width={40}
                    height={40}
                    alt=" "
                    className="ml-4 float-left mr-2 "
                  />
                  <div className="truckownerNameInPoDelivery">
                    <h5>Delivery Partner</h5>
                    <h6>Ashok Pattiyala</h6>
                  </div>
                </div>
                <div className="ratingDivInPoDelivery">
                  <Rating giveRating="false" />
                  <p>Rs 250</p>
                </div>
              </div>
              <div className="loactionSymbolsInPoDelivery">
                <LocationOnIcon color="disabled" fontSize="large" />
                <LocationOnIcon color="disabled" fontSize="large" />
              </div>
              <div className="travelDataInPoDelivery">
                <div className="pickUpDataInPoDelivery">
                  <p className="travelDataLineInPoDelivery">
                    {trackingTruckDataDetails[1].pickup_point}
                  </p>
                  {/* <p className="travelDataLineInPoDelivery">
                    Miyapur Main Road
                  </p> */}
                  <p className="travelDataLineInPoDelivery">
                    {trackingTruckDataDetails[1].pickup_date.slice(0, 10)}
                  </p>
                </div>
                <div className="destinationDataInPoDelivery">
                  <p className="travelDataLineInPoDelivery">
                    {trackingTruckDataDetails[1].drop_point}
                  </p>
                  {/* <p className="travelDataLineInPoDelivery">Barakhamba Rd</p> */}
                  <p className="travelDataLineInPoDelivery">4th May, 9.30 PM</p>
                </div>
              </div>
            </div>
          </div>
          <div className="anotherPackageDivInPoDelivery">
            <h5 className="textUnderlineInPoDelivery" onClick={handleClick}>
              Want to Book a Truck for another Package ?
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Podelivery;