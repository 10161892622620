import React from "react";
import { Col, Row } from "react-bootstrap";
import { Media } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import call from "../../Images/call.png";
import box from "../../Images/box.png";
import "./TOPackageComponent.css";
import axios from "axios";
import qs from "querystring";
import { localAxiosToken } from "../../Axios/axios";
import { useSelector, useDispatch } from "react-redux";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";
import { truckDetails } from "../../Redux/actions/ownerTruckInfo";
import { deliveryRequests } from "../../Redux/actions/tripsAvailable";
import { useHistory } from "react-router-dom";

function TOPackageComponent({ DeliveryRequests1, color, numbers }) {
  const history = useHistory();

  console.log(DeliveryRequests1[0]);
  console.log(DeliveryRequests1[1].package_id);

  const OwnerToken = useSelector((state) => state.OwnerToken);
  const TripDetails = useSelector((state) => state.TripDetails);

  const params2 = (mappingId) =>
    qs.stringify({
      delivered: false,
      status: "Accepted",
      mappingId,
    });

  const params3 = (mappingId) =>
    qs.stringify({
      delivered: true,
      status: "Accepted",
      mappingId,
    });

  const params4 = (mappingId) =>
    qs.stringify({
      delivered: false,
      status: "cancel",
      mappingId,
    });

  const mapPackageToTruck = (mappingId, tripId) => {
    axios(
      localAxiosToken("/updatePackageMapping", params2(mappingId), OwnerToken)
    )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => console.log(error));
    const params = qs.stringify({ tripId });
    axios(localAxiosToken("/getTripPackages", params, OwnerToken))
      .then((res) => {
        console.log(params);
        dispatch(deliveryRequests(res.data.packageDetails));
        history.push("/deliveryrequests");
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const dispatch = useDispatch();

  console.log(TripDetails, "tripdetails");

  function fetchData(TruckNo) {
    console.log(TruckNo, "TruckNoTruckNoTruckNo");
    const data = qs.stringify({ truckNo: TruckNo });
    axios(localAxiosToken("/getTripByTruckNo", data, OwnerToken))
      .then((response) => {
        console.log(response.data);
        const tripDetails = response.data.message.tripDetails
          ? response.data.message.tripDetails
          : 0;
        console.log(`${JSON.stringify(tripDetails)}`);
        console.log(tripDetails.length);
        console.log(response.data.message.truckDetails[0]);
        const truck = response.data.message.truckDetails[0];
        console.log(truck);
        console.log("enterpackageComponent");
        dispatch(tripHistory(tripDetails.reverse()));
        dispatch(truckDetails(response.data.message.truckDetails[0]));
        console.log(response.data.message.tripDetails[0].truck_no);
      })
      .catch((err) => console.log(err));
  }

  const packageDelivery = (mappingId, tripId) => {
    axios(
      localAxiosToken("/updatePackageMapping", params3(mappingId), OwnerToken)
    )
      .then((response) => {
        console.log(response.data, response.data.message === "Updated!");
        if (response.data.message === "Updated!") {
          axios(
            localAxiosToken(
              "/incrementDeliveredPackages",
              qs.stringify({ tripId }),
              OwnerToken
            )
          )
            .then((response1) => {
              console.log(response1.data);
            })
            .catch((error) => console.log(error));
        }
        fetchData(TripDetails.truckNo);
      })
      .catch((error) => console.log(error));
  };

  console.log(DeliveryRequests1[0].status);
  console.log(DeliveryRequests1.length);
  console.log(DeliveryRequests1[1].pickup_point);
  console.log(DeliveryRequests1[1].drop_point);
  console.log(DeliveryRequests1[1].package_weight);
  console.log(DeliveryRequests1[1].package_space);

  const CancelDelivery = (mappingId, tripId) => {
    axios(
      localAxiosToken("/updatePackageMapping", params4(mappingId), OwnerToken)
    )
      .then((response) => {
        console.log(response.data);
        alert("Package has been cancelled");
      })
      .catch((error) => console.log(error));
    const params = qs.stringify({ tripId });
    axios(localAxiosToken("/getTripPackages", params, OwnerToken))
      .then((res) => {
        console.log(params);
        dispatch(deliveryRequests(res.data.packageDetails));
        history.push("/deliveryrequests");
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="mr-5 mb-4">
      <div className="packagecard">
        <div className="d-flex flex-column">
          {color === "black" ? (
            <div className="package1">
              <img
                src={box}
                width={30}
                height={30}
                className="ml-2 float-left mr-2 mt-2 mb-2"
                fontSize="large"
              />
              <span className="float-left mt-1 ml-2 packageno">
                Package {numbers + 1} - {DeliveryRequests1[0].status}
              </span>
            </div>
          ) : (
            <div className="package">
              <img
                src={box}
                width={30}
                height={30}
                className="ml-2 float-left mr-2 mt-2 mb-2"
                fontSize="large"
              />
              <span className="float-left mt-1 ml-2 packageno">
                Package {numbers + 1} - {DeliveryRequests1[0].status}
              </span>
            </div>
          )}
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              <p className="ml-1 mt-2 m-0">Package Weight</p>
              <p
                style={{ fontSize: 20, color: "gray" }}
                className="mr-auto ml-1"
              >
                {DeliveryRequests1[1].package_weight}. KG
              </p>
            </div>
            <div className="d-flex flex-column">
              <p className="ml-5 mt-2 m-0">Package Space</p>
              <p style={{ fontSize: 20, color: "gray" }} className="float-left">
                {DeliveryRequests1[1].package_space}. FT
              </p>
            </div>
            <div className="d-flex flex-column">
              <h4 className="ml-5 mt-2 blue">
                Rs {DeliveryRequests1[1].package_space * 20}
              </h4>
            </div>
          </div>
          <Row>
            <Col className="text-left">
              <div className="pl-2">
                <p className="m-0" style={{ fontWeight: "bold" }}>
                  Drop Location
                </p>
                <p className="m-0">{DeliveryRequests1[1].drop_point}</p>
                <p className="m-0" style={{ fontWeight: "bold" }}>
                  Customer Name
                </p>
                <p className="m-0">{DeliveryRequests1[1].customer_name}</p>
                <Media className="mt-2">
                  <img
                    src={call}
                    width={20}
                    height={20}
                    className="mt-1 mr-1"
                  />
                  <Media.Body className="mr-3">
                    <p className="m-0" style={{ fontWeight: "bold" }}>
                      {DeliveryRequests1[1].customer_mobile_num}
                    </p>
                  </Media.Body>
                </Media>
              </div>
            </Col>
            <Col className="text-left">
              <p className="m-0" style={{ fontWeight: "bold" }}>
                Pickup Location
              </p>
              <p className="m-0">{DeliveryRequests1[1].pickup_point}</p>
              <p className="m-0" style={{ fontWeight: "bold" }}>
                Customer Name
              </p>
              <p className="m-0">
                {DeliveryRequests1[1].package_receiving_person}
              </p>
              <div className="mt-2">
                <img src={call} width={20} height={20} className="mr-1" />
                <span className="m-0" style={{ fontWeight: "bold" }}>
                  {DeliveryRequests1[1].receiving_person_mobile_no}
                </span>
              </div>
            </Col>
            {DeliveryRequests1[0].status === null ? (
              <div>
                <Row>
                  <Col className="text-left">
                    <a
                      href="#"
                      style={{
                        color: "darkorange",
                        textDecoration: "none",
                        fontWeight: "600",
                      }}
                      className="ml-4"
                    >
                      Cancel Delivery
                      <span>
                        <DeleteIcon
                          style={{ color: "orange", fontSize: "30px" }}
                          onClick={() =>
                            CancelDelivery(
                              DeliveryRequests1[0].mapping_id,
                              DeliveryRequests1[0].trip_id
                            )
                          }
                        />
                      </span>
                    </a>
                  </Col>
                  <Col className="text-right">
                    <input
                      type="submit"
                      value="Accept Delivery"
                      className="mt-2 ml-5 mb-3"
                      onClick={() =>
                        mapPackageToTruck(
                          DeliveryRequests1[0].mapping_id,
                          DeliveryRequests1[0].trip_id
                        )
                      }
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                {DeliveryRequests1[0].status === "cancel" ? null : (
                  <Row>
                    <Col className="text-left">
                      <input
                        type="submit"
                        value="Delivery Done"
                        className="mt-2 ml-4 mb-3"
                        onClick={() =>
                          packageDelivery(
                            DeliveryRequests1[0].mapping_id,
                            DeliveryRequests1[0].trip_id
                          )
                        }
                      />
                    </Col>
                    <Col className="text-right">
                      <a
                        href="#"
                        style={{
                          color: "darkorange",
                          textDecoration: "none",
                          fontWeight: "600",
                        }}
                        className="ml-4"
                      >
                        Cancel Delivery
                        <span>
                          <DeleteIcon
                            style={{ color: "orange", fontSize: "30px" }}
                            onClick={() =>
                              CancelDelivery(
                                DeliveryRequests1[0].mapping_id,
                                DeliveryRequests1[0].trip_id
                              )
                            }
                          />
                        </span>
                      </a>
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default TOPackageComponent;