import React from "react";
import "./AvailableTrucks.css";
import Header from "../PoHeader/Header";
import TruckComponent from "../PoTruckComponent/TruckComponent";
import { useSelector } from "react-redux";

function AvailableTrucks() {
  const allTruckDetailsSelector = useSelector((state) => state.AllTruckDetails);
  const pickUpPointSelector = useSelector((state) => state.PickUp);
  const dropPointSelector = useSelector((state) => state.Drop);
  const dateSelector = useSelector((state) => state.PickUpDate);
  const entireTruckSelector = useSelector((state) => state.EntireTruck);
  const receivingPersonNameSelector = useSelector(
    (state) => state.ReceivingPersonName
  );
  const packageSpaceSelector = useSelector((state) => state.PackageSpace);
  const packageWeightSelector = useSelector((state) => state.PackageWeight);
  const receivingPersonNumSelector = useSelector(
    (state) => state.ReceivingPersonNum
  );
  const customerTokenSelector = useSelector((state) => state.CustomerToken);

  let n = allTruckDetailsSelector.length;

  console.log(`No of available Trucks ${n}`);
  console.log(allTruckDetailsSelector);
  console.log(pickUpPointSelector);
  console.log(dropPointSelector);
  console.log(dateSelector);
  console.log(entireTruckSelector);
  console.log(receivingPersonNameSelector);
  console.log(packageSpaceSelector);
  console.log(packageWeightSelector);
  console.log(receivingPersonNumSelector);
  console.log(customerTokenSelector);
  // console.log(allTruckDetailsSelector[0]);

  return (
    <div className="AvailableTrucks">
      <div className="headerPart">
        <Header />
      </div>
      <div className="titleWithTrucks">
        <div className="availabletruckTitle">
          <h2>AvailableTrucks</h2>
        </div>
        <div className="availableTrucks">
          {allTruckDetailsSelector.length ? (
            allTruckDetailsSelector.map((object, index) => (
              <TruckComponent key={index} allData={object} />
            ))
          ) : (
            <h2 style={{ justifyContent: "center", marginTop: "23.5%" }}>
              {" "}
              We couldnt find any matching truck in your route.
            </h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default AvailableTrucks;