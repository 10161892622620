import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { useHistory } from "react-router-dom";
import "./TOotp.css";
import Arrow from "../../Images/Arrow.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import qs from "querystring";
import { localAxios } from "../../Axios/axios";
import { ownerOtp } from "../../Redux/actions/ownerInfo";

function TOotp() {
  const history = useHistory();
  const [OTP, setOTP] = useState("");
  const [errorOTP, setErrorOTP] = useState("");
  const truckOwnerOtpSelector = useSelector((state) => state.OwnerOtp);
  const OwnerMobileNum = useSelector((state) => state.OwnerMobileNum);
  let logIn = "";
  const dispatch = useDispatch();

  const toOtpFunction = () => {
    if (OTP === truckOwnerOtpSelector) {
      logIn = "true";
      history.push("/TOLogin");
      console.log(logIn);
    } else {
      logIn = "false";
      console.log(logIn);
      setErrorOTP("Invalid otp");
    }
  };

  const ResendCode = async () => {
    console.log(OwnerMobileNum);
    setOTP("");
    setErrorOTP("");
    await axios(
      localAxios(
        "/SMSLogin",
        qs.stringify({
          mobileNum: OwnerMobileNum,
        })
      )
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode !== 400) {
          if (res.data.truckOwner) {
            dispatch(ownerOtp(res.data.otp));
            console.log(res.data.truckDetails[0].truck_no);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form>
      <div className="truckOwnerOtp">
        <div className="truckOtp">
          <pre>
            {`Enter the 4-Digit Mobile Verification Code 
  You shall recieve the code to your Number`}
          </pre>
          <h1>Verification Code</h1>
          <div className="otpboxTO">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          {errorOTP && <h5 className="errorTO"> {errorOTP} </h5>}
          <p>Hint:Your OTP is {truckOwnerOtpSelector}</p>
          <h6 onClick={ResendCode}>Resend Verification Code</h6>
          <div className="arrowTO">
            <img
              src={Arrow}
              onClick={toOtpFunction}
              alt=" "
              className="arrowTOstyle"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default TOotp;