import React from "react";
import Header from "../PoHeader/Header";
import { useHistory } from "react-router-dom";
import "./receivingPersonPackages.css";
import { useSelector } from "react-redux";
import PackageComponent from "../PoPackageComponent/PackageComponent";

function ReceivingPersonPackages() {
  const history = useHistory();
  const handleClick = () => history.push("/Details");
  const getReceivingPackages = useSelector((state) => state.ReceivingPackages);

  return (
    <div className="deliverybooked">
      <div className="headerDivInReceivingPackages">
        <Header />
      </div>
      <div className="mainDivInReceivingPackages">
        <div className="receivingPackagesHeading">
          <h1 className="text-center receivingPackageFont">
            Your Receiving Packages
          </h1>
        </div>
        {getReceivingPackages ? (
          <div className="outlineInReceivingPackages">
            <PackageComponent
              packages={getReceivingPackages}
            ></PackageComponent>
            <div className="down">
              <a
                className="text-black font-weight-normal"
                href="#"
                style={{ textDecoration: "underline" }}
                onClick={handleClick}
              >
                Want to Book a Truck for another Package ?
              </a>
            </div>
          </div>
        ) : (
          <div className="outlineInDeliveryPackages">
            <p style={{ fontSize: "20px", marginTop: "10%" }}>
              You Don't Have Packages For Receiving
            </p>
            <div className="down">
              <a
                className="text-black font-weight-normal"
                href="#"
                style={{ textDecoration: "underline" }}
                onClick={handleClick}
              >
                Want to Book a Truck for another Package ?
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReceivingPersonPackages;