import React, { onChange } from "react";
import TCHeader from "../TcHeader/TCHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./TCDeliveryRequests.css";
import { Col, Row } from "react-bootstrap";
import BOX3 from "../../Images/BOX3.png";
import Truck from "../../Images/Truck.png";
import SearchField from "react-search-field";
import call from "../../Images/call.png";
import { Media } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import qs from "querystring";
import { deliveryRequests } from "../../Redux/actions/tripsAvailable";
import { useHistory } from "react-router-dom";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";

function TCDeliveryRequests() {
  const dispatch = useDispatch();
  const DeliveryRequestsData = useSelector((state) => state.DeliveryRequests);
  const OwnerToken = useSelector((state) => state.OwnerToken);
  console.log(DeliveryRequestsData);
  const allTruckDataSelector = useSelector((state) => state.AllTruckData);
  const history = useHistory();

  const handleClick = () => {
    history.push("/TCdeliverydetails");
  };

  const tripHistoryFunction = () => {
    const requestList = [];
    const listOfTrtips = [];
    if (allTruckDataSelector) {
      for (let truck of allTruckDataSelector) {
        const data = qs.stringify({ truckNo: truck.truck_no });
        console.log(`token = ${OwnerToken}`);
        requestList.push(
          axios(localAxiosToken("/getTripByTruckNo", data, OwnerToken)).then(
            (res) => {
              if (res.data.statusCode === 200) {
                const trips = res.data.message.tripDetails;
                const truck = res.data.message.truckDetails;
                console.log(trips);
                console.log(trips, "trips");
                console.log(truck, "truck");
                if (truck != null && trips != null) {
                  trips.map((data, index) => {
                    data.capacity_inkgs = truck[0].capacity_inkgs;
                    data.capacity_inspace = truck[0].capacity_inspace;
                  });
                  listOfTrtips.push(...trips.reverse());
                }
              }
            }
          )
        );
      }
      Promise.all(requestList).then(() => dispatch(tripHistory(listOfTrtips)));
    } else {
      dispatch(tripHistory(false));
    }
  };

  const currentDate = new Date();

  const params2 = (mappingId) =>
    qs.stringify({
      delivered: false,
      status: "Accepted",
      mappingId,
    });

  const params3 = (mappingId) =>
    qs.stringify({
      delivered: true,
      status: "Accepted",
      mappingId,
    });

  const params4 = (tripId, startDate) =>
    qs.stringify({
      tripId,
      reachDate: JSON.stringify(currentDate),
      startDate,
    });

  const params5 = (mappingId) =>
    qs.stringify({
      delivered: false,
      status: "cancel",
      mappingId,
    });

  const mapPackageToTruck = (mappingId, tripId) => {
    axios(
      localAxiosToken("/updatePackageMapping", params2(mappingId), OwnerToken)
    )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => console.log(error));
    axios(
      localAxiosToken("/getTripPackages", qs.stringify({ tripId }), OwnerToken)
    ).then((res) => {
      if (res.data.statusCode === 200) {
        dispatch(deliveryRequests(res.data.packageDetails));
      }
    });
  };

  const deliveryDone = (deliveryRequestData) => {
    axios(
      localAxiosToken(
        "/updatePackageMapping",
        params3(deliveryRequestData[0].mapping_id),
        OwnerToken
      )
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.statusCode === 200) {
          axios(
            localAxiosToken(
              "/incrementDeliveredPackages",
              qs.stringify({ tripId: deliveryRequestData[0].trip_id }),
              OwnerToken
            )
          )
            .then((res) => {
              console.log(res);
              tripHistoryFunction();
              axios(
                localAxiosToken(
                  "/updateTrip",
                  params4(
                    deliveryRequestData[0].trip_id,
                    deliveryRequestData[1].pickup_date.slice(0, 10)
                  ),
                  OwnerToken
                )
              )
                .then((response) => {
                  console.log(response.data);
                })
                .catch((error) => console.log(error));
              axios(
                localAxiosToken(
                  "/getTripPackages",
                  qs.stringify({ tripId: deliveryRequestData[0].trip_id }),
                  OwnerToken
                )
              )
                .then((res) => {
                  if (res.data.statusCode === 200) {
                    console.log(res.data);
                    dispatch(deliveryRequests(res.data.packageDetails));
                  }
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((error) => console.log(error));
  };

  const cancelFunction = (mappingId, tripId) => {
    axios(
      localAxiosToken("/updatePackageMapping", params5(mappingId), OwnerToken)
    )
      .then((response) => {
        console.log(response.data);
        alert(response.data.message);
      })
      .catch((error) => console.log(error));
    axios(
      localAxiosToken("/getTripPackages", qs.stringify({ tripId }), OwnerToken)
    ).then((res) => {
      if (res.data.statusCode === 200) {
        console.log(res.data.packageDetails);
        dispatch(deliveryRequests(res.data.packageDetails));
      }
    });
  };

  return (
    <div className="tcDeliveryRequests">
      <div className="headerInTcDeliveryRequests">
        <TCHeader />
      </div>
      <div className="mainDivInTcDeliveryRequests">
        <div className="firstDivInTcDeliveryRequestsPage">
          <div className="firstSubDivInDeliveryRequests">
            <ArrowBackIcon
              style={{ color: "#fb6504", fontSize: "50px" }}
              className="float-left  mr-1"
              onClick={handleClick}
            />
            <h4 className="text-left  Requests">
              
              Delivery Requests ({DeliveryRequestsData[0].length})
            </h4>
          </div>
          <div className="searchBoxInTcDeliveryRequests">
            <SearchField
              placeholder="Search with Truck/Location"
              onChange={onChange}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap divForDeliveryRequests">
          {DeliveryRequestsData[0].map((data, index) => (
            <div
              key={index}
              style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
            >
              <div className="trpackagecard">
                <div className="d-flex flex-column">
                  <div className="trpackage">
                    <img
                      src={BOX3}
                      width={30}
                      height={30}
                      className="ml-2 float-left mr-2 mt-2 mb-2"
                      fontSize="large"
                    />
                    <span className="float-left mt-2 package32">
                      Package {DeliveryRequestsData[0].length - index}
                    </span>
                    <span className="float-right mr-2 mt-2">
                      Truck {data[0].truck_no}
                    </span>
                    <img
                      src={Truck}
                      width={30}
                      height={30}
                      className="float-right mt-2 mr-1"
                    />
                  </div>
                  <div className="d-flex flex-row">
                    <div className="d-flex flex-column">
                      <p className="ml-1 mt-2 m-0">Package Weight</p>
                      <p
                        style={{ fontSize: 20, color: "gray" }}
                        className="mr-auto ml-1"
                      >
                        {data[1].package_weight}.KG
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <p className="ml-5 mt-2 m-0">Package Space</p>
                      <p
                        style={{ fontSize: 20, color: "gray" }}
                        className="float-left"
                      >
                        {data[1].package_space}.FT
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <h4 className="ml-4 mt-2 blue">Rs 1050</h4>
                    </div>
                  </div>
                  <Row>
                    <Col className="text-left">
                      <div className="pl-2">
                        <p className="m-0" style={{ fontWeight: "bold" }}>
                          Drop Location
                        </p>
                        <p className="m-0">{data[1].drop_point}</p>
                        <p className="m-0" style={{ fontWeight: "bold" }}>
                          Drop Customer Name
                        </p>
                        <p className="m-0">
                          {data[1].package_receiving_person}
                        </p>
                        <Media className="mt-2">
                          <img
                            src={call}
                            width={20}
                            height={20}
                            className="mt-1 mr-1"
                          />
                          <Media.Body className="mr-3">
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              {data[1].receiving_person_mobile_no}
                            </p>
                          </Media.Body>
                        </Media>
                      </div>
                    </Col>
                    <Col className="text-left">
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Pickup Location
                      </p>
                      <p className="m-0">{data[1].pickup_point}</p>
                      <p className="m-0" style={{ fontWeight: "bold" }}>
                        Pickup Customer Name
                      </p>
                      <p className="m-0">{data[1].customer_name}</p>
                      <div className="mt-2">
                        <img
                          src={call}
                          width={20}
                          height={20}
                          className="mr-1"
                        />
                        <span className="m-0" style={{ fontWeight: "bold" }}>
                          {data[1].customer_mobile_num}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {data[0].delivered === false ? (
                    data[0].status == "cancel" ? (
                      <div style={{ height: "55px" }}></div>
                    ) : data[0].status !== "Accepted" ? (
                      <div
                        className="d-flex ml-2 mr-2 justify-content-between align-items-baseline"
                        style={{ height: "55px" }}
                      >
                        <p
                          style={{
                            color: "darkorange",
                            textDecoration: "none",
                            fontWeight: "600",
                          }}
                          onClick={() =>
                            cancelFunction(data[0].mapping_id, data[0].trip_id)
                          }
                        >
                          Cancel Delivery
                          <span>
                            <DeleteIcon
                              style={{ color: "#fb6504", fontSize: "30px" }}
                            />
                          </span>
                        </p>
                        <input
                          type="submit"
                          value="Accept Delivery"
                          className="mt-2  mb-3"
                          onClick={() =>
                            mapPackageToTruck(
                              data[0].mapping_id,
                              data[0].trip_id
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div
                        className="d-flex ml-2 mr-2 justify-content-between align-items-baseline"
                        style={{ height: "55px" }}
                      >
                        <input
                          type="submit"
                          value="Delivery Done"
                          className="mt-2  mb-3"
                          onClick={() => deliveryDone(data)}
                        />
                        <p
                          style={{
                            color: "darkorange",
                            textDecoration: "none",
                            fontWeight: "600",
                          }}
                          onClick={() =>
                            cancelFunction(data[0].mapping_id, data[0].trip_id)
                          }
                        >
                          Cancel Delivery
                          <span>
                            <DeleteIcon
                              style={{ color: "#fb6504", fontSize: "30px" }}
                            />
                          </span>
                        </p>
                      </div>
                    )
                  ) : (
                    <div style={{ height: "55px" }}>
                      <p
                        style={{
                          color: "darkorange",
                          fontWeight: "bold",
                          marginTop: "4%",
                        }}
                      >
                        package Delivered
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TCDeliveryRequests;