import React from "react";
import { Col, Row } from "react-bootstrap";
import TCHeader from "../TcHeader/TCHeader";
import "./TCDeliverychecklist.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import blackcall from "../../Images/blackcall.png";
import { Media } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function TCDeliverychecklist() {
  const history = useHistory();
  const handleClick = () => history.push("/TCdeliverydetails");
  const deleveryRequestSelector = useSelector(
    (state) => state.DeliveryRequests
  );
  console.log(deleveryRequestSelector[0]);
  let dropDone = 0;

  deleveryRequestSelector[0].map((data) => {
    if (data[0].delivered === true) {
      dropDone++;
    }
  });

  return (
    <div>
      <TCHeader />
      <Row>
        <Col className="col-4">
          <ArrowBackIcon
            style={{ color: "#black", fontSize: "30px" }}
            className="float-left mt-3 mr-1 ml-2"
            onClick={handleClick}
          />
          <h4 className="text-left mt-3 tcchecklist"> Delivery Check List </h4>
        </Col>
        <Col className="col-5 tcdeliverychecklist">
          <Row className="mt-3">
            <Col className="col-4">
              <FiberManualRecordIcon
                style={{ color: "red", fontSize: "15px" }}
                className=" mr-1 "
              />
              <span className="font-weight-bold">Need to Deliver</span>
            </Col>
            <Col className="col-4">
              <FiberManualRecordIcon
                style={{ color: "green", fontSize: "15px" }}
                className=" mr-1 "
              />
              <span className="font-weight-bold">Delivered</span>
            </Col>
            <Col className="col-4">
              <span className="font-weight-bold">
                Drop Done - <span style={{ color: "green" }}>{dropDone}</span>
              </span>
            </Col>
          </Row>
          <div className="m-5">
            <Row className="mt-3 text-left">
              <Col className="col-4">
                <p className="font-weight-bold">Names</p>
              </Col>
              <Col className="col-5">
                <p className="font-weight-bold">Location</p>
              </Col>
              <Col className="col-3">
                <p className="font-weight-bold">Status</p>
              </Col>
            </Row>
            {deleveryRequestSelector[0].length ? (
              deleveryRequestSelector[0].map((object, index) => (
                <div key={index}>
                  <Row className="mt-3 tcchecklistborder">
                    <Col className="col-4">
                      <Media>
                        <img src={blackcall} className="mr-2" />
                        <Media.Body>
                          <p>{object[1].package_receiving_person}</p>
                        </Media.Body>
                      </Media>
                    </Col>
                    <Col className="col-5">
                      <p className="m-0">{object[1].pickup_point}</p>
                      <p className="m-0">{object[1].drop_point}</p>
                    </Col>
                    <Col className="col-3">
                      {object[0].delivered == true ? (
                        <FiberManualRecordIcon
                          style={{ color: "green", fontSize: "15px" }}
                        />
                      ) : (
                        <FiberManualRecordIcon
                          style={{ color: "red", fontSize: "15px" }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <div>
                <p>No packages in the trip.</p>
              </div>
            )}
          </div>
        </Col>
        <Col className="col-3">
          <h4 style={{ color: "darkorange" }} className="mt-3">
            Total -{deleveryRequestSelector[0].length}
          </h4>
        </Col>
      </Row>
    </div>
  );
}

export default TCDeliverychecklist;