import React, { useState } from "react";
import TOHeader from "../ToHeader/TOHeader";
import { useHistory } from "react-router-dom";
import "./addtrip.css";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Modal from "react-modal";
import "./Transportmodal1.css";
import CloseIcon from "@material-ui/icons/Close";
import validator from "validator";
import { useSelector, useDispatch } from "react-redux";
import { localAxiosToken } from "../../Axios/axios";
import qs from "querystring";
import axios from "axios";
import { Col, Row, Button } from "react-bootstrap";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";
import { useMediaQuery } from "react-responsive";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Addtrip() {
  const history = useHistory();
  const tripDetailsFunction = () => history.push("/Tripdetails");

  const OwnerFullName = useSelector((state) => state.OwnerFullName);
  const TruckNo = useSelector((state) => state.TruckNo);
  const PickUpDate = useSelector((state) => state.PickUpDate);
  const PickUpSelector = useSelector((state) => state.PickUp);
  const Drop = useSelector((state) => state.Drop);
  const OwnerToken = useSelector((state) => state.OwnerToken);
  const dispatch = useDispatch();

  const [pickUpPoint, setPickUpPoint] = useState("");
  const [dropPoint, setDropPoint] = useState("");
  const [date, setDate] = useState("");
  const indian_cities = useSelector((state) => state.CitiesList);
            console.log(indian_cities,'-----citiess-------------');

  console.log(OwnerFullName);

  const [errorMessage, setErrorMessage] = useState("");

  const TripHistorySelector = useSelector((state) => state.TripHistory);

  const validateDate = (value) => {
    if (validator.isDate(value)) {
      setErrorMessage("");
      setDate(value);
    } else {
      setErrorMessage("Enter Valid Date!");
    }
  };

  const isMobile = useMediaQuery({
    query: "(max-device-width: 800px)",
  });

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const AddingTrip = () => {
    console.log(OwnerFullName);
    console.log(TruckNo);
    console.log(PickUpDate);
    console.log(PickUpSelector);
    console.log(Drop);
    console.log(OwnerToken);
    const formData = `${qs.stringify({
      source: pickUpPoint,
      destination: dropPoint,
      truckNo: TruckNo,
    })}&startDate=${date}`;
    if(pickUpPoint != dropPoint)
    axios(localAxiosToken("/addTrip", formData, OwnerToken))
      .then((res) => {
        console.log(res.data.details[0]);
        console.log(res.data);
        if (res.data.statusCode === 400) {
          alert(res.data.message);
        }
        if (TripHistorySelector === 0) {
          const tripDetails2 = [res.data.details[0]];
          dispatch(tripHistory(tripDetails2));
        } else {
          const tripDetails = [...TripHistorySelector, res.data.details[0]];
          console.log(TripHistorySelector.length);
          dispatch(tripHistory(tripDetails));
        }
        console.log(`trip history = ${TripHistorySelector}`);
        history.push("/Tripdetails");
      })
      .catch((err) => console.log(err));
  };

  console.log(TripHistorySelector.length);

  return (
    <div className="trip">
      <TOHeader />
      <div className="text-center pt-4">
        <h4 className="mt-2">
          Welcome,<span className="font-weight-bold"> {OwnerFullName} !</span>
        </h4>
      </div>
      <div className="tripbox1">
        {isMobile ? (
          <IconButton
            aria-label="add"
            onClick={openModal}
            className="trip-button"
          >
            <AddIcon style={{ color: "black", fontSize: "50px" }} />
          </IconButton>
        ) : (
          <IconButton
            aria-label="add"
            onClick={openModal}
            className="trip-button"
          >
            <AddIcon style={{ color: "black", fontSize: "100px" }} />
          </IconButton>
        )}
        <h2 onClick={openModal}>Add delivery Trip</h2>
        {TripHistorySelector.length === 0 ? (
          <> </>
        ) : (
          <button onClick={tripDetailsFunction}>Trips</button>
        )}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="Modaltransport1"
        >
          <Row>
            <h3 className="mt-2 ml-2">Add Trip</h3>
            <IconButton
              aria-label="close"
              onClick={closeModal}
              className="ml-auto"
            >
              <CloseIcon style={{ color: "white" }} fontSize="large" />
            </IconButton>
          </Row>
          <form>
            <p className="mt-3 text-center">Add Trip Details</p>
            <Row>
              <Col>
                <select
                  name="city"
                  className="endpoint1 mt-3"
                  onChange={(e) => setPickUpPoint(e.target.value)}
                >
                  <option default>Trip Start Point</option>
                   {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
               
                </select>
                <input
                    min={new Date().toISOString().split('T')[0]} // Set the min attribute to the current date

                  type="date"
                  className="mt-3"
                  onChange={(e) => validateDate(e.target.value)}
                />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {errorMessage}
                </span>
              </Col>
              <Col>
                <select
                  name="city"
                  className="endpoint1 mt-3"
                  onChange={(e) => setDropPoint(e.target.value)}
                >
                  <option default>Trip End Point</option>
                  {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
                </select>
                <input type="time" className="mt-3" />
              </Col>
            </Row>
            <center>
              <Button className="mt-3" variant="dark" onClick={AddingTrip}>
                Add Trip
              </Button>
            </center>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default Addtrip;