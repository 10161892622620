export const OwnerFullName = (state = "", action) => {
  switch (action.type) {
    case "FullName":
      state = action.data;

      return state;
    default:
      return state;
  }
};

export const OwnerMobileNum = (state = "", action) => {
  switch (action.type) {
    case "MobileNum2":
      state = action.data;

      return state;
    default:
      return state;
  }
};

export const OwnerRegisterMobileNum = (state = "", action) => {
  switch (action.type) {
    case "MobileNum2":
      state = action.data;

      return state;
    default:
      return state;
  }
};

export const OwnerOtp = (state = "", action) => {
  switch (action.type) {
    case "OTP2":
      state = action.data;

      return state;
    default:
      return state;
  }
};

export const OwnerToken = (state = "", action) => {
  switch (action.type) {
    case "Token2":
      state = action.data;

      return state;
    default:
      return state;
  }
};

export const OwnerCheckList = (state = "", action) => {
  switch (action.type) {
    case "CheckList":
      state = action.data;

      return state;
    default:
      return state;
  }
};

export const OwnerRating = (state = 0, action) => {
  switch (action.type) {
    case "Rating":
      state = state.data;
      return state;

    default:
      state = 0;
      return state;
  }
};