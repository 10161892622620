import React from "react";
import Header from "../PoHeader/Header";
import "./Map.css";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import blackcall from "../../Images/blackcall.png";

function Map() {
  const history = useHistory();
  const handleClick = () => history.push("/deliverybooked");

  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 20.5937,
    lng: 78.9629,
  };

  return (
    <div className="map" data-component="map">
      <Header />
      <LoadScript googleMapsApiKey="AIzaSyA4QqiMxUNpXNs2UkL6VsDK196sIB2TlYU">
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={8}
          center={defaultCenter}
        />
      </LoadScript>
      <div className="footer">
        <button>
          
          <img
            src={blackcall}
            className="mr-2"
            alt=" "
            width={30}
            height={30}
          />
          Call Delivery Partner
        </button>
        <input
          type="submit"
          name=""
          value="DONE"
          className="float-right"
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default Map;