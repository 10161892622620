import React from "react";
import { useDispatch } from "react-redux";
import "./PackageOwnerAddPackage.css";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Header from "../PoHeader/Header";
import { useSelector } from "react-redux";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import { useMediaQuery } from "react-responsive";
import { CitiesList } from "../../Redux/reducers/customerInfo";

function PackageOwnerAddPackage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const citiesArray = [];
  const customerFullName = useSelector(
    (state) => `${state.CustomerFirstName} ${state.CustomerLastName}`
  );
  const customerLastNameSelector = useSelector(
    (state) => state.CustomerLastName
  );
  console.log(customerLastNameSelector);
  const detailsPageFunction = async () => { 
       try {
      axios(localAxiosToken("/getListOfCities"))
        .then((res) => {
          const cities = res.data.data;
          if (res.data.statusCode === 200) {
           const cityNames = cities.map((city) => city.city_name);
            citiesArray.push(...cityNames);
            // console.log(citiesArray, "CITIIIES")
            const uniqueCities = [...new Set(citiesArray)];
            dispatch({ type: "SET_CITIES_LIST", payload: uniqueCities });
            if (citiesArray.length > 0) {
              history.push("/details") 
            }
          }
          else {
            console.log(res.data);
            alert(res.data.message);
          }
        })
        .catch((err) => console.log(err))
    } catch (err) {
      console.log(err,"Error");
    }
  };

  const isMobile = useMediaQuery({
    query: "(max-device-width: 800px)",
  });

  return (
    <div className="large">
      <div className="headerPartInAddPackage">
        <Header />
      </div>
      <div className="largeBox">
        <h1 className="customerNameText">
          Welcome,
          <span className="customerNameSpan">
            {`${" "} ${customerFullName} ${" "}!`}
          </span>

          <p
          className="anotherPackageText"
                  onClick={() => history.push("/deliverybooked")}
        >
          Your Previous Packages 
        </p>
        </h1>
        {isMobile ? (
          <IconButton aria-label="add" onClick={detailsPageFunction}>
            <AddIcon
              style={{ color: "black", fontSize: "50px", fontWeight: "700" }}
              className="iconButton"
            />
          </IconButton>
        ) : (
          <IconButton aria-label="add" onClick={detailsPageFunction}>
            <AddIcon
              style={{ color: "black", fontSize: "100px", fontWeight: "700" }}
              className="iconButton"
            />
          </IconButton>
        )}
        <h2 className="deliveryPackageText">Deliver a Package </h2>
        <p
          className="anotherPackageText"
          onClick={detailsPageFunction}
        >
          Want to Deliver a Package ?
        </p>
      </div>
    </div>
  );
}

export default PackageOwnerAddPackage;