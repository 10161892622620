import React from "react";
import TCHeader from "../TcHeader/TCHeader";
import "./TCdeletetruck.css";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Scrollbars } from "react-custom-scrollbars";
import { allTruckData } from "../../Redux/actions/transportCompanyInfo";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import { useSelector, useDispatch } from "react-redux";
import qs from "querystring";
import { useHistory } from "react-router-dom";

function TCdeletetruck() {
  const history = useHistory();
  const dispatch = useDispatch();
  const OwnerToken = useSelector((state) => state.OwnerToken);
  const allTruckDetails = useSelector((state) => state.AllTruckData);

  const deleteFunction = (truckData) => {
    console.log(truckData);
    axios(
      localAxiosToken(
        "/deleteTruck",
        qs.stringify({ truckNo: truckData.truck_no }),
        OwnerToken
      )
    )
      .then((res) => {
        console.log(res);

        if (res.data.statusCode === 200) {
          axios(
            localAxiosToken(
              "/decrementNoOfTrucks",
              qs.stringify({
                transportCompanyMoibileNum:
                  truckData.transport_company_mobile_num,
              }),
              OwnerToken
            )
          )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          axios(
            localAxiosToken(
              "/getCompanyTrucks",
              qs.stringify({
                mobileNum: truckData.transport_company_mobile_num,
              }),
              OwnerToken
            )
          )
            .then((res) => {
              console.log(res.data.truckDetails);
              if (res.data.statusCode === 200) {
                dispatch(allTruckData(res.data.truckDetails));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    history.push("/TCdeliverydetails");
  };

  return (
    <div className="tcDeleteTruckPage">
      <div className="headerInDeletePage">
        <TCHeader />
      </div>
      <div className="mainDivInDeletePage">
        <div className="deletetruck ">
          <Scrollbars style={{ width: 350, height: 470 }}>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <h4 className="mr-auto">Delete The Truck</h4>
                <IconButton aria-label="close" className="float-right ml-auto">
                  <div className="cross">
                    <CloseIcon style={{ color: "black" }} fontSize="large" />
                  </div>
                </IconButton>
              </div>
              {allTruckDetails.map((data, index) => (
                <div
                  className="d-flex flex-row dashed justify-content-between"
                  key={index}
                >
                  <div className="tc1">
                    <FiberManualRecordIcon
                      style={{ color: "black", fontSize: "20px" }}
                      className=" mr-3"
                    />
                    <span className="font-weight-bold ml-4">
                      Truck {index + 1}({data.truck_no})
                    </span>
                  </div>
                  <div>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        deleteFunction(data);
                      }}
                    >
                      <DeleteIcon style={{ color: "black" }} className="dell" />
                    </IconButton>
                  </div>
                </div>
              ))}
              <div className="closeButton">
                <input
                  type="submit"
                  name=""
                  value="CLOSE"
                  className="font-weight-bold "
                  onClick={onSubmit}
                />
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
}

export default TCdeletetruck;