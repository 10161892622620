import * as AiIcons from "react-icons/ai";

export const TraSidebarData = [
  {
    title: "Close",
    icon: <AiIcons.AiOutlineClose color="white" />,
    cName: "navTextInTransport",
  },
  {
    title: "Add New Truck",
    path: "/TCaddnewtruck",
    cName: "navTextInTransport",
  },
  {
    title: "Delete The Truck",
    path: "/TCdeletethetruck",
    cName: "navTextInTransport",
  },
  {
    title: "Pending Delivery Request",
    // path: "/#",
    cName: "navTextInTransport",
  },
  {
    title: "Total Trips",
    path: "/TCdeliverydetails",
    cName: "navTextInTransport",
  },
  {
    title: "Notifications",
    // path: "/#",
    cName: "navTextInTransport",
  },
  {
    title: "Ultimate Delivery App Share",
    // path: "/#",
    cName: "navTextInTransport",
  },
  {
    title: "Feedback",
    // path: "/#",
    cName: "navTextInTransport",
  },
  {
    title: "Privacy Policy",
    // path: "/#",
    cName: "navTextInTransport",
  },
  {
    title: "Contact Us",
    path: "/#",
    cName: "navTextInTransport",
  },
  {
    title: "Sign Out",
    path: "/home",
    cName: "navTextInTransport",
  },
];