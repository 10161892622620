import React, { useState } from "react";
import TCHeader from "../TcHeader/TCHeader";
import "./TCadddeliveries.css";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import BOX3 from "../../Images/BOX3.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  pickUp,
  drop,
  packageWeight,
  packageSpace,
  receivingPersonName,
  receivingPersonNum,
  dropPersonName,
  dropPersonNum,
} from "../../Redux/actions/packageDetails";
import { useDispatch, useSelector } from "react-redux";
import qs from "querystring";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import { useHistory } from "react-router-dom";
import { deliveryRequests } from "../../Redux/actions/tripsAvailable";
import { tripHistory } from "../../Redux/actions/ownerTruckInfo";

function TCadddeliveries() {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = () => history.push("/TCdeliverydetails");
  const [weight, setWeight] = useState("");
  const [space, setSpace] = useState("");
  const [dropPoint, setDropPoint] = useState("");
  const [pickupPoint, setPickUpPoint] = useState("");
  const [dropCustomer, setDropCustomer] = useState("");
  const [pickUpCustomer, setPickUpCustomer] = useState("");
  const [dropMobileNum, setDropMobileNum] = useState("");
  const [pickUpMobileNum, setPickUpMobileNum] = useState("");
  const entireTruck = false;
  const OwnerToken = useSelector((state) => state.OwnerToken);
  const TripDetails = useSelector((state) => state.TripDetails);
  const allTruckDataSelector = useSelector((state) => state.AllTruckData);
  console.log(TripDetails);
  const availableWeight =
    TripDetails.capacity_inkgs - TripDetails.booked_weight;
  const availableSpace =
    TripDetails.capacity_inspace - TripDetails.booked_space;
  const [PackageSpaceErr, setPackageSpaceErr] = useState({});
  const [PackageWeightErr, setPackageWeightErr] = useState({});
  const [DropCustomerNameErr, setDropCustomerNameErr] = useState({});
  const [PickupCustomerNameErr, setPickupCustomerNameErr] = useState({});
  const [dMobileNumberErr, setDMobileNumberErr] = useState({});
  const [pMobileNumberErr, setPMobileNumberErr] = useState({});
  const indian_cities = useSelector((state) => state.CitiesList);


  const params = `${qs.stringify({
    pickUpPoint: pickupPoint,
    dropPoint: dropPoint,
    entireTruck: entireTruck,
    receivingPersonName: pickUpCustomer,
    receivingPersonNo: pickUpMobileNum,
    packageSpace: space,
    packageWeight: weight,
    customerName: dropCustomer,
    mobileNum: dropMobileNum,
    tripId: TripDetails.trip_id,
  })}&date=${TripDetails.start_date.slice(0, 10)}`;

  const params2 = (packageId) =>
    `${qs.stringify({
      truckNo: TripDetails.truck_no,
      tripId: TripDetails.trip_id,
      packageId,
      status: "Accepted",
    })}&date=${TripDetails.start_date.slice(0, 10)}`;

  const tripHistoryFunction = () => {
    const requestList = [];
    const listOfTrtips = [];
    if (allTruckDataSelector) {
      for (let truck of allTruckDataSelector) {
        const data = qs.stringify({ truckNo: truck.truck_no });
        console.log(`token = ${OwnerToken}`);
        requestList.push(
          axios(localAxiosToken("/getTripByTruckNo", data, OwnerToken)).then(
            (res) => {
              if (res.data.statusCode === 200) {
                const trips = res.data.message.tripDetails;
                const truck = res.data.message.truckDetails;
                console.log(trips);
                console.log(trips, "trips");
                console.log(truck, "truck");
                if (truck != null && trips != null) {
                  trips.map((data) => {
                    data.capacity_inkgs = truck[0].capacity_inkgs;
                    data.capacity_inspace = truck[0].capacity_inspace;
                  });
                  listOfTrtips.push(...trips.reverse());
                }
              }
            }
          )
        );
      }
      Promise.all(requestList).then(() => dispatch(tripHistory(listOfTrtips)));
    } else {
      dispatch(tripHistory(false));
    }
  };

  const onSubmit = () => {
    const isValid = formValidation();
    if (isValid) {
      dispatch(pickUp(pickupPoint));
      dispatch(drop(dropPoint));
      dispatch(packageWeight(weight));
      dispatch(packageSpace(space));
      dispatch(receivingPersonName(pickUpCustomer));
      dispatch(receivingPersonNum(pickUpMobileNum));
      dispatch(dropPersonName(dropCustomer));
      dispatch(dropPersonNum(dropMobileNum));
      axios(localAxiosToken("/addPackage", params, OwnerToken))
        .then((res) => {
          console.log(res.data);
          if (res.data.statusCode === 201) {
            console.log(params);
            axios(
              localAxiosToken(
                "/assignPackage",
                params2(res.data.details[0].package_id),
                OwnerToken
              )
            )
              .then((response) => {
                console.log(response.data);
                axios(
                  localAxiosToken(
                    "/getTripPackages",
                    qs.stringify({ tripId: TripDetails.trip_id }),
                    OwnerToken
                  )
                )
                  .then((res) => {
                    console.log(res);
                    if (res.data.statusCode === 200) {
                      dispatch(deliveryRequests(res.data.packageDetails));
                      tripHistoryFunction();
                      history.push("/TCdeliveryrequests");
                    }
                  })
                  .catch(() => {
                    console.log(res);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      const requestList = [];
      const listOfTrtips = [];
      if (allTruckDataSelector) {
        for (let truck of allTruckDataSelector) {
          const data = qs.stringify({ truckNo: truck.truck_no });
          console.log(`token = ${OwnerToken}`);
          requestList.push(
            axios(localAxiosToken("/getTripByTruckNo", data, OwnerToken)).then(
              (res) => {
                if (res.data.statusCode === 200) {
                  const trips = res.data.message.tripDetails;
                  const truck = res.data.message.truckDetails;
                  console.log(trips);
                  console.log(trips, "trips");
                  console.log(truck, "truck");
                  trips.map((data) => {
                    data.capacity_inkgs = truck[0].capacity_inkgs;
                    data.capacity_inspace = truck[0].capacity_inspace;
                  });
                  listOfTrtips.push(...trips.reverse());
                }
              }
            )
          );
        }
        Promise.all(requestList).then(() =>
          dispatch(tripHistory(listOfTrtips))
        );
      } else {
        dispatch(tripHistory(false));
      }
    }
  };

  const formValidation = () => {
    var specialCharacterPattern3 = /[^a-zA-Z ]+/;
    var specialCharacterForMobileInTc = /[^0-9]+/;
    const dMobileNumberErr = {};
    const pMobileNumberErr = {};
    const PackageSpaceErr = {};
    const PackageWeightErr = {};
    const PickupCustomerNameErr = {};
    const DropCustomerNameErr = {};
    let isValid = true;
    if (weight.length === 0) {
      PackageWeightErr.PackageWeightEmpty = "package Weight should be required";
      isValid = false;
    } else if (specialCharacterForMobileInTc.test(weight)) {
      PackageWeightErr.PackageWeightInCharacters =
        "should not include special characters ";
      isValid = false;
    } else if (weight > availableWeight) {
      PackageWeightErr.PackageWeightGreater = `${"Available Weight In This trip"} ${availableWeight}`;
      isValid = false;
    } else {
    }
    if (space.length === 0) {
      PackageSpaceErr.PackageSpaceEmpty = "package Space should be required";
      isValid = false;
    } else if (specialCharacterForMobileInTc.test(space)) {
      PackageSpaceErr.PackageSpaceEmpty =
        "should not include special characters ";
      isValid = false;
    } else if (space > availableSpace) {
      PackageSpaceErr.PackageSpaceGreater = `${"Available Space In This trip"} ${availableSpace}`;
      isValid = false;
    } else {
    }
    if (dropCustomer.length === 0) {
      DropCustomerNameErr.DropCustomerNameEmpty =
        "dropCustomer Name should be required ";
      isValid = false;
    } else if (specialCharacterPattern3.test(dropCustomer)) {
      DropCustomerNameErr.DropCustomerNameSpecial =
        "should not include special characters ";
      isValid = false;
    } else {
    }
    if (pickUpCustomer.length === 0) {
      PickupCustomerNameErr.PickupCustomerNameEmpty =
        "pickUpCustomer Name should be required ";
      isValid = false;
    } else if (specialCharacterPattern3.test(pickUpCustomer)) {
      PickupCustomerNameErr.PickupCustomerNameSpecial =
        "should not include special characters ";
      isValid = false;
    } else {
    }
    if (dropMobileNum.length === 0) {
      dMobileNumberErr.MobileNumberEmpty =
        "Drop Mobile Number should be required";
      isValid = false;
    } else if (specialCharacterForMobileInTc.test(dropMobileNum)) {
      dMobileNumberErr.MobileNumberChar =
        "should not include special characters";
      isValid = false;
    } else if (dropMobileNum.trim().length > 10) {
      dMobileNumberErr.MobileNumberLong = "Mobile number should be 10 Digits";
      isValid = false;
    } else if (dropMobileNum.trim().length < 10) {
      dMobileNumberErr.MobileNumberShort = "Mobile number should be 10 Digits";
      isValid = false;
    } else {
    }
    if (pickUpMobileNum.length === 0) {
      pMobileNumberErr.MobileNumberEmpty =
        "PickUp Mobile Number should be required";
      isValid = false;
    } else if (specialCharacterForMobileInTc.test(pickUpMobileNum)) {
      pMobileNumberErr.MobileNumberChar =
        "should not include special characters";
      isValid = false;
    } else if (pickUpMobileNum.trim().length > 10) {
      pMobileNumberErr.MobileNumberLong = "Mobile number should be 10 Digits";
      isValid = false;
    } else if (pickUpMobileNum.trim().length < 10) {
      pMobileNumberErr.MobileNumberShort = "Mobile number should be 10 Digits";
      isValid = false;
    } else {
    }
    setPickupCustomerNameErr(PickupCustomerNameErr);
    setDropCustomerNameErr(DropCustomerNameErr);
    setDMobileNumberErr(dMobileNumberErr);
    setPMobileNumberErr(pMobileNumberErr);
    setPackageWeightErr(PackageWeightErr);
    setPackageSpaceErr(PackageSpaceErr);
    return isValid;
  };

  function onChangetextremovevalidationmessage() {
    setDropCustomerNameErr("");
    setPickupCustomerNameErr("");
    setDMobileNumberErr("");
    setPMobileNumberErr("");
    setPackageWeightErr("");
    setPackageSpaceErr("");
  }

  return (
    <div className="tcAddDelivery">
      <div className="headerInAddDelivery">
        <TCHeader />
      </div>
      <div className="mainDivInAddDelivery">
        <div className="addDeliveryTitle">
          <ArrowBackIcon
            style={{ color: "#fb6504", fontSize: "30px" }}
            className="float-left ml-2 mr-1"
            onClick={handleClick}
          />
          <h4 className="text-left mt-4 adddeliveries"> 
            Add Deliveries (Truck-{TripDetails.truck_no} Trip-
            {TripDetails.trip_id} {TripDetails.start_date.slice(0, 10)})
          </h4>
        </div>
        <div className="packageCardInDeliveryRequests">
          <div className="trrpackage ">
            <img
              src={BOX3}
              width={30}
              height={30}
              className="ml-2 float-left mr-2 mt-2 mb-2 "
              fontSize="large"
            />
            <span className="float-left mt-2 package43 ">
              Package {Number(TripDetails.total_packages) + 1}
            </span>
          </div>
          <div style={{ marginLeft: "2%", marginRight: "2%" }}>
            <div
              className="mt-3 d-flex "
              style={{ textAlign: "left", height: "60px" }}
            >
              <div style={{ width: "50%" }}>
                <p className="m-0 weight">Package Weight (KG)</p>
                <input
                  type="text"
                  onChange={(e) => {
                    setWeight(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(PackageWeightErr).map((key) => {
                  return (
                    <div className="errorDicInAddDeliveries">
                      {PackageWeightErr[key]}
                    </div>
                  );
                })}
              </div>
              <div style={{ width: "50%" }}>
                <p className="m-0 weight">Package Space (FT)</p>
                <input
                  type="text"
                  onChange={(e) => {
                    setSpace(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(PackageSpaceErr).map((key) => {
                  return (
                    <div className="errorDicInAddDeliveries">
                      {PackageSpaceErr[key]}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-left mt-3">
              <select
                name="city"
                className="enndpoint "
                onChange={(e) => {
                  setDropPoint(e.target.value);
                }}
              >
                <option default>Delivery Drop Point</option>
                {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
              </select>
            </div>
            <div
              className="mt-3 d-flex"
              style={{ textAlign: "left", height: "60px" }}
            >
              <div style={{ width: "50%" }}>
                <p className="float-left m-0">Drop Customer Name</p>
                <input
                  type="text"
                  onChange={(e) => {
                    setDropCustomer(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(DropCustomerNameErr).map((key) => {
                  return (
                    <div className="errorDicInAddDeliveries">
                      {DropCustomerNameErr[key]}
                    </div>
                  );
                })}
              </div>
              <div style={{ width: "50%" }}>
                <p className=" m-0">Drop Customer Number</p>
                <input
                  type="text"
                  onChange={(e) => {
                    setDropMobileNum(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(dMobileNumberErr).map((key) => {
                  return (
                    <div className="errorDicInAddDeliveries">
                      {dMobileNumberErr[key]}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-left mt-3">
              <select
                name="city"
                className="enndpoint"
                onChange={(e) => {
                  setPickUpPoint(e.target.value);
                }}
              >
                <option default>Delivery Pickup Point</option>
                 {indian_cities.map((city) => 
                      <option key={city} >{city}</option>
                    )}
              
              </select>
            </div>
            <div
              className="mt-2 d-flex "
              style={{ textAlign: "left", height: "60px" }}
            >
              <div style={{ width: "50%" }}>
                <p className=" m-0">Pickup Customer Name</p>
                <input
                  type="text"
                  onChange={(e) => {
                    setPickUpCustomer(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(PickupCustomerNameErr).map((key) => {
                  return (
                    <div className="errorDicInAddDeliveries">
                      {PickupCustomerNameErr[key]}
                    </div>
                  );
                })}
              </div>
              <div style={{ width: "50%" }}>
                <p className=" m-0">PickUp Customer Number</p>
                <input
                  type="text"
                  className="inputWidth"
                  onChange={(e) => {
                    setPickUpMobileNum(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                {Object.keys(pMobileNumberErr).map((key) => {
                  return (
                    <div className="errorDicInAddDeliveries">
                      {pMobileNumberErr[key]}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-1 mb-1 d-flex  justify-content-between">
              <div style={{ width: "50%", textAlign: "initial" }}>
                <p
                  style={{
                    color: "darkorange",
                    textDecoration: "none",
                    fontWeight: "600",
                  }}
                  className=" mt-2"
                  onClick={() => history.push("/TCdeliverydetails")}
                >
                  Cancel Delivery
                  <span>
                    <DeleteIcon
                      style={{ color: "#fb6504", fontSize: "30px" }}
                    />
                  </span>
                </p>
              </div>
              <div className="accept ">
                <input
                  type="submit"
                  value="Accept Delivery"
                  className="mt-2 "
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="justify-content-between">
          <p
            style={{
              color: "#fb6504",
              textDecoration: "none",
              fontWeight: "650",
            }}
            onClick={() => {
              history.push("/TCadddeliveries");
            }}
            className=" another "
          >
            Add another Delivery Request
            <AddIcon
              style={{ color: "#fb6504", fontSize: "20px" }}
              className="ml-2"
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default TCadddeliveries;