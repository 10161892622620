import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import qs from "querystring";
import { useDispatch } from "react-redux";
import {
  customerFirstName,
  customerLastName,
  customerMobileNum,
  customerOtp,  
  customerToken,
} from "../../Redux/actions/customerInfo";
import axios from "axios";
import { localAxios } from "../../Axios/axios";
import Arrow from "../../Images/Arrow.png";
import "./PORegister.css";

function PORegister() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [firstNameErr, setFirstNameErr] = useState({});
  const [lastNameErr, setLastNameErr] = useState({});
  const [mobileNumberErr, setMobileNumberErr] = useState({});
  let error;
  const LoginFunction = () => history.push("/Login");

  const onSubmit = async () => {
    dispatch(customerFirstName(firstName));
    dispatch(customerLastName(lastName));
    dispatch(customerMobileNum(mobileNumber));
    const isValid = formValidation();
    if (isValid) {
      try {
        await axios(
          localAxios(
            "/customerRegister",
            qs.stringify({
              firstName: firstName,
              lastName: lastName,
              mobileNum: mobileNumber,
            })
          )
        )
          .then((response) => {
            if (response.data.statusCode === 200) {
              console.log(response.data);
              dispatch(customerOtp(response.data.otp));
              console.log(response.data.otp);
              dispatch(customerToken(response.data.token));
              console.log(response.data.token);
              history.push("/otp");
            }
          })
          .catch((err) => {
            console.log(err);
            error = err.response.data.statusCode;
            console.log(error);
            if (error === 400) {
              setErrorMessage("This Mobile Number is already registered");
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  function formValidation() {
    const firstNameErr = {};
    const lastNameErr = {};
    const mobileNumberErr = {};
    var specialCharacterPattern = /[^a-zA-Z ]+/;
    var specialCharacterForMobile = /[^0-9]+/;
    let validFirstName = true;
    let validLastName = true;
    let validMobileNum = true;
    let isValid = false;
    if (firstName.length === 0) {
      firstNameErr.firstNameEmpty = "First Name should be required";
      validFirstName = false;
    } else if (specialCharacterPattern.test(firstName)) {
      firstNameErr.firstNameSpecial =
        "should not include special characters and numbers";
      console.log("text");
      validFirstName = false;
    } else {
    }
    if (lastName.length === 0) {
      lastNameErr.lastNameEmpty = "Last Name should be required";
      validLastName = false;
    } else if (specialCharacterPattern.test(lastName)) {
      lastNameErr.lastNameSpecial =
        "should not include special characters and numbers ";
      console.log("text1");
      validLastName = false;
    } else {
    }
    if (mobileNumber.length === 0) {
      mobileNumberErr.mobileNumberEmpty = "Mobile Number should be required";
      validMobileNum = false;
    } else if (specialCharacterForMobile.test(mobileNumber)) {
      mobileNumberErr.mobileNumberHaveCharacters =
        "should not include special characters";
      validMobileNum = false;
    } else if (mobileNumber.trim().length > 10) {
      mobileNumberErr.mobileNumberLong = "Mobile Number should be 10 Digits";
      validMobileNum = false;
    } else if (mobileNumber.trim().length < 10) {
      mobileNumberErr.mobileNumberShort = "Mobile Number should be 10 Digits";
      validMobileNum = false;
    } else {
    }
    setFirstNameErr(firstNameErr);
    setLastNameErr(lastNameErr);
    setMobileNumberErr(mobileNumberErr);
    if (validFirstName && validLastName && validMobileNum) {
      isValid = true;
    }
    return isValid;
  }

  function onChangetextremovevalidationmessage() {
    setFirstNameErr("");
    setMobileNumberErr("");
    setLastNameErr("");
  }

  return (
    <div className="example3">
      <div className="packageRegisterBlock">
        <div className="packageRegisterTitleBlock">
          <pre className="packageRegisterTitle">
            {`Want to Send
(or) Recieve Package?`}
          </pre>
        </div>
        <div className="loginDetailsInPackage">
          <h1>Register</h1>
          <p>
            Already registered? Please
            <span onClick={() => LoginFunction()}> <u>Login</u></span>
          </p>
          <div className="inputsInPackage">
            <input
              type="text"
              name="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                onChangetextremovevalidationmessage();
              }}
            />
            <label
              alt="First Name"
              className={firstName ? "active" : " "}
              htmlFor="First Name"
              placeholder="First Name"
            >
              First Name
            </label>
            {Object.keys(firstNameErr).map((key) => {
              return (
                <div className="colorProperty" key={key}>
                  {firstNameErr[key]}
                </div>
              );
            })}
          </div>
          <div className="inputsInPackage">
            <input
              type="text"
              onChange={(e) => {
                setLastName(e.target.value);
                onChangetextremovevalidationmessage();
              }}
            />
            <label
              alt="Last Name"
              className={lastName ? "active" : " "}
              htmlFor="Last Name"
              placeholder="Last Name"
            >
              Last Name
            </label>
            {Object.keys(lastNameErr).map((key) => {
              return (
                <div className="colorProperty" key={key}>
                  {lastNameErr[key]}
                </div>
              );
            })}
          </div>
          <div className="inputsInPackage">
            <input
              type="text"
              onChange={(e) => {
                setMobileNumber(e.target.value);
                onChangetextremovevalidationmessage();
              }}
            />
            <label
              alt="Mobile number"
              className={mobileNumber ? "active" : " "}
              htmlFor="Mobile Number" 
              placeholder="Mobile Number"
            >
              Mobile Number
            </label>
            {Object.keys(mobileNumberErr).map((key) => {
              return (
                <div className="colorProperty" key={key}>
                  {mobileNumberErr[key]}
                </div>
              );
            })}
          </div>
        </div>
        <div className="packageArrow">
          <img
            src={Arrow}
            onClick={onSubmit}
            className="packageArrowStyle"
          ></img>
        </div>
        {errorMessage ? (
          <div>
            <p className="errorMessageStyle">{errorMessage}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PORegister;