import React, { useState } from "react";
import OTPInput from "otp-input-react";
import "./TCotp.css";
import { useHistory } from "react-router-dom";
import blackarrow from "../../Images/blackarrow.png";
import { useSelector, useDispatch } from "react-redux";
import { localAxios } from "../../Axios/axios";
import axios from "axios";
import qs from "querystring";
import { ownerOtp } from "../../Redux/actions/ownerInfo";
import {
  companyName,
  contactNumber,
  noOfVehicals,
} from "../../Redux/actions/transportCompanyInfo";

function TCotp() {
  const history = useHistory();
  const [OTP, setOTP] = useState("");
  const [errorOTP, setErrorOTP] = useState("");
  const ownerOtpSelector = useSelector((state) => state.OwnerOtp);
  const customerMobileNumSelector = useSelector(
    (state) => state.CustomerMobileNum
  );
  console.log(ownerOtpSelector);
  let logIn = "";
  const dispatch = useDispatch();

  const handleClick = () => {
    if (OTP === ownerOtpSelector) {
      logIn = "true";
      history.push("/TCLogin");
      console.log(logIn);
      dispatch(companyName(""));
      dispatch(contactNumber(""));
      dispatch(noOfVehicals([]));
    } else {
      logIn = "false";
      console.log(logIn);
      setErrorOTP("Invalid otp");
    }
  };

  const ResendCode = async () => {
    setOTP("");
    setErrorOTP("");
    await axios(
      localAxios(
        "/SMSLogin",
        qs.stringify({
          mobileNum: customerMobileNumSelector,
        })
      )
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode === 200) {
          dispatch(ownerOtp(res.data.otp));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form>
      <div className="transportCompanyOtp">
        <div className="transportOtp">
          <pre>
            
            {`Enter the 4-Digit Mobile Verification Code 
  You shall recieve the code to your Number`}
          </pre>
          <h1>Verification Code</h1>
          <div className="otpboxTC">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          {errorOTP && <h5 className="errorTC"> {errorOTP} </h5>}
          <p>Hint:Your OTP is {ownerOtpSelector}</p>
          <h6 onClick={ResendCode}>Resend Verification Code</h6>
          <div className="arrowTCO">
            <img
              src={blackarrow}
              onClick={handleClick}
              className="arrowTCOstyle"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default TCotp;