import React from "react";
import TOHeader from "../ToHeader/TOHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./DeliveryRequests.css";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TOPackageComponent from "../ToPackageComponent/TOPackageComponent";

function DeliveryRequests() {
  const history = useHistory();
  const handleClick = () => history.push("/Tripdetails");
  const DeliveryRequestsSelector = useSelector(
    (state) => state.DeliveryRequests
  );

  console.log(DeliveryRequestsSelector);
  console.log(DeliveryRequestsSelector[0]);
  console.log(DeliveryRequestsSelector[0][0]);

  const TripDetails = useSelector((state) => state.TripDetails);
  
  console.log(TripDetails);
  console.log(TripDetails.startDate);
  console.log(DeliveryRequestsSelector[0].length);

  return (
    <div className="deliveryrequests">
      <TOHeader />
      <ArrowBackIcon
        style={{ color: "black", fontSize: "30px", cursor: "pointer" }}
        className="float-left mt-3 ml-3 mr-3"
        onClick={handleClick}
      />
      <h2 className="text-left mt-3">
        Delivery Requests
        <span className="font-weight-normal">
          (Trip-{TripDetails.tripId} {TripDetails.startDate.slice(0, 10)})
        </span>
      </h2>
      <Row className="m-0 p-0 ml-5">
        {DeliveryRequestsSelector[0].length ? (
          DeliveryRequestsSelector[0].map((object, index) => (
            <TOPackageComponent
              key={index}
              numbers={index}
              DeliveryRequests1={object}
            />
          ))
        ) : (
          <h2 style={{ marginLeft: "40%" }}>No Packages in the trip.</h2>
        )}
      </Row>
    </div>
  );
}

export default DeliveryRequests;