import React, { onChange, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "react-modal";
import { selectedTruck } from "../../Redux/actions/transportCompanyInfo";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import { modalPageOpening } from "../../Redux/actions/transportCompanyInfo";
import "./TcModal.css";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ModalPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const citiesArray = [];
    const [isOptionSelected, setIsOptionSelected] = useState(false); // Initialize state variable


  const modalPageDataSelector = useSelector((state) => state.ModalPageOpening);
  const allTrucksInCompany = useSelector((state) => state.AllTruckData);
  const [modalIsOpen, setIsOpen] = React.useState(true);
  console.log(modalIsOpen);

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
    dispatch(modalPageOpening(false));
  }

  const selectedTruckFunction = (selectedtruckindex) => {
    console.log(selectedtruckindex);
    dispatch(selectedTruck(selectedtruckindex));
        setIsOptionSelected(true); // Update the state when an option is selected

  };

  const handleClick1 = async () => {
    dispatch(modalPageOpening(""));
    if (selectedTruckFunction != null) {
      try {
        console.log("jjjjjjj")
      axios(localAxiosToken("/getListOfCities"))
        .then((res) => {
          const cities = res.data.data;
          if (res.data.statusCode === 200) {
           const cityNames = cities.map((city) => city.city_name);
            citiesArray.push(...cityNames);
            const uniqueCities = [...new Set(citiesArray)];
            dispatch({ type: "SET_CITIES_LIST", payload: uniqueCities });
            if (citiesArray.length > 0) {
    history.push("/TCTripdetails");
            }
          }
          else {
            console.log(res.data);
            alert(res.data.message);
          }
        })
        .catch((err) => console.log(err))
    } catch (err) {
      console.log(err,"Error");
    }
    }
  };

  return (
    <Modal
      isOpen={modalPageDataSelector}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
      className="modalTransport"
    >
      <Row>
        <h3 className="mt-2 ml-2 addTripHeading">Add Trip</h3>
        <IconButton aria-label="close" onClick={closeModal} className="ml-auto">
          <CloseIcon style={{ color: "white" }} fontSize="large" />
        </IconButton>
      </Row>
      <form>
        <p className="ml-5 mt-3">Please Choose the Truck for Trip</p>
        <select
          name="trips"
          className="drop mt-2"
          onChange={(e) => {
            selectedTruckFunction(e.target.value);
          }}
        >
          <option>_____Select Your Truck____</option>
          {allTrucksInCompany.map((data, index) => {
            return (
              <option key={index} value={index}>{`TRUCK ${index + 1} - ${
                data.truck_no
              } `}</option>
            );
          })}
        </select>
          {isOptionSelected && ( // Conditionally render the button
          <input
            type="submit"
            value="Add Trip"
            className="mt-5"
            onClick={handleClick1}
          />
        )}
      </form>
    </Modal>
  );
}

export default ModalPage;