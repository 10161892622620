import React from "react";
import { useHistory } from "react-router-dom";

import TCHeader from "../TcHeader/TCHeader";
import "./TCAddtrip.css";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import "./Transportmodal.css";
import { useSelector, useDispatch } from "react-redux";
import { modalPageOpening } from "../../Redux/actions/transportCompanyInfo";
import ModalPage from "../TcModalPage/TcModal";

function TCAddtrip() {
  const history = useHistory();

  const dispatch = useDispatch();
  const allTrucksInCompany = useSelector((state) => state.AllTruckData);
  const tripHistorySelector = useSelector((state) => state.TripHistory);
  const companyNameSelector = useSelector((state) => state.CompanyName);
  const modalPageDataSelector = useSelector((state) => state.ModalPageOpening);
  console.log(tripHistorySelector);
  console.log(allTrucksInCompany);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    console.log(modalIsOpen);
    dispatch(modalPageOpening("true"));
  }

  return (
    <div className="transportAddTrip">
      <div className="headerInAddTrip">
        <TCHeader />
      </div>
      <div className="mainDivInAddTrip">
        <div className="text-center ownerNameDiv">
          <h5 className="welcomeTextInAddTrip">
            Welcome,
            <span className="font-weight-bold">{`${" "}${companyNameSelector} ${"!"}`}</span>
          </h5>

        </div>
        <div>
          
          <p
          className="anotherPackageText"
                  onClick={() => history.push("/TCdeliverydetails")}
        >
          Your Previous Trips 
        </p>
        </div>
        <div className="trtripbox" data-component="trtripbox">
          <IconButton aria-label="add" onClick={openModal}>
            <AddIcon
              style={{
                color: "#fb6504",
                fontSize: "100px",
                fontWeight: "bold",
                border: "none",
                outline: "none",
              }}
            />
          </IconButton>
          <h2 onClick={openModal}>Add Delivery Trip</h2>
          {
            (console.log(modalPageDataSelector),
            modalPageDataSelector ? <ModalPage></ModalPage> : null)
          }
        </div>
      </div>
    </div>
  );
}

export default TCAddtrip;