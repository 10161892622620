import React, { useState } from "react";
import "./pkgstatus.css";
import Header from "../PoHeader/Header";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PACKAGE from "../../Images/PACKAGE.png";
import orangecall1 from "../../Images/orangecall1.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { localAxiosToken } from "../../Axios/axios";
import qs from "querystring";
import { useSelector } from "react-redux";
import Rating from "../../Rating/rating";

function PkgStatus() {
  const history = useHistory();
  const [commentData, setCommentData] = useState(" ");
  const customerMobileNumSelector = useSelector(
    (state) => state.CustomerMobileNum
  );
  const customerTokenSelector = useSelector((state) => state.CustomerToken);
  const packageDetailsData = useSelector((state) => state.CustomerPackages);
  const selectedTruckDetails = useSelector((state) => state.SelectedTruckData);
  const selectedTruckForRatingData = useSelector(
    (state) => state.SelectedTruckForRating
  );
  const TrackTruckDetailsData = useSelector((state) => state.TrackingTruckData);
  const ownerRating = useSelector((state) => state.OwnerRating);

  console.log(selectedTruckDetails);
  console.log(customerMobileNumSelector);
  console.log(customerTokenSelector);
  console.log(packageDetailsData);
  console.log(selectedTruckForRatingData);
  console.log(TrackTruckDetailsData);
  console.log(ownerRating);

  const commentFunction = (e) => {
    console.log(e.target.value);
    setCommentData(e.target.value);
  };

  const ratingData = `${qs.stringify({
    truckNo: TrackTruckDetailsData[0].truck_no,
    tripId: TrackTruckDetailsData[0].trip_id,
    rating: ownerRating,
    mobileNum: TrackTruckDetailsData[1].customer_mobile_num,
    receivingPersonNo: TrackTruckDetailsData[1].receiving_person_mobile_no,
    comment: commentData,
    packageId: TrackTruckDetailsData[1].package_id,
    truckOwnerMobileNo: selectedTruckDetails.mobileNumber,
  })}`;

  const handleClick = async () => {
    console.log(ratingData, "rating Data");
    await axios(
      localAxiosToken("/addRating", ratingData, customerTokenSelector)
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.statusCode === 200) {
          history.push("/podelivery");
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(ratingData);
      });
  };

  return (
    <div className="packageStatusDiv">
      <div className="headerInPackageStatus">
        <Header />
      </div>
      <div className="mainDivInStatus">
        <div className="cardInStatus">
          <div className="subCardInStatus">
            <div className="headerInStatus">
              <img
                src={PACKAGE}
                width={40}
                height={40}
                alt=" "
                className="ml-2 float-left "
              />
              <div>
                <span className="float-left ml-4 text-white">Status : </span>
                <span className="float-left  ml-2 text-orange">Delivered</span>
              </div>
            </div>
            <div className="orangeCallDiv">
              <img
                src={orangecall1}
                width={40}
                height={40}
                alt=" "
                className="ml-4 float-left mr-2 "
              />
              <div className="d-flex flex-column">
                <h5 className="mr-auto m-0">{`${TrackTruckDetailsData[0].truck_no}`}</h5>
                <h5 className="mr-auto m-0">Ashok Pattiyala</h5>
              </div>
            </div>
            <div>
              <div className="loactionSymbolsInStatus">
                <LocationOnIcon color="disabled" fontSize="large" />
                <LocationOnIcon color="disabled" fontSize="large" />
              </div>
              <div className="travelDataInStatus">
                <div className="pickUpDataInStatus">
                  <p className="travelDataLineInStatus">
                    {TrackTruckDetailsData[1].pickup_point}
                  </p>
                  {/* <p className="travelDataLineInStatus">Miyapur Main Road</p> */}
                  <p className="travelDataLineInStatus">
                    {TrackTruckDetailsData[1].pickup_date.slice(0, 10)}
                  </p>
                </div>
                <div className="destinationDataInStatus">
                  <p className="travelDataLineInStatus">
                    {TrackTruckDetailsData[1].drop_point}
                  </p>
                  {/* <p className="travelDataLineInStatus">Barakhamba Rd</p> */}
                  <p className="travelDataLineInStatus">4th May, 9.30 PM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ratingCard ">
          <div>
            <h4>Delivery Alert</h4>
            <h4 className="dropDateColor">
              {`Delivered (${TrackTruckDetailsData[1].drop_date})`}
            </h4>
            <a className="notReceiving" href="#">
              Did not Recieved the Package?
            </a>
          </div>
          <div className="d-flex flex-row mainDivForPackage">
            <div className="packageWeightDiv">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row ">
                  <div className="d-flex flex-column align-items-center">
                    <h6>Package Weight</h6>
                    <h4>{`${TrackTruckDetailsData[1].package_weight} KG`}</h4>
                  </div>
                  <div className="d-flex flex-column ml-5 align-items-center">
                    <h6>Package Space</h6>
                    <h4>{`${TrackTruckDetailsData[1].package_space} FT`}</h4>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column mt-1">
                <h3 className="heading m-0">Drop Location</h3>
                <p className="headingValue m-0 p-0">{`${TrackTruckDetailsData[1].pickup_point}-${TrackTruckDetailsData[1].drop_point}`}</p>
              </div>
              <div className="d-flex flex-column mt-1">
                <h3 className="heading m-0">Customer Name</h3>
                <p className="headingValue m-0 p-0">
                  {TrackTruckDetailsData[1].customer_name}
                </p>
              </div>
            </div>
            <div>
              <img
                src={PACKAGE}
                alt=""
                width={150}
                height={150}
                style={{ opacity: "19%" }}
              />
            </div>
          </div>
          <div className="d-flex flex-column  ratingDiv">
            <h3 className="partnerHeading">Rate Delivery Partner</h3>
            <div className="d-flex flex-row justify-content-left">
              <img
                src={orangecall1}
                width={40}
                height={40}
                alt=" "
                className=" float-left ml-4  mt-2"
              />
              <div className="d-flex flex-column">
                <h3 className="heading m-0 t-l">Ashok Pattiyala</h3>
                <p className="headingValue m-0 p-0 t-l">
                  {TrackTruckDetailsData[0].truck_no}
                </p>
              </div>
            </div>
            <Rating
              giveRating="true"
              truckNo={TrackTruckDetailsData[0].truck_no}
            />
          </div>
          <div className="form-floating">
            <textarea
              className="form-control"
              placeholder="Please let know any comments"
              id="textArea"
              onChange={commentFunction}
            ></textarea>
          </div>
          <button className="doneText mt-2" onClick={handleClick}>
            DONE
          </button>
        </div>
      </div>
    </div>
  );
}

export default PkgStatus;