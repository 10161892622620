import React, { useState, OnSubmit } from "react";
import { useHistory } from "react-router-dom";
import qs from "querystring";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  companyName,
  contactNumber,
  noOfVehicals,
  selectedTruck,
} from "../../Redux/actions/transportCompanyInfo";
import { localAxios } from "../../Axios/axios";
import { ownerOtp, ownerToken } from "../../Redux/actions/ownerInfo";
import { FcCheckmark } from "react-icons/fc";
import { Row } from "react-bootstrap";
import { AiOutlineExclamation } from "react-icons/ai";
import Arrow from "../../Images/Arrow.png";
import "./TCRegister.css";

function TCRegister() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [register, setRegister] = useState(false);
  const listOfTrucks = [];
  const handleClick1 = () => history.push("/TCLogin");
  const handleClick2 = (indexValue) => {
    history.push("/TCvehicleinfo");
    console.log(indexValue);
    dispatch(selectedTruck(indexValue + 1));
  };
  const [companyNameErr, setcompanyNameErr] = useState({});
  const [contactNumberErr, setcontactNumberErr] = useState({});
  const [noOfVehicalsErr, setnoOfVehicalsErr] = useState({});
  const companyNameSelector = useSelector((state) => state.CompanyName);
  const contactNumberSelector = useSelector((state) => state.ContactNumber);
  const noOfVehicalsSelector = useSelector((state) => state.NoOfVehicals);
  const otpSelector = useSelector((state) => state.OwnerOtp);
  
  const onSubmit = async () => {
    const isValid = formValidation();
    if (isValid) {
      try {
        await axios(
          localAxios(
            "/transportCompanyRegister",
            qs.stringify({
              companyName: companyNameSelector,
              mobileNum: contactNumberSelector,
              noOfTrucks: noOfVehicalsSelector.length,
            })
          )
        )
          .then((response) => {
            console.log(response);
            if (response.data.statusCode === 201) {
              dispatch(ownerOtp(response.data.otp));
              console.log(response.data.otp);
              dispatch(ownerToken(response.data.token));
              console.log(response.data.token);
              setRegister(true);
            } else {
              alert(response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  function formValidation() {
    var specialCharacterPatternInTc = /[^a-zA-Z ]+/;
    var specialCharacterForMobileInTc = /[^0-9]+/;
    const companyNameErr = {};
    const contactNumberErr = {};
    const noOfVehicalsErr = {};
    let isValid = true;
    if (companyNameSelector.length === 0) {
      companyNameErr.companyNameEmpty = "Company Name should be required";
      isValid = false;
    } else if (specialCharacterPatternInTc.test(companyNameSelector)) {
      companyNameErr.companyNameSpecial =
        "should not include special characters ";
      isValid = false;
    } else {
    }
    if (contactNumberSelector.length === 0) {
      contactNumberErr.contactNumberEmpty = "Mobile Number should be required";
      isValid = false;
    } else if (specialCharacterForMobileInTc.test(contactNumberSelector)) {
      contactNumberErr.mobileNumberHaveCharacters =
        "should not include special characters";
      isValid = false;
    } else if (contactNumberSelector.trim().length > 10) {
      contactNumberErr.contactNumberLong = "Mobile Number should be 10 Digits";
      isValid = false;
    } else if (contactNumberSelector.trim().length < 10) {
      contactNumberErr.contactNumberShort = "Mobile Number should be 10 Digits";
      isValid = false;
    } else {
    }
    if (noOfVehicalsSelector.length === 0) {
      noOfVehicalsErr.noOfVehiclesEmpty =
        "Number of vehicles Should be Required";
      isValid = false;
    } else {
    }
    setcompanyNameErr(companyNameErr);
    setcontactNumberErr(contactNumberErr);
    setnoOfVehicalsErr(noOfVehicalsErr);
    return isValid;
  }

  const noOfVehiclesFunction = (vehicles) => {
    console.log(vehicles, "vehiclesvehiclesvehicles");
    for (let i = 1; i <= vehicles; i++) {
      listOfTrucks.push([i, false]);
    }
    console.log(listOfTrucks, "list");
    dispatch(noOfVehicals(listOfTrucks));
    console.log(listOfTrucks);
  };

  function nextPage() {
    const next = noOfVehicalsSelector.map((data) => {
      const ret = data[1] ? 1 : 0;
      return ret;
    });
    const allTruckReg = next.reduce((a, b) => a + b, 0);
    console.log(allTruckReg, "All Register Trucks");
    if (allTruckReg === noOfVehicalsSelector.length) history.push("/TCotp");
  }

  function onChangetextremovevalidationmessage() {
    setcompanyNameErr("");
    setcompanyNameErr("");
    setnoOfVehicalsErr("");
  }

  return (
    <form onSubmit={OnSubmit}>
      <div className="TCRegister" data-component="TCRegister">
        <div className="transportMainDiv">
          <div className="Transportation1">
            <p className="mainTitleForTransportation">
              Want to be a
              <span className="transportationTitle">Transportation</span>
            </p>
            <p className="deliveryPartner">Delivery Partner?</p>
          </div>
          <div className="login-transport1">
            <h3>Register</h3>
            <h6>
              Already registered? Please
              <span className="TCLogin" onClick={handleClick1}> <u>Login</u></span>
            </h6>
            <div className="pos">
              <Row className="ml-0">
                <input
                  className="TClogin-input1"
                  type="text"
                  placeholder=" "
                  value={companyNameSelector}
                  onChange={(e) => {
                    dispatch(companyName(e.target.value));
                    onChangetextremovevalidationmessage();
                  }}
                />
                <label className="mt-2 floatin">Company Name</label>
              </Row>
              {Object.keys(companyNameErr).map((key) => {
                return <div className="errorDiv">{companyNameErr[key]}</div>;
              })}
            </div>
            <div className="pos">
              <Row className="ml-0">
                <input
                  className="TClogin-input1"
                  type="text"
                  placeholder=" "
                  value={contactNumberSelector}
                  onChange={(e) => {
                    dispatch(contactNumber(e.target.value));
                    onChangetextremovevalidationmessage();
                  }}
                />
                <label className="mt-2 floatin">Contact Number</label>
              </Row>
              {Object.keys(contactNumberErr).map((key) => {
                return <div className="errorDiv">{contactNumberErr[key]}</div>;
              })}
            </div>
            <div className="pos">
              <Row className="ml-0">
                <input
                  className="TClogin-input1"
                  type="numbers"
                  placeholder=" "
                  value={
                    noOfVehicalsSelector.length === 0
                      ? ""
                      : noOfVehicalsSelector.length
                  }
                  onChange={(e) => {
                    noOfVehiclesFunction(e.target.value);
                    onChangetextremovevalidationmessage();
                  }}
                />
                <label className="mt-2 floatin">Number Of Vehicles</label>
              </Row>
              {Object.keys(noOfVehicalsErr).map((key) => {
                return <div className="errorDiv">{noOfVehicalsErr[key]}</div>;
              })}
            </div>
          </div>
          {register || otpSelector ? (
            <div className="truckList">
              <h5 className="vehicle">Vehicle Information</h5>
              <div className="trucks1">
                {noOfVehicalsSelector.length ? (
                  noOfVehicalsSelector.map((data, index) => (
                    <div
                      className="trucks"
                      onClick={() => handleClick2(index)}
                      key={index}
                    >
                      <label className="trucksA">TRUCK</label>
                      {data[1] ? (
                        <FcCheckmark size={10}></FcCheckmark>
                      ) : (
                        <AiOutlineExclamation size={12}></AiOutlineExclamation>
                      )}
                      <p className="truckNumber">{index + 1}</p>
                    </div>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
              <img
                src={Arrow}
                alt="img"
                onClick={nextPage}
                className="ArrowTC1"
              />
            </div>
          ) : (
            <div className="arrowBbtn">
              <img
                src={Arrow}
                alt="img"
                onClick={onSubmit}
                className="ArrowTC"
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
}

export default TCRegister;